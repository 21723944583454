import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import useMapsAutocomplete from './hooks/useMapsAutocomplete';
import { MdSearch, MdSearchOff } from 'react-icons/md';
import { Loader } from 'semantic-ui-react';
import { useDebounce } from '@hooks/useDebounce';
import { MdOutlineLocationOn } from 'react-icons/md';
import useMapsPlaceDetails from './hooks/useMapsPlaceDetails';
import { LoadingOutlined } from '@ant-design/icons';

function GeoPicker({
    active = false,
    placeholder = '',
    defaultValue = '',
    onSelect = () => {},
    defaultResults = [],
    ...rest
}) {
    const ref = useRef();
    const [value, setValue] = useState('');
    const { fetching, fetched, autoComplete, predictions, abort } = useMapsAutocomplete();
    const debounceAutoComplete = useDebounce(autoComplete, 500);
    const [results, setResults] = useState([]);

    useEffect(() => {
        if (active) {
            ref.current.focus();
            if (defaultValue) {
                setValue(defaultValue);
                handleSearch(defaultValue);
            }
        }
        return () => {
            if (active) {
                console.log('GeoPicker closed');
                abort();
                // reset();
            }
        };
    }, [active, defaultValue]);

    useEffect(() => {
        if (Array.isArray(predictions) && predictions.length > 0) {
            setResults(predictions);
        }
    }, [predictions]);

    useEffect(() => {
        if (Array.isArray(defaultResults) && defaultResults.length > 0) {
            setResults(defaultResults);
        }
    }, [defaultResults]);

    const reset = () => {
        flushResults();
        setValue(defaultValue);
    };

    const flushResults = () => {
        if (Array.isArray(defaultResults) && defaultResults.length > 0) {
            setResults([]);
        } else {
            setResults([]);
        }
    };

    const onChange = (e) => {
        const value = e.target.value;
        // console.log(value);
        setValue(value);
        handleSearch(value);
    };

    const handleSearch = (value) => {
        if (value.length < 3) {
            flushResults();
            return;
        }
        // Skip debounce if value is equal to defaultValue
        if (value == defaultValue) {
            autoComplete(value)
                .then((data) => {
                    console.log('data:', data);
                })
                .catch((err) => {
                    // alert(err);
                });
        } else {
            debounceAutoComplete(value)
                .then((data) => {
                    console.log('data:', data);
                })
                .catch((err) => {
                    // alert(err);
                });
        }
    };

    const Results = () => {
        if (fetching) {
            return <Loader inline="centered" active={true} />;
        }
        if (results.length == 0 && fetched) {
            return (
                <div className={styles.icon_container}>
                    <MdSearchOff className={styles.icon} />
                    <div className={styles.text}>无结果</div>
                </div>
            );
        }
        if (results.length == 0 && !fetched) {
            return (
                <div className={styles.icon_container}>
                    <MdSearch className={styles.icon} />
                    <div className={styles.text}>机场，酒店，地址</div>
                </div>
            );
        }

        return results.map((item, index) => {
            const { fetchPlaceDetails, fetching: fetchingDetails, abort: abortDetails } = useMapsPlaceDetails();

            useEffect(() => {
                return () => {
                    abortDetails();
                };
            }, []);

            const handleOnClick = (item) => {
                fetchPlaceDetails(item.placeID)
                    .then((data) => {
                        console.log('Place details:', data);
                        const { name, formattedAddress, location } = data.details;
                        const description = `${name} - ${formattedAddress}`;
                        onSelect({
                            description: description,
                            placeID: item.placeID,
                            lat: location.lat,
                            lng: location.lng,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            };
            return (
                <div key={index} className={styles.result_item_container} onClick={() => handleOnClick(item)}>
                    <div className={styles.leading_container}>
                        <MdOutlineLocationOn className={styles.icon} />
                    </div>
                    <div className={styles.trailing_container}>
                        <div className={styles.title_container}>
                            {item.description} {fetchingDetails && <LoadingOutlined />}
                        </div>
                        <div className={styles.subtitle_container}></div>
                    </div>
                </div>
            );
        });
    };

    return (
        <div className={styles.container} {...rest}>
            <input
                ref={ref}
                className={styles.input}
                placeholder={placeholder}
                autoFocus
                onChange={onChange}
                value={value}
            />
            <div className={styles.results_container}>
                <Results />
            </div>
        </div>
    );
}

GeoPicker.propTypes = {
    active: PropTypes.bool,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
};

export default GeoPicker;
