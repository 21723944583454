import React, { useLayoutEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from '@features/account/accountSlice';
import { FiLogOut } from 'react-icons/fi';
import * as config from '@config';
import { MdAccountCircle, MdOutlineBed } from 'react-icons/md';
import {
    IoFileTrayFullOutline,
    IoAirplaneOutline,
    IoAirplane,
    IoDocumentTextOutline,
    IoCompassOutline,
    IoMapOutline,
    IoCarOutline,
} from 'react-icons/io5';
import { AiTwotoneCustomerService } from 'react-icons/ai';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Visa } from '@utils/constants';
import { AccommodationEnabled, ActivityEnabled, WhiteLabelled, FlightEnabled } from './utils/storage';
import { DevMode } from './utils/utils';
import useLoginUser from './hooks/useLoginUser';
import { Menu } from 'antd';
import { MdOutlineBeachAccess } from 'react-icons/md';

function Console() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { userInfo: loginInfo } = useLoginUser();
    const [openKeys, setOpenKeys] = useState(['']);

    // wrap it with useMemo
    const getMenuItems = () => {
        const items = [{ type: 'divider' }];

        if (FlightEnabled()) {
            items.push({
                key: 'air-tickets',
                label: '机票',
                icon: <IoAirplaneOutline style={{ transform: 'rotate(-45deg)', fontSize: '18px' }} />,
                // type: 'group',
                children: [
                    {
                        key: '/dashboard/air-tickets/flight-deals',
                        label: '团队特惠',
                    },
                    {
                        key: '/dashboard/air-tickets/git',
                        label: '团队报价',
                    },
                    {
                        key: '/dashboard/air-tickets/fit',
                        label: '散客预订',
                    },
                ],
            });
        }

        if (AccommodationEnabled()) {
            items.push({
                key: 'accommodations',
                label: '住宿',
                icon: <MdOutlineBed style={{ fontSize: '18px' }} />,
                children: [
                    {
                        key: '/dashboard/accommodations/hotels',
                        label: '散客酒店',
                    },
                    {
                        key: '/dashboard/accommodations/offers',
                        label: '澳新团队',
                    },
                ],
            });
        }

        if (ActivityEnabled()) {
            items.push({
                key: 'activities',
                label: '度假',
                icon: <IoCompassOutline style={{ fontSize: '18px' }} />,
                children: [
                    {
                        key: '/dashboard/activities/tickets',
                        label: '活动体验',
                    },
                    // {
                    //     key: '/dashboard/activities/transfers',
                    //     label: '机场接送',
                    // },
                    {
                        key: '/dashboard/activities/demo',
                        label: '定制参考',
                    },
                    {
                        key: '/dashboard/activities/cruises',
                        label: '太平洋邮轮',
                    },
                ],
            });

            items.push({
                key: '/dashboard/packages/',
                label: '套餐',
                icon: <MdOutlineBeachAccess style={{ fontSize: '18px' }} />,
            });

            items.push({
                key: '/dashboard/transfers/',
                label: '用车',
                icon: <IoCarOutline style={{ fontSize: '18px' }} />,
            });

            items.push({
                key: '/dashboard/trips',
                label: '行程规划',
                icon: <IoMapOutline style={{ fontSize: '18px' }} />,
            });
        }

        items.push(
            {
                key: 'visa',
                label: '签证',
                icon: <IoDocumentTextOutline style={{ fontSize: '18px' }} />,
                children: [
                    {
                        key: `/dashboard/visa/${Visa.Tourist}`,
                        label: '旅游签证',
                    },
                    {
                        key: `/dashboard/visa/${Visa.WorkingHoliday}`,
                        label: '打工假期签证',
                    },
                ],
            },
            {
                key: 'orders',
                label: '订单管理',
                icon: <IoFileTrayFullOutline style={{ fontSize: '18px' }} />,
                children: [
                    ...(FlightEnabled()
                        ? [
                              {
                                  key: '/dashboard/air-tickets/fit/orders',
                                  label: '机票订单',
                                  icon: <IoAirplaneOutline style={{ transform: 'rotate(-45deg)', fontSize: '14px' }} />,
                              },
                              {
                                  key: '/dashboard/air-tickets/git/enquiries',
                                  label: '团队订单',
                                  icon: <IoAirplane style={{ transform: 'rotate(-45deg)', fontSize: '14px' }} />,
                              },
                          ]
                        : []),
                    ...(AccommodationEnabled()
                        ? [
                              {
                                  key: '/dashboard/accommodations/hotels/orders',
                                  label: '酒店订单',
                                  icon: <MdOutlineBed style={{ fontSize: '14px' }} />,
                              },
                          ]
                        : []),
                    ...(ActivityEnabled()
                        ? [
                              {
                                  key: '/dashboard/orders',
                                  label: '活动订单',
                                  icon: <IoCompassOutline style={{ fontSize: '14px' }} />,
                              },
                          ]
                        : []),
                    // {
                    //     key: '/dashboard/transfers/orders',
                    //     label: '用车订单',
                    // },
                    {
                        key: '/dashboard/transfers/enquiries',
                        label: '用车咨询',
                        icon: <IoCarOutline style={{ fontSize: '14px' }} />,
                    },
                ],
            },
            { type: 'divider' },
            {
                key: '/dashboard/faqs',
                label: 'FAQs',
                icon: <FaRegQuestionCircle style={{ fontSize: '18px' }} />,
            },
            {
                key: '/dashboard/support',
                label: '客服',
                icon: <AiTwotoneCustomerService style={{ fontSize: '18px' }} />,
            },
            {
                key: '/dashboard/account',
                label: '账号',
                icon: <MdAccountCircle style={{ fontSize: '18px' }} />,
            },
            {
                key: '/logout',
                label: 'Logout',
                icon: <FiLogOut style={{ fontSize: '18px' }} />,
                danger: true,
            }
        );
        return items;
    };
    const menuItems = useMemo(() => getMenuItems(), []);

    const findSelectedKey = (items, path) => {
        let bestMatch = null;
        let bestMatchLength = 0;

        const checkItem = (item) => {
            if (item.key && path.startsWith(item.key) && item.key.length > bestMatchLength) {
                bestMatch = item.key;
                bestMatchLength = item.key.length;
            }
            if (item.children) {
                item.children.forEach(checkItem);
            }
        };

        items.forEach(checkItem);
        return bestMatch;
    };

    const selectedKey = useMemo(() => findSelectedKey(menuItems, location.pathname), [location.pathname, menuItems]);

    // Solve submenus close-and-open issue when rerender
    useLayoutEffect(() => {
        const pathSegments = location.pathname.split('/').filter(Boolean);
        const last = pathSegments[pathSegments.length - 1];
        if (last === 'orders' || last === 'enquiries') {
            setOpenKeys(['orders']);
            return;
        }
        // /dashboard/xxx/gegeg
        if (pathSegments.length > 2) {
            const lv1 = pathSegments[1];
            switch (lv1) {
                case 'air-tickets':
                case 'accommodations':
                case 'activities':
                case 'orders':
                // case 'transfers':
                case 'visa':
                    setOpenKeys([lv1]);
                    break;
                default:
                    console.log('Unknown lv1 key:', lv1);
                    break;
            }
        }
    }, [location.pathname]);

    const handleMenuOnClick = ({ key, keyPath }) => {
        // console.log(keyPath);
        if (key.startsWith('/dashboard')) {
            // console.log('Navigate to', key);
            navigate(key, { replace: true });
        } else {
            switch (key) {
                case '/logout':
                    clickLogout();
                    break;
                default:
                    console.warn('Unkown key', key);
                    break;
            }
        }
    };

    const onOpenChange = (keys) => {
        // console.log('onOpenChange', keys);
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    };

    const clickLogout = () => {
        if (window.confirm('是否要登出？') == true) {
            dispatch(logout());
        }
    };

    return (
        <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            {/* <div style={{ padding: '16px 28px' }}>
                <div>授信额度: CNY {loginInfo.agency.credit?.toLocaleString('en-US')}</div>
            </div> */}
            <Menu
                style={{
                    borderRight: 'unset',
                }}
                mode="inline"
                items={menuItems}
                openKeys={openKeys}
                selectedKeys={selectedKey ? [selectedKey] : []}
                onClick={handleMenuOnClick} // was onSelect
                onOpenChange={onOpenChange}
            />

            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    transform: ' translateX(-50%)',
                    whiteSpace: 'nowrap',
                    // borderTop: '1px solid #e4e6e8',
                }}
            >
                <span>Version {config.BUILD_NUM}</span>
            </div>
        </div>
    );
}

export default Console;
