import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalContent, ModalActions, Dropdown } from 'semantic-ui-react';
import { App, Button, Space } from 'antd';
import { createSelector } from '@reduxjs/toolkit';
import { Selector } from '../../utils/constants';
import useOrderItem from '../../hooks/activity-order/useOrderItem';

function AddOrderItem({ orderID = '', callback = () => {} }) {
    // console.log('AddOrderItem orderID:', orderID);
    const selectorData = (state) => state.activities.data;
    const filterActivities = createSelector(selectorData, (items) =>
        items?.filter((item) => {
            // Filter out hidden items
            if (item.hidden == false) {
                return true;
            } else if (
                // Filter out items that are not activities
                !item.selectors?.includes(Selector.Cars) &&
                !item.selectors?.includes(Selector.Cruises) &&
                !item.selectors?.includes(Selector.Team) &&
                !item.selectors?.includes(Selector.Package)
            ) {
                return true;
            }
        })
    );
    const activities = useSelector((state) => {
        return filterActivities(state);
    });
    const fetching = useSelector((state) => state.activities.fetching);
    const { addOrderItem, adding } = useOrderItem();
    const [open, setOpen] = useState(false);
    const [activityID, setActivityID] = useState();
    const { message } = App.useApp();

    useEffect(() => {
        if (open == false) {
            setActivityID('');
        }
        return () => {};
    }, [open]);

    const options = activities.map((item, index) => {
        return { key: index, text: item.name, value: item.id };
    });

    const handleOnChange = (e, { value }) => {
        console.log(value);
        setActivityID(value);
    };

    const handleSumbit = () => {
        const req = {
            activityID: activityID,
            orderID: orderID,
        };
        addOrderItem(req)
            .then((data) => {
                callback();
                setOpen(false);
            })
            .catch((error) => {
                console.error('Error adding order item:', error);
                message?.error(error.message);
            });
    };

    return (
        <>
            <Button type="dashed" onClick={() => setOpen(true)}>
                添加活动
            </Button>
            <Modal open={open} onClose={() => setOpen(false)} closeOnDimmerClick={false}>
                <ModalHeader>添加活动</ModalHeader>
                <ModalContent>
                    <Dropdown
                        placeholder="选择活动"
                        fluid
                        selection
                        options={options}
                        search
                        clearable
                        value={activityID}
                        onChange={handleOnChange}
                    />
                </ModalContent>
                <ModalActions>
                    <Space>
                        <Button type="primary" onClick={handleSumbit} loading={adding}>
                            添加
                        </Button>
                        <Button type="default" onClick={() => setOpen(false)} disabled={adding}>
                            关闭
                        </Button>
                    </Space>
                </ModalActions>
            </Modal>
        </>
    );
}

AddOrderItem.propTypes = {
    orderID: PropTypes.string.isRequired,
    callback: PropTypes.func,
};

export default AddOrderItem;
