import React from 'react';
import PropTypes from 'prop-types';
import { MdAccessTime } from 'react-icons/md';
import styles_input from '@styles/InputContainerLarge.module.css';

const TimePickerField = ({ label, name, value, onChange }) => (
    <div className={styles_input.input_container}>
        <MdAccessTime className={styles_input.icon} />
        <label>
            <div className={styles_input.label_content}>{label}</div>
        </label>
        <input
            className={styles_input.input}
            name={name}
            type="time"
            placeholder="选择时间"
            value={value || ''}
            onChange={onChange}
        />
    </div>
);

TimePickerField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default TimePickerField;
