import { useCallback, useState, useRef } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useFlightDeal() {
    const [fetching, setFetching] = useState(false);
    const [deal, setDeal] = useState({});

    const abortRef = useRef();

    const fetchDeal = useCallback(async (id) => {
        abort();
        setFetching(true);
        abortRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL2}/flight-deals/${id}`, {
                signal: abortRef.current.signal,
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                },
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Something went wrong when fetching deal');
            }
            const data = await response.json();
            const { deal } = data;
            setDeal(deal);
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetch deal:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);

    return { fetching, deal, fetchDeal, abort };
}

export default useFlightDeal;
