import { useCallback, useState, useRef } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useMapsAutocomplete() {
    const [fetching, setFetching] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [predictions, setPredictions] = useState([]);
    const abortRef = useRef();

    const autoComplete = useCallback(async (input) => {
        abort();
        abortRef.current = new AbortController();
        setFetching(true);
        setFetched(false);
        try {
            const response = await fetch(`${config.apiURL2}/maps/autocomplete`, {
                method: 'POST',
                signal: abortRef.current.signal,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${GetToken()}`,
                },
                body: JSON.stringify({ input }),
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to fetch auto complete');
            }
            const data = await response.json();
            const { predictions } = data;
            setPredictions(predictions);
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error auto complete:', error);
            }
            throw error;
        } finally {
            setFetching(false);
            setFetched(true);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);

    return { fetching, fetched, predictions, autoComplete, abort };
}

export default useMapsAutocomplete;
