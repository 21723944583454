import React, { useState, useEffect } from 'react';
import { Typography, Descriptions, Card, Row, Col, Select, Spin, Alert, Grid, Image, Button, App } from 'antd';
import { ActivityDurationParse } from '@utils/constants';
import styles from '@styles/ActivityDetailsPage.module.css';
import useActivity from '../../hooks/activity/useActivity';
import { useParams } from 'react-router';
import BackBar from '@components/BackBar';
import * as config from '@config';
import { useCart } from '../../contexts/CartContext';
import useActivityEffectPricing from '../../hooks/activity/useActivityEffectPricing';
import useLoginUser from '../../hooks/useLoginUser';
import { TeamOutlined } from '@ant-design/icons';
import ActivityDetails from '@components/activity/ActivityDetails';

const { Title, Paragraph } = Typography;
const { useBreakpoint } = Grid;

const ActivityDetailsPage = () => {
    const { id } = useParams();
    const { addToCart } = useCart();
    const {
        data: { activity, commission },
        fetching: loading,
        fetchActivity,
    } = useActivity();
    const {
        fetching,
        data: { effectPricing = {} },
        fetchEffectPricing,
    } = useActivityEffectPricing();

    const { userInfo } = useLoginUser();
    const { message } = App.useApp();

    const [selectedVariant, setSelectedVariant] = useState(null);
    const [selectedSegment, setSelectedSegment] = useState(null);
    const [error, setError] = useState(null);
    const screens = useBreakpoint();

    useEffect(() => {
        fetchActivity(id).catch((error) => {
            setError('Some data is missing. Please try again later.');
            console.error('Error fetching activity:', error);
        });
    }, [id]);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchEffectPricing({
            activityID: id,
            segmentID: selectedSegment?.id,
            variantID: selectedVariant?.id,
        })
            .then((data) => {
                if (Object.keys(effectPricing).length !== 0) {
                    message?.success('价格已更新');
                }
            })
            .catch((error) => {
                message?.error('价格更新失败');
                console.error('Error fetching effective pricing:', error);
            });
    }, [selectedVariant, selectedSegment, id]);

    if (loading) {
        return <Spin size="large" />;
    }

    if (error) {
        return <Alert message="Error" description={error} type="error" showIcon />;
    }

    if (!activity) {
        return <Alert message="No data available" type="warning" showIcon />;
    }

    const { name, nameEN, tourCode, content, information, duration, variants, segmentOptions: segments } = activity;

    // console.log('Activity:', activity);

    const handleVariantChange = (variantId, opt) => {
        const variant = variants.find((v) => v.id === opt.id);
        setSelectedVariant(variant || null);
    };

    const handleSegmentChange = (segmentId, opt) => {
        const segment = segments.find((v) => v.id === opt.id);
        setSelectedSegment(segment || null);
    };

    const handleAddToCart = () => {
        const req = {
            activityID: id,
            variantID: selectedVariant?.id || '',
            segmentID: selectedSegment?.id || '',
        };
        addToCart(req)
            .then((data) => {
                const { reply } = data;
                if (reply) {
                    message?.success(reply);
                } else {
                    message?.success('Added to cart');
                }
            })
            .catch((error) => {
                console.error('Erro to cart:', error);
            });
    };

    // const renderCommission = () => {
    //     return (
    //         <>
    //             佣金:
    //             {!!commission.ratio
    //                 ? `${Precentage(commission.ratio)}%`
    //                 : `${Precentage(userInfo?.agency?.defaultCommission)}%`}
    //             {!!commission.constant && `+A$${commission.constant}`}
    //         </>
    //     );
    // };

    const renderPricing = () => {
        if (activity.demo) {
            return null;
        }
        let variantOptions = [];
        if (variants) {
            variantOptions = variants.map((variant) => ({
                ...variant,
                key: variant.id,
                value: variant.id,
                label: variant.nameZH,
                labelEN: variant.nameEN,
            }));
        }
        let segmentOptions = [];
        if (segments) {
            segmentOptions = segments.map((segment) => ({
                key: segment.id,
                value: segment.id,
                label: segment.value,
            }));
        }

        return (
            <Card title="价格" style={{ marginBottom: '16px' }}>
                {variantOptions.length > 0 && (
                    <Select
                        style={{ width: '100%', marginBottom: '16px' }}
                        placeholder="套餐选项"
                        options={variantOptions}
                        onChange={handleVariantChange}
                        optionRender={(option) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography.Text style={{ whiteSpace: 'wrap' }}>
                                        {option.data.nameZH}
                                    </Typography.Text>
                                    <Typography.Text type="secondary" style={{ whiteSpace: 'wrap' }}>
                                        {option.data.description}
                                    </Typography.Text>
                                </div>
                            );
                        }}
                    />
                )}
                {segmentOptions.length > 0 && (
                    <Select
                        style={{ width: '100%', marginBottom: '16px' }}
                        placeholder="请选择时间段"
                        onChange={handleSegmentChange}
                        options={segmentOptions}
                    />
                )}
                <Descriptions column={1}>
                    {effectPricing.bundlePriced && (
                        <Descriptions.Item label="价格">
                            <span style={{ color: 'red', fontWeight: 'bold' }}>${effectPricing.bundlePrice}</span>
                        </Descriptions.Item>
                    )}
                    {!effectPricing.bundlePriced && (
                        <>
                            <Descriptions.Item label="成人">
                                <span style={{ color: 'red', fontWeight: 'bold' }}>${effectPricing.adultPrice}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label="儿童">
                                <span style={{ color: 'red', fontWeight: 'bold' }}>${effectPricing.childPrice}</span>
                            </Descriptions.Item>
                        </>
                    )}
                    {effectPricing.hasFamilyPlan && (
                        <Descriptions.Item
                            label={
                                <span>
                                    <TeamOutlined /> 家庭套票 ({effectPricing.familyAdultNum} 成人 +{' '}
                                    {effectPricing.familyChildNum} 儿童)
                                </span>
                            }
                        >
                            <span style={{ color: 'red', fontWeight: 'bold' }}>${effectPricing.familyPrice}</span>
                        </Descriptions.Item>
                    )}
                </Descriptions>
                <Button type="primary" style={{ marginTop: '16px' }} onClick={handleAddToCart}>
                    添加到报价单
                </Button>
            </Card>
        );
    };

    const renderDuration = () => {
        if (!duration) {
            return null;
        }
        return (
            <Card style={{ marginBottom: '16px' }}>
                <Descriptions column={1}>
                    <Descriptions.Item label={'时长'}>{ActivityDurationParse(duration) || 'N/A'}</Descriptions.Item>
                </Descriptions>
            </Card>
        );
    };

    const renderPhotoGrid = () => {
        if (!activity.photos || activity.photos.length === 0) {
            console.log('No photos');

            return null;
        }

        return (
            <Card>
                <Row gutter={[8, 8]}>
                    {activity.photos.map((photo, index) => (
                        <Col xs={8} sm={6} md={10} key={photo.id}>
                            <div>
                                <Image
                                    alt={`Photo ${index + 1}`}
                                    src={`${config.baseURL}/public/${photo.filename}`}
                                    preview={{ src: `${config.baseURL}/public/${photo.filename}` }}
                                    style={{
                                        objectFit: 'cover',
                                        aspectRatio: '1/1',
                                    }}
                                />
                            </div>
                        </Col>
                    ))}
                </Row>
            </Card>
        );
    };

    return (
        <div className={styles.container}>
            <BackBar />
            <Title level={screens.xs ? 5 : 4}>{name || 'Untitled Activity'}</Title>
            <Paragraph type="secondary">
                {nameEN} {tourCode && `- Tour Code: ${tourCode}`}
            </Paragraph>

            <div className={styles.content_container}>
                <div className={styles.left_container}>
                    <ActivityDetails activity={activity} />
                </div>
                <div className={styles.right_container}>
                    {renderPricing()}
                    {renderDuration()}
                    <div style={{ marginBottom: '16px' }}>{renderPhotoGrid()}</div>
                </div>
            </div>
        </div>
    );
};

export default ActivityDetailsPage;
