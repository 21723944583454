import React from 'react';
import { Card, Row, Col, Typography, Descriptions, Tag, Divider } from 'antd';
import { CarOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { formatDate, formatHHMM, formatYYYYMMDD, minutesToText } from './utils.js';
import TransferPolicy from '../TransferPolicy.jsx';
import TransferPaxEditModal from '../TransferPaxEditModal';

const { Title, Text } = Typography;

const TransferOrderDetail = ({ data, refresh = () => {}, ...rest }) => {
    if (!data) {
        return (
            <div {...rest}>
                <Card>
                    <Text type="secondary">No booking data available</Text>
                </Card>
            </div>
        );
    }

    return (
        <div {...rest}>
            {/* Header Card */}
            <Card>
                <Row justify="space-between" align="middle">
                    <Col>
                        <Title level={4}>
                            <Tag color={data.status === 'Cancelled' ? 'red' : 'blue'}>{data.status}</Tag>Reference:{' '}
                            {data.ref}({data.rideID})
                        </Title>
                    </Col>
                    <Col>
                        <Text type="success" style={{ fontSize: 20 }}>
                            {data.currency} {data.saleTotalPrice}
                        </Text>
                    </Col>
                </Row>
            </Card>

            <div style={{ marginTop: 24 }}>
                {/* Journey Details */}
                <Card title="行程详情">
                    <Descriptions column={2}>
                        <Descriptions.Item label={<>出发</>}>
                            <div>
                                <Text strong>{data.pickupName}</Text>
                                <br />
                                <Text type="secondary">{data.pickupAddress}</Text>
                            </div>
                        </Descriptions.Item>

                        <Descriptions.Item label={<>终点</>}>
                            <div>
                                <Text strong>{data.dropoffName}</Text>
                                <br />
                                <Text type="secondary">{data.dropoffAddress}</Text>
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item label={<>日期</>}>{formatYYYYMMDD(data.time)}</Descriptions.Item>
                        <Descriptions.Item label={<>时间</>}>{formatHHMM(data.time)}</Descriptions.Item>
                        {data.duration && (
                            <Descriptions.Item label={<>时长</>}>{minutesToText(data.duration)}</Descriptions.Item>
                        )}
                        {data.retTime && (
                            <>
                                <Descriptions.Item label={<>返程日期</>}>
                                    {formatYYYYMMDD(data.retTime)}
                                </Descriptions.Item>
                                <Descriptions.Item label={<>返程时间</>}>{formatHHMM(data.retTime)}</Descriptions.Item>
                            </>
                        )}
                        {data.arrFlightNumber && (
                            <Descriptions.Item label={<>到达航班</>}>{data.arrFlightNumber}</Descriptions.Item>
                        )}
                        {data.depFlightNumber && (
                            <Descriptions.Item label={<>出发航班</>}>{data.depFlightNumber}</Descriptions.Item>
                        )}
                    </Descriptions>
                </Card>

                {/* Passenger Details */}
                <Card
                    title={
                        <div style={{ display: 'flex', gap: 8 }}>
                            乘客信息
                            <TransferPaxEditModal id={data.id} pax={data?.passenger} callback={refresh} />
                        </div>
                    }
                    style={{ marginTop: 24 }}
                >
                    <Descriptions column={2}>
                        <Descriptions.Item label={<>人数</>}>{data?.passenger?.passengerCount}</Descriptions.Item>
                        <Descriptions.Item label={<>行李</>}>{data?.passenger?.luggageCount}</Descriptions.Item>
                        <Descriptions.Item label={<>姓名</>}>
                            {data?.passenger?.salutation} {data?.passenger?.firstName} {data?.passenger?.lastname}{' '}
                        </Descriptions.Item>
                        <Descriptions.Item label={<>电话</>}>{data?.passenger?.cellPhone}</Descriptions.Item>
                        <Descriptions.Item label={<>邮箱</>}>{data?.passenger?.email}</Descriptions.Item>
                    </Descriptions>
                </Card>

                <Card title="车辆信息" style={{ marginTop: 24 }}>
                    <Descriptions column={2}>
                        <Descriptions.Item label={<>车辆</>}>
                            {data?.vehicleManufacture} {data?.vehicleModel}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>

                {/* Additional Services */}
                <Card title="额外服务" style={{ marginTop: 24 }}>
                    {data.meetAndGreet && (
                        <div>
                            <Tag icon={<CarOutlined />} color="blue">
                                举牌接机服务
                            </Tag>
                            <span>
                                {data?.passenger?.welcomeSign}{' '}
                                <TransferPaxEditModal
                                    id={data.id}
                                    pax={data?.passenger}
                                    editWelcomeSign
                                    callback={refresh}
                                />
                            </span>
                        </div>
                    )}
                </Card>
                <Card title="政策" style={{ marginTop: 24 }}>
                    <TransferPolicy />
                </Card>
            </div>
        </div>
    );
};

TransferOrderDetail.propTypes = {
    data: PropTypes.object.isRequired,
    refresh: PropTypes.func,
};

export default TransferOrderDetail;
