import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { MdGroup, MdOutlineLuggage } from 'react-icons/md';
import { Button } from 'antd';
import { DevMode } from '@utils/utils';

function Vehicles({ data = [], onSelect = () => {} }) {
    useEffect(() => {}, [data]);

    return (
        <div className={styles.container}>
            {data.map((item, index) => {
                return (
                    <div key={index} className={styles.item_container}>
                        <div className={styles.top_container}>
                            <div className={styles.passenger}>
                                <MdGroup />
                                <span>最多{item.passengerCapacity}人</span>
                            </div>
                            <div className={styles.luggage}>
                                <MdOutlineLuggage />
                                <span>最多{item.luggageCapacity}件</span>
                            </div>
                        </div>
                        <div className={styles.middle_container}>
                            <div className={styles.car_photo}>
                                <div className={styles.image_container}>
                                    <img src={item.imageURL} alt={item.vehicleClass} />
                                </div>
                            </div>

                            <div className={styles.car_container}>
                                <div className={styles.car_class}>{item.vehicleClass}</div>
                                <div className={styles.car_model}>
                                    {item.typicalVehicle.model} {item.typicalVehicle.manufacturer} or same class/grade
                                </div>
                            </div>
                        </div>
                        <div className={styles.bottom_container}>
                            <div className={styles.pricing_container}>
                                <div className={styles.currency}>{item.price.currency}</div>
                                <div className={styles.value}>{item.price.salePrice}</div>
                                {DevMode() && <code style={{ color: 'grey' }}>({item.price.netPrice})</code>}
                            </div>
                            <div className={styles.action_container}>
                                <Button className="button" size="small" onClick={() => onSelect(item)}>
                                    立即预订
                                </Button>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

Vehicles.propTypes = {
    data: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default Vehicles;
