import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/TripDestinationPlays.module.css';
import { MdAddCircleOutline } from 'react-icons/md';
import { Modal, Button, Tab, Dimmer, Loader } from 'semantic-ui-react';
import { Selector } from '@utils/constants';
import axios from 'axios';
import * as config from '@config';
import TripDestinationPlaysRow from './TripDestinationPlaysRow';
import CommissionAmount from './CommissionAmount';
import ActivityPicker from '@components/activity/ActivityPicker';
import { DestinationName } from '@utils/constants';
import { GetToken } from '@utils/storage';
import usePlayAdd from '@hooks/trip/usePlayAdd';
import { App } from 'antd';

function TripDestinationPlays({ destination = {}, callback = () => {}, compact }) {
    const [activityPickerActive, setActivityPickerActive] = useState(false);
    const [tabIndex, setTabIndex] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const { adding, addPlay } = usePlayAdd();
    const { message } = App.useApp();

    const handleAdd = (activityID) => {
        addPlay({ activityID, destinationID: destination.id })
            .then((data) => {
                const reply = data.reply;
                if (reply) {
                    message?.success(reply);
                }
                callback();
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                alert(msg);
            });
    };

    const handleTabChange = (e, data) => {
        setTabIndex(data.activeIndex);
    };

    return (
        <div className={styles.container}>
            {destination?.plays.length > 0 ? (
                <div className={styles.plays_container}>
                    {destination.plays.map((item, index) => {
                        return (
                            <div className={styles.play_container} key={index}>
                                <TripDestinationPlaysRow play={item} compact={compact} callback={callback} />
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div>请添加体验或包车</div>
            )}

            <div className={styles.bottom_container} onClick={() => setActivityPickerActive(true)}>
                <div className={styles.button}>
                    <MdAddCircleOutline size={24} /> 添加活动
                </div>
            </div>
            <Modal
                open={activityPickerActive}
                onClose={() => setActivityPickerActive(false)}
                closeOnDimmerClick={false}
                closeIcon
                size="large"
                centered={false}
            >
                <Modal.Header>{DestinationName(destination.name)}</Modal.Header>
                <Modal.Content scrolling>
                    <div className={styles.activity_picker_container}>
                        <Tab
                            menu={{ secondary: true, pointing: false }}
                            activeIndex={tabIndex}
                            onTabChange={handleTabChange}
                            panes={[
                                {
                                    menuItem: '体验',
                                    render: () => {
                                        return (
                                            <ActivityPicker
                                                citySelectors={[destination.name]}
                                                deselectors={[
                                                    Selector.Cars,
                                                    Selector.Transfers,
                                                    Selector.Team,
                                                    Selector.Package,
                                                    Selector.OtherExperience,
                                                ]}
                                                plays={destination.plays}
                                                onSelect={handleAdd}
                                            />
                                        );
                                    },
                                },
                                {
                                    menuItem: '城市套餐',
                                    render: () => {
                                        return (
                                            <ActivityPicker
                                                citySelectors={[destination.name]}
                                                selectors={[Selector.Team, Selector.Package]}
                                                plays={destination.plays}
                                                onSelect={handleAdd}
                                            />
                                        );
                                    },
                                },
                                {
                                    menuItem: '包车',
                                    render: () => {
                                        return (
                                            <ActivityPicker
                                                citySelectors={[destination.name]}
                                                selectors={[Selector.Cars, Selector.Transfers]}
                                                plays={destination.plays}
                                                onSelect={handleAdd}
                                            />
                                        );
                                    },
                                },
                            ]}
                        />
                    </div>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setActivityPickerActive(false)}>确认</Button>
                </Modal.Actions>
                <Dimmer active={adding} page inverted>
                    <Loader></Loader>
                </Dimmer>
            </Modal>
        </div>
    );
}

TripDestinationPlays.propTypes = {
    destination: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        plays: PropTypes.array,
        notes: PropTypes.string,
        sort: PropTypes.number,
    }).isRequired,
    callback: PropTypes.func.isRequired,
    compact: PropTypes.bool,
};

export default TripDestinationPlays;
