import React, { useState, useEffect, useLayoutEffect } from 'react';
import styles from '@styles/TransfersPage.module.css';
import TopHeaderBg from '@assets/activity_top_bg_2.png';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useLocation } from 'react-router-dom';
import { RestoreScrollPosition, StoreScrollPosition } from '@utils/scrollPosition';
import { Selector, SelectorParser } from '../../utils/constants';
import { useSearchParams } from 'react-router-dom';
import Activities from '@components/activity/Activities';
import { Checkbox } from 'antd';

const cities = [
    Selector.Sydney,
    Selector.Melbourne,
    Selector.Brisbane,
    Selector.GoldCoast,
    Selector.Cairns,
    Selector.Adelaide,
    Selector.Perth,
    Selector.Tasmania,
    Selector.HamiltonIsland,
    Selector.Uluru,
    Selector.Darwin,
    Selector.Auckland,
    Selector.NewZealand,
    Selector.OtherCity,
];

function TransfersPage() {
    const location = useLocation();
    const [data, setData] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const selectorData = (state) => state.activities.data;
    const filterHiden = createSelector(selectorData, (items) => items?.filter((item) => item.hidden == false));
    const activities = useSelector((state) => {
        return filterHiden(state);
    });
    const fetching = useSelector((state) => state.activities.fetching);
    const [searchParams, setSearchParams] = useSearchParams();
    const params = new URLSearchParams(searchParams);
    let initCity = params.get('city') ?? '';
    const [citySelector, setCitySelector] = useState(initCity);

    useEffect(() => {
        filterTransfers();
    }, [searchParams, fetching]);

    useLayoutEffect(() => {
        return () => {
            StoreScrollPosition(location.pathname + location.search);
        };
    }, []);

    useEffect(() => {
        RestoreScrollPosition(location.pathname + location.search);
    }, [data]);

    const filterTransfers = () => {
        setLoaded(false);
        const params = new URLSearchParams(searchParams);
        const sort = params.get('sort');
        let newData = [...activities];
        // city
        if (citySelector) {
            console.log('citySelector', citySelector);
            newData = newData.filter((item) => {
                if (item.selectors?.includes(citySelector)) {
                    return true;
                }
            });
            // Selector sorting
            newData?.sort((a, b) => {
                const sortA = a.selectorItems.find((item) => item.name == citySelector).sort;
                const sortB = b.selectorItems.find((item) => item.name == citySelector).sort;
                return sortA - sortB;
            });
        }

        // filter kind
        const fixedSelector = Selector.Transfers;
        newData = newData.filter((item) => {
            if (item.selectors?.includes(fixedSelector)) {
                return true;
            }
        });
        // Selector sorting
        newData?.sort((a, b) => {
            const sortA = a.selectorItems.find((item) => item.name == fixedSelector).sort;
            const sortB = b.selectorItems.find((item) => item.name == fixedSelector).sort;
            return sortA - sortB;
        });

        if (sort) {
            const calculatePrice = (pricing) => {
                if (pricing.bundlePriced) {
                    return pricing.bundlePrice;
                }
                return pricing.adultPrice;
            };
            switch (sort) {
                case 'price-asc':
                    newData?.sort((a, b) => {
                        return calculatePrice(a.pricing) - calculatePrice(b.pricing);
                    });
                    break;
                case 'price-desc':
                    newData?.sort((a, b) => {
                        return calculatePrice(b.pricing) - calculatePrice(a.pricing);
                    });
                    break;
                default:
                    break;
            }
        }
        setData(newData);
        setLoaded(true);
    };

    const handleCityChecked = (value) => {
        setCitySelector((prev) => {
            return prev == value ? null : value;
        });
        const params = new URLSearchParams(searchParams);
        params.set('city', value);
        setSearchParams(params);
    };

    const Filter = () => {
        return (
            <div className={styles.filter_container}>
                {[...cities].map((item, index) => {
                    return (
                        <div key={index}>
                            <Checkbox checked={item == citySelector} onChange={() => handleCityChecked(item)}>
                                {SelectorParser(item)}
                            </Checkbox>
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <section className={styles.top_bg_container}>
                <img src={TopHeaderBg} />
                <div className={styles.words_container}>
                    <div className={styles.paragraph}>
                        机场接送 <span className={styles.colorful}>节省 50% 车费 </span>
                    </div>
                </div>
            </section>
            <section className={styles.content_container}>
                <div className={styles.title}>{citySelector ? SelectorParser(citySelector) : '全部'}</div>
                <Activities activities={data} loaded={loaded} filterElement={<Filter />} priceSorting={false} />
            </section>
        </div>
    );
}

export default TransfersPage;
