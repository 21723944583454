import React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Timeago } from '@utils/utils';
import { GetUserInfo } from '../utils/storage';
import useComments from '../hooks/useComments';
import { App, Button, Space } from 'antd';
import useConmmentPost from '../hooks/useCommentPost';
import { List, Input } from 'antd';
import usePolling from '@hooks/usePolling';

const { TextArea } = Input;

function Comments({ commentOnID = '' }) {
    const loginInfo = GetUserInfo();
    const { fetching, comments, fetchComments, abort: abortFetch } = useComments();
    const { posting, postComment, abort: abortPost } = useConmmentPost();
    const { message } = App.useApp();
    const [addedComment, setAddedComment] = useState('');

    useEffect(() => {
        return () => {
            abortFetch();
            abortPost();
        };
    }, [commentOnID]);

    const fetchData = () => {
        if (commentOnID) {
            console.log('Fetching comments for:', commentOnID);
            fetchComments(commentOnID, { selfOnly: false }).catch((err) => {
                message.error(err.toString());
            });
        }
    };

    const { progress } = usePolling(fetchData, {
        interval: 10000,
        immediate: true,
        enabled: true,
    });

    const handleSubmit = () => {
        if (addedComment) {
            const req = {
                comment: addedComment,
                commentOnID: commentOnID,
            };
            postComment(req)
                .then((res) => {
                    setAddedComment('');
                    fetchData();
                    message.success(res.reply);
                })
                .catch((err) => {
                    let msg = err.toString();
                    if (err.response?.data?.error) {
                        console.log('***', err.response.data.error);
                        msg = err.response.data.error;
                    }
                    window.alert(msg);
                });
        }
    };

    return (
        <div>
            <List
                itemLayout="vertical"
                size="small"
                dataSource={comments}
                locale={{ emptyText: ' ' }}
                renderItem={(comment, index) => (
                    <List.Item>
                        <div style={{ display: 'flex', gap: '16px' }}>
                            <h4>
                                {comment.accName} {loginInfo.id == comment.accountID && '(me)'}
                            </h4>
                            <span className="color_indictive">{Timeago(comment.createdAt)}</span>
                        </div>
                        <pre style={{ whiteSpace: 'pre-wrap' }}>{comment.content}</pre>
                    </List.Item>
                )}
            />
            <Space
                direction="vertical"
                style={{
                    display: 'flex',
                }}
            >
                <TextArea
                    value={addedComment}
                    onChange={(e) => setAddedComment(e.target.value)}
                    placeholder="留言"
                    autoSize={{
                        minRows: 5,
                    }}
                />
                <Button type="primary" onClick={handleSubmit} loading={posting} disabled={addedComment?.trim() === ''}>
                    Submit
                </Button>
            </Space>
        </div>
    );
}

Comments.propTypes = {
    commentOnID: PropTypes.string,
};

export default Comments;
