import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { useSelector } from 'react-redux';
import ActivityPickerItem from './ActivityPickerItem';

function ActivityPicker({ citySelectors, selectors, deselectors, plays = [], onSelect = () => {} }) {
    const activities = useSelector((state) => state.activities.data);
    const fetching = useSelector((state) => state.activities.fetching);
    const [filteredActivities, setFilteredActivities] = useState([]);

    useEffect(() => {
        filterActivities(citySelectors, selectors, deselectors);
        return () => {};
    }, [activities, citySelectors, selectors, deselectors]);

    const filterActivities = useCallback(
        (citySelectors, selectedSelectors, deselectedSelectors) => {
            let newData = [...activities];

            // Filter for city selectors
            if (Array.isArray(citySelectors) && citySelectors.length > 0) {
                newData = newData.filter((item) => {
                    return citySelectors.some((selector) => item.selectors?.includes(selector));
                });
            }

            if (Array.isArray(selectedSelectors) && selectedSelectors.length > 0) {
                newData = newData.filter((item) => {
                    return selectedSelectors.some((selector) => item.selectors?.includes(selector));
                });
            }

            if (Array.isArray(deselectedSelectors) && deselectedSelectors.length > 0) {
                newData = newData.filter((item) => {
                    const matchesDeselected = deselectedSelectors.some((selector) =>
                        item.selectors?.includes(selector)
                    );
                    return !matchesDeselected;
                });
            }

            // Modified selector sorting to handle multiple selectors
            newData?.sort((a, b) => {
                // Get the lowest sort value among all matching selectors for each item
                const getMinSortValue = (activity) => {
                    const matchingItems = activity.selectorItems
                        .filter((item) => citySelectors?.includes(item.name))
                        .map((item) => item.sort);
                    return Math.min(...matchingItems);
                };
                const sortA = getMinSortValue(a);
                const sortB = getMinSortValue(b);
                return sortA - sortB;
            });

            setFilteredActivities(newData);
        },
        [activities] // Only depends on activities
    );

    return (
        <div className={styles.container}>
            {filteredActivities.map((item, index) => {
                const selected = !!plays.find((elem) => elem.activityID == item.id);
                return <ActivityPickerItem key={index} activity={item} selected={selected} onSelect={onSelect} />;
            })}
        </div>
    );
}

ActivityPicker.propTypes = {
    citySelectors: PropTypes.arrayOf(PropTypes.string),
    selectors: PropTypes.arrayOf(PropTypes.string),
    deselectors: PropTypes.arrayOf(PropTypes.string),
    plays: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
};

export default ActivityPicker;
