import { useCallback, useState, useRef } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useStayEnquiryPost() {
    const [posting, setPosting] = useState(false);
    const abortRef = useRef();

    const postStayEnquiry = useCallback(async (data) => {
        abort();
        setPosting(true);
        abortRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL2}/stay-enquiries`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${GetToken()}`,
                },
                body: JSON.stringify(data),
                signal: abortRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred posting an enquiry');
            }
            const result = await response.json();
            return result;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error posting an enquiry:', error);
            }
            throw error;
        } finally {
            setPosting(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);

    return { posting, postStayEnquiry, abort };
}

export default useStayEnquiryPost;
