import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { GetFlightSearchQueryHistory, CleanupFlightSearchQueryHistory } from '@utils/storage';
import styles from './styles.module.css';
import { Collapse, Typography } from 'antd';
const { Text } = Typography;
import IconOneway from '@assets/icon_oneway.svg';
import IconRoundTrip from '@assets/icon_round_trip.svg';
import { useMyContext } from '../../../contexts/MyContext';
import { FaRegTrashCan } from 'react-icons/fa6';

function index() {
    const { setFlightSearchReq } = useMyContext();
    const initData = GetFlightSearchQueryHistory() ?? [];
    const [data, setData] = useState(initData);
    const [activieKey, setActivieKey] = useState(initData?.length ? ['1'] : []);

    useEffect(() => {
        if (data?.length) {
            setActivieKey(['1']);
        } else {
            setActivieKey([]);
        }
    }, [data]);

    const handleOnClick = (item) => {
        setFlightSearchReq(item);
    };

    const Content = () => {
        return (
            <div className={styles.grid_container}>
                {data.map((item, index) => {
                    return (
                        <div key={index} className={styles.item_container} onClick={() => handleOnClick(item)}>
                            <div className={styles.top_container}>
                                <Text
                                    style={{ fontSize: '16px' }}
                                    ellipsis={{ tooltip: `${item.fromCity} - ${item.toCity}` }}
                                >
                                    {item.fromCity}
                                </Text>
                                <div className={styles.icon_container}>
                                    <img src={item.returnDate ? IconRoundTrip : IconOneway} />
                                </div>
                                <Text
                                    style={{ fontSize: '16px' }}
                                    className={styles.to}
                                    ellipsis={{ tooltip: `${item.fromCity} - ${item.toCity}` }}
                                >
                                    {item.toCity}
                                </Text>
                            </div>
                            <div className={styles.bottom_container}>
                                <div>{item.retDate ? '往返' : '单程'}</div>
                                <div>{item.fromDate}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    const handleFlush = (e) => {
        e.stopPropagation();
        if (confirm('确定清空搜索记录吗？') !== true) return;
        CleanupFlightSearchQueryHistory();
        setData([]);
    };

    const Trigger = () => {
        return (
            <div className={styles.trigger_container}>
                <div className={styles.leading_container}>最近搜索</div>
                {data.length > 0 && (
                    <div className={styles.trailing_container} onClick={handleFlush}>
                        <FaRegTrashCan />
                        <span>清空历史</span>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <Collapse
                ghost
                activeKey={activieKey}
                expandIconPosition="start"
                items={[
                    {
                        key: '1',
                        label: <Trigger />,
                        children: <Content />,
                    },
                ]}
                onChange={(key) => {
                    setActivieKey(key);
                }}
            />
        </div>
    );
}

index.propTypes = {};

export default index;
