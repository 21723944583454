import React, { useState } from 'react';
import { Form, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { addMessage, MsgLevel } from '@features/messages/messagesSlice';
import { useDispatch } from 'react-redux';

const Test = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const onFinish = async (values) => {
        console.log('Received values:', values);
    };

    const beforeUpload = (file) => {
        return false; // Prevent automatic upload
    };

    return (
        <div>
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.Item
                    name="upload"
                    label="Upload File"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                        {
                            required: true,
                            message: 'Please upload a file',
                        },
                    ]}
                >
                    <Upload beforeUpload={beforeUpload} listType="text">
                        <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit Form and Upload
                    </Button>
                </Form.Item>
            </Form>

            <Button
                type="primary"
                onClick={() => {
                    // get time stamp
                    const time = new Date().getTime();
                    dispatch(addMessage({ type: 'error', content: 'This is a success message' + time }));
                }}
            >
                add message
            </Button>

            {/* <img src="https://aotravel-resources.oss-cn-beijing.aliyuncs.com/images_activity/史蒂芬斯港海豚观赏快船巡游-60分钟_1932F9B7B38.png"></img> */}
        </div>
    );
};

export default Test;
