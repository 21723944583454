import { useCallback, useRef, useState } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';
import { RemoveEmpty } from '@utils/utils';

function useFlightDeals(props) {
    const [fetching, setFetching] = useState(false);
    const [deals, setDeals] = useState([]);
    const [total, setTotal] = useState();

    const abortRef = useRef();

    const fetchDeals = useCallback(async (query) => {
        abort();
        abortRef.current = new AbortController();
        setFetching(true);
        const q = RemoveEmpty(query);
        const queryString = new URLSearchParams(q).toString();
        // console.log('query:', queryString);
        try {
            const response = await fetch(`${config.apiURL2}/flight-deals?${queryString}`, {
                signal: abortRef.current.signal,
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                },
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Something went wrong when fetching deals');
            }
            const data = await response.json();
            const { deals, total } = data;
            setTotal(total);
            setDeals(deals || []);
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetch deals:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);

    return { fetching, deals, total, fetchDeals, abort };
}

export default useFlightDeals;
