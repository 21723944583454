import React from 'react';
import PropTypes from 'prop-types';
import styles from './CityPicker.module.css';
import { MdLocalAirport, MdOutlineLocationOn } from 'react-icons/md';

function ResultItem({ item = {}, handleOnClick = () => {}, cityOnly = false }) {
    const Title = () => {
        if (cityOnly) {
            return (
                <>
                    {item.cityEnglishName} ({item.cityCode})
                </>
            );
        }
        return (
            <>
                {item.airportEnglishName}({item.cityCode})
            </>
        );
    };

    const Subtitle = () => {
        if (cityOnly) {
            return item.countryName;
        }
        return (
            <>
                {item.countryEnglishName} {item.cityEnglishName}
            </>
        );
    };

    return (
        <div className={styles.result_item_container} onClick={() => handleOnClick(item)}>
            <div className={styles.leading_container}>
                {cityOnly ? (
                    <MdOutlineLocationOn className={styles.icon} />
                ) : (
                    <MdLocalAirport className={`${styles.icon} ${styles.deg45}`} />
                )}
            </div>
            <div className={styles.trailing_container}>
                <div className={styles.title_container}>
                    <Title />
                </div>
                <div className={styles.subtitle_container}>
                    <Subtitle />
                </div>
            </div>
        </div>
    );
}

ResultItem.propTypes = {
    item: PropTypes.object,
    handleOnClick: PropTypes.func.isRequired,
    cityOnly: PropTypes.bool,
    defaultResults: PropTypes.array,
};

export default ResultItem;
