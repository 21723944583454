import React from 'react';
import PropTypes from 'prop-types';
import { Button, InputNumber, Modal } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import useFlightDealBook from './hooks/useFlightDealBook';
import useFlightDeal from './hooks/useFlightDeal';
import { useNavigate } from 'react-router';

function Actions({ item }) {
    const [showBook, setShowBook] = useState(false);
    const [showReserve, setShowReserve] = useState(false);
    const [seats, setSeats] = useState(1);
    const { submitting, bookFlightDeal } = useFlightDealBook();
    const { fetching, deal, fetchDeal, abort } = useFlightDeal();
    const navigate = useNavigate();

    useEffect(() => {
        if (deal.remainingSeats) {
            setSeats(deal.remainingSeats);
        }
        return () => abort();
    }, [deal.remainingSeats]);

    useEffect(() => {
        if (showBook) {
            fetchDeal(item.id);
        }
        return () => abort();
    }, [showBook]);

    useEffect(() => {
        if (showReserve) {
            fetchDeal(item.id);
        }
        return () => abort();
    }, [showReserve]);

    const handleBooking = (reserve) => {
        const req = {
            dealID: item.id,
            seats: seats,
            reserve: reserve,
        };
        // console.log('req:', req);
        bookFlightDeal(req)
            .then((data) => {
                setShowBook(false);
                const { enquiryID, reply } = data;
                if (enquiryID) {
                    alert(reply || '成功');
                    navigate(`/dashboard/air-tickets/git/enquiries/${enquiryID}`);
                }
            })
            .catch((error) => {
                alert(error.message);
            });
    };

    return (
        <>
            <Button
                className="button"
                size="small"
                onClick={() => setShowBook(true)}
                loading={submitting}
                disabled={item.remainingSeats === 0}
            >
                预订
            </Button>
            <Modal
                title="预订位置(最少10个位置)"
                open={showBook}
                onCancel={() => setShowBook(false)}
                footer={null}
                centered
                maskClosable={false}
                loading={fetching}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
                    <InputNumber
                        value={seats}
                        max={item.remainingSeats}
                        defaultValue={item.remainingSeats}
                        onChange={(value) => setSeats(value)}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleBooking(false)}
                        loading={submitting}
                        disabled={seats === 0}
                    >
                        预订
                    </Button>
                </div>
            </Modal>
            <Button
                className="button"
                size="small"
                onClick={() => setShowReserve(true)}
                loading={submitting}
                disabled={item.remainingSeats === 0}
            >
                预留
            </Button>
            <Modal
                title="预留位置(最少10个位置)"
                open={showReserve}
                onCancel={() => setShowReserve(false)}
                footer={null}
                centered
                maskClosable={false}
                loading={fetching}
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
                    <InputNumber
                        value={seats}
                        max={item.remainingSeats}
                        defaultValue={item.remainingSeats}
                        onChange={(value) => setSeats(value)}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleBooking(true)}
                        loading={submitting}
                        disabled={seats === 0}
                    >
                        预留
                    </Button>
                </div>
            </Modal>
        </>
    );
}

Actions.propTypes = {
    item: PropTypes.object.isRequired,
};

export default Actions;
