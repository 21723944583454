import { useEffect, useRef, useState, useCallback } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function usePlayAdd() {
    const [adding, setAdding] = useState(false);
    const abortRef = useRef();

    const addPlay = useCallback(async ({ activityID, destinationID }) => {
        setAdding(true);
        abort();
        abortRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL}/plays`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${GetToken()}`,
                },
                body: JSON.stringify({ activityID, destinationID }),
                signal: abortRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred adding a play');
            }
            const result = await response.json();
            return result;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error adding a play:', error);
            }
            throw error;
        } finally {
            setAdding(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);

    return { adding, addPlay, abort };
}

export default usePlayAdd;
