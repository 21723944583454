import { useCallback, useRef, useState } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';
import { RemoveEmpty } from '@utils/utils';

function useRideOrders() {
    const [fetching, setFetching] = useState(false);
    const [orders, setOrders] = useState([]);
    const [total, setTotal] = useState(0);

    const abortRef = useRef();

    const fetchOrders = useCallback(async ({ page = 1, pageSize = 20, ...rest }) => {
        abort();
        setFetching(true);
        abortRef.current = new AbortController();
        // in case page and pageSize are string
        page = parseInt(page);
        pageSize = parseInt(pageSize);
        let query = {
            offset: (page - 1) * pageSize,
            limit: pageSize,
            ...rest,
        };
        const q = RemoveEmpty(query);
        const queryString = new URLSearchParams(q).toString();
        try {
            const response = await fetch(`${config.apiURL2}/ride/orders?${queryString}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                },
                signal: abortRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to fetch ride orders');
            }
            const data = await response.json();
            const { total, orders } = data;
            setOrders(orders || []);
            setTotal(total || 0);
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetch ride orders:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);

    return { fetching, orders, total, fetchOrders, abort };
}

export default useRideOrders;
