import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TransferForm.module.css';
import styles_input from '@styles/InputContainerLarge.module.css';
import { MdLocationOn, MdOutlineLocationOn, MdHourglassTop, MdGroup, MdOutlineLuggage } from 'react-icons/md';
import '@styles/day-picker.css';
import dayjs from 'dayjs';
import { Segmented } from 'antd';
import DatePickerField from './components/DatePickerField';
import TimePickerField from './components/TimePickerField';
import LocationField from './components/LocationField';
import QtyPickerField from './components/QtyPickerField';
import { MdOutlineSwapHoriz } from 'react-icons/md';

export const k_transfer_form_data = 'k_transfer_form_data';

const MIN_HOURS = '3';

// 3 - 8 hours
const HOURS_ARRAY = ['3', '4', '5', '6', '7', '8', '9', '10'];

const TRIP_TYPES = {
    ONE_WAY: 'ONE_WAY',
    BY_HOUR: 'BY_HOUR',
};

// values are string beacuse they are used in URLSearchParams
export const DEFAULT_DATA = {
    time: '',
    date: '',
    pickup: '',
    pickupPlaceID: '',
    dropoff: '',
    dropoffPlaceID: '',
    passengerCount: '2',
    luggageCount: '2',
    hours: '', // empty means one way, otherwise by hours
    returnTrip: 'false',
    retDate: '', // for return trip
    retTime: '', // for return trip
};

function TransferForm({ data, setData }) {
    const [openCalendar, setOpenCalendar] = useState(false);
    const [openRetCalendar, setOpenRetCalendar] = useState(false);
    const [openPickup, setOpenPickup] = useState(false);
    const [openDropoff, setOpenDropoff] = useState(false);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        console.log([name, value]);

        setData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    // type: pickup, dropoff
    const handleLocationSelect = (type) => (locationData) => {
        console.log('Selected location:', locationData);
        const { description, placeID } = locationData;
        setData((prev) => ({
            ...prev,
            [`${type}`]: description,
            [`${type}PlaceID`]: placeID,
        }));
        if (type === 'pickup') setOpenPickup(false);
        if (type === 'dropoff') setOpenDropoff(false);
    };

    const handleTripTypeChange = (value) => {
        if (value == TRIP_TYPES.ONE_WAY) {
            setData((prev) => {
                delete prev.hours;
                return { ...prev };
            });
        } else {
            setData((prev) => {
                return { ...prev, hours: MIN_HOURS };
            });
        }
    };

    const handleByHours = (e) => {
        const { name, value } = e.target;
        console.log([name, value]);
        setData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const handleChangeReturnTrip = (e) => {
        setData((prev) => {
            const returnTrip = prev.returnTrip === 'true' ? 'false' : 'true';
            if (returnTrip === 'false') {
                delete prev.retDate;
                delete prev.retTime;
            }
            return { ...prev, returnTrip: returnTrip };
        });
    };

    const handleDateSelect = (date) => {
        setData((prev) => ({
            ...prev,
            date: date ? dayjs(date).format('YYYY-MM-DD') : '',
        }));
        setOpenCalendar(false);
    };

    const handleRetDateSelect = (date) => {
        setData((prev) => ({
            ...prev,
            retDate: date ? dayjs(date).format('YYYY-MM-DD') : '',
        }));
        setOpenRetCalendar(false);
    };

    const renderFromLoation = () => (
        <LocationField
            icon={MdLocationOn}
            label={data.hours ? '起点/终点' : '起点'}
            value={data.pickup}
            isOpen={openPickup}
            setIsOpen={setOpenPickup}
            onSelect={handleLocationSelect('pickup')}
            placeholder="请输入出发地机场，酒店，地址"
        />
    );

    const handleSwap = () => {
        console.log('swap locations');
        setData((prev) => {
            return {
                ...prev,
                pickup: prev.dropoff,
                dropoff: prev.pickup,
                pickupPlaceID: prev.dropoffPlaceID,
                dropoffPlaceID: prev.pickupPlaceID,
            };
        });
    };

    return (
        <div className={styles.container}>
            <div>
                <Segmented
                    options={[
                        { label: '单次接送', value: TRIP_TYPES.ONE_WAY },
                        { label: '按时包车', value: TRIP_TYPES.BY_HOUR },
                    ]}
                    value={data.hours ? TRIP_TYPES.BY_HOUR : TRIP_TYPES.ONE_WAY}
                    onChange={handleTripTypeChange}
                />
            </div>
            <div className={styles.group_container}>
                <DatePickerField
                    label="日期"
                    value={data.date}
                    isOpen={openCalendar}
                    setIsOpen={setOpenCalendar}
                    onSelect={handleDateSelect}
                    defaultMonth={data.date ? new Date(data.date) : new Date()}
                />
                <TimePickerField label="时间" name="time" value={data.time} onChange={handleOnChange} />
            </div>
            <div className={styles.group_container}>
                <QtyPickerField
                    icon={MdGroup}
                    label="乘客"
                    name="passengerCount"
                    value={data.passengerCount}
                    onChange={handleOnChange}
                />
                <QtyPickerField
                    icon={MdOutlineLuggage}
                    label="行李"
                    name="luggageCount"
                    value={data.luggageCount}
                    onChange={handleOnChange}
                />
            </div>
            {!data.hours && (
                <div className={`${styles.group_container} ${styles.locations_container}`}>
                    {renderFromLoation()}
                    <div className={styles.location_swap_container}>
                        <MdOutlineSwapHoriz className={styles.icon} onClick={handleSwap} />
                    </div>
                    <LocationField
                        icon={MdOutlineLocationOn}
                        label="终点"
                        value={data.dropoff}
                        isOpen={openDropoff}
                        setIsOpen={setOpenDropoff}
                        onSelect={handleLocationSelect('dropoff')}
                        placeholder="请输入目的地机场，酒店，地址"
                    />
                </div>
            )}
            {data.hours && (
                <div className={styles.group_container}>
                    {renderFromLoation()}
                    <div className={styles_input.input_container}>
                        <MdHourglassTop className={styles_input.icon} />
                        <label>
                            <div className={styles_input.label_content}>用时(小时)</div>
                        </label>
                        <select
                            className={styles_input.input}
                            name="hours"
                            value={data.hours}
                            placeholder="用时"
                            onChange={handleByHours}
                        >
                            {HOURS_ARRAY.map((item, index) => {
                                return (
                                    <option key={index} value={item}>
                                        {item} 小时
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            )}

            {!data.hours && (
                <>
                    <div className={styles_input.checkbox_container}>
                        <input
                            id="returnTrip"
                            type="checkbox"
                            className={styles_input.checkbox}
                            checked={data.returnTrip === 'true'}
                            onChange={handleChangeReturnTrip}
                        />
                        <label htmlFor="returnTrip">往返行程 (返程节省10%)</label>
                    </div>
                    {data.returnTrip === 'true' && (
                        <div className={styles.group_container}>
                            <DatePickerField
                                label="日期"
                                value={data.retDate}
                                isOpen={openRetCalendar}
                                setIsOpen={setOpenRetCalendar}
                                onSelect={handleRetDateSelect}
                                defaultMonth={
                                    data.retDate ? new Date(data.retDate) : data.date ? new Date(data.date) : new Date()
                                }
                            />
                            <TimePickerField
                                label="时间"
                                name="retTime"
                                value={data.retTime}
                                onChange={handleOnChange}
                            />
                        </div>
                    )}
                </>
            )}
            <div className={styles.action_container}></div>
        </div>
    );
}

TransferForm.propTypes = {
    data: PropTypes.object.isRequired,
    setData: PropTypes.func.isRequired,
};

export default TransferForm;
