import React, { useEffect, useId, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import styles from '@styles/HotelCheckout.module.css';
import {
    Form,
    FormGroup,
    FormField,
    Dimmer,
    Loader,
    Table,
    TableHeader,
    TableHeaderCell,
    TableBody,
    TableRow,
    TableCell,
    TableFooter,
    Modal,
    Icon,
    ModalHeader,
    ModalContent,
    ModalActions,
} from 'semantic-ui-react';
import qs from 'qs';
import { useSearchParams } from 'react-router-dom';
import { fetchHotelOrders } from '@features/hotelorders/ordersSlice';
import { useDispatch } from 'react-redux';
import BackBar from '@components/BackBar';
import useHotelVerify from '@hooks/hotel/useHotelVerify';
import { format, parse } from 'date-fns';
import { GetNightsBetweenDates } from '@components/hotel/RoomsTable';
import useHotelCreatePNR from '@hooks/hotel/useHotelCreatePNR';
import { DevMode } from '@utils/utils';
import { Checkbox, Result, Button } from 'antd';

const DefaultPaymentData = {
    cardCode: '',
    cardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    cvv: '',
    cardName: '',
    firstname: '',
    lastname: '',
};

const DefaultGuestData = {
    roomIndex: '',
    type: 10, //Adult
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
};

// RateKey
function HotelCheckout(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const rateKey = qs.parse(searchParams.toString()).rateKey;
    const { verifying, priceVerify, bookingKey, totalCost, abort: abortVerify } = useHotelVerify();
    const { submitting, createHotelPNR, abort: abortCreatePRN } = useHotelCreatePNR();

    const paymentFormID = useId();

    const [useCc, setUseCc] = useState(true);

    const [hotelInfo, setHotelInfo] = useState({});
    const [rateInfos, setRateInfos] = useState([]);
    const [acceptedCards, setAcceptedCards] = useState([]);
    const [guarantee, setGuarantee] = useState();
    const [rooms, setRooms] = useState([]);

    const [paymentData, setPaymentData] = useState(DefaultPaymentData);
    const [confirmationID, setConfirmationID] = useState(); //used to indicate booking completion

    useEffect(() => {
        if (rateKey) {
            handlePriceCheck();
        }
        return () => {
            abortVerify();
            abortCreatePRN();
        };
    }, []);

    const handlePriceCheck = () => {
        priceVerify(rateKey)
            .then((res) => {
                const data = res;
                const hotelInfo = data.hotelInfo;
                if (hotelInfo) {
                    setHotelInfo(hotelInfo);
                }
                const rateInfos = data.hotelRateInfo.rateInfos.convertedRateInfo;
                if (rateInfos) {
                    setRateInfos(rateInfos);
                }
                const roomRateInfo = data.hotelRateInfo.rooms[0]?.ratePlans[0]?.convertedRateInfo;
                if (roomRateInfo) {
                    setGuarantee(roomRateInfo.guarantee);
                    const paymentCard = roomRateInfo.guarantee.guaranteesAccepted.find((item) => {
                        return item.guaranteeTypeCode == 5;
                    }).paymentCards.paymentCard;
                    setAcceptedCards(paymentCard);
                }
                setRooms(data.hotelRateInfo.rooms);
                if (data.convertedPriceChange || data.priceChange) {
                    alert('价格有变动，请重新查看');
                    //TODO: close or navigate back
                    navigate(-1);
                }
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            });
    };

    const handlePaymentChange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        // console.log({ [name]: value });
        switch (name) {
            case 'cardNumber':
                //Only number and space
                if (value != '') {
                    if (!onlyNumberSpace(value)) return;
                }
                value = formatCardNumber(value);
                console.log(value);
                break;
        }
        setPaymentData((prev) => {
            return { ...prev, [name]: value };
        });
    };

    const handleSubmit = () => {
        console.log('submit');
        const leadGuests = [];
        for (let i = 0; i < rooms.length; i++) {
            const guestFormID = `guest${i + 1}`;
            const guestForm = document.getElementById(guestFormID);
            if (!guestForm.reportValidity()) {
                console.error('guest form invalid', guestFormID);
                return;
            }
            leadGuests.push({
                type: 10, //Adult
                roomIndex: rooms[i].roomIndex,
                firstname: guestForm.querySelector('input[name="firstname"]').value,
                lastname: guestForm.querySelector('input[name="lastname"]').value,
                email: guestForm.querySelector('input[name="email"]').value,
                mobile: guestForm.querySelector('input[name="mobile"]').value,
            });
        }

        console.log(useCc);

        if (useCc) {
            const paymentForm = document.getElementById(paymentFormID);
            if (paymentForm) {
                if (!paymentForm.reportValidity()) {
                    console.error('payment form invalid', paymentFormID);
                    return;
                }
            }
        }

        console.log('guests', leadGuests);

        createHotelPNR({
            bookingKey: bookingKey,
            paymentData: paymentData,
            leadGuests: leadGuests,
            rateKey: rateKey,
            numOfRooms: rooms.length,
            amountAfterTax: totalCost,
        })
            .then((data) => {
                console.log(data);
                const confirmationID = data.confirmationID;
                if (confirmationID) {
                    setConfirmationID(confirmationID);
                    dispatch(fetchHotelOrders());
                }
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            });
    };

    const fillDemoCard = () => {
        const year = new Date().getFullYear() + 1;
        const demoPaymentData = {
            cardNumber: '5982761200000006',
            expiryMonth: '12',
            expiryYear: String(year),
            cvv: '123',
            cardName: 'kk',
            firstname: 'Xiangkai',
            lastname: 'Gu',
        };
        setPaymentData((prev) => {
            return { ...prev, ...demoPaymentData };
        });
    };

    if (!rateKey) {
        return <div>Empty rate, please try again.</div>;
    }

    const gotoOrders = () => {
        navigate('/dashboard/accommodations/hotels/orders', { replace: true });
    };

    const Success = () => {
        return (
            <Result
                status="success"
                title={`Successfully Booked ${hotelInfo.hotelName}!`}
                subTitle={`Confirmation number: ${confirmationID}`}
                extra={[
                    <Button type="primary" key="orders" onClick={gotoOrders}>
                        Goto orders
                    </Button>,
                ]}
            />
        );
    };

    return (
        <>
            {confirmationID ? (
                Success()
            ) : (
                <>
                    <BackBar />
                    <br />
                    <Dimmer inverted page active={verifying}>
                        <Loader>Loading</Loader>
                    </Dimmer>
                    <Dimmer inverted page active={submitting}>
                        <Loader>Submitting</Loader>
                    </Dimmer>
                    <div className={styles.container}>
                        <div className={styles.leading_container}>
                            {rateInfos.map((rateInfo, index) => {
                                return <RateTable key={index} hoteInfo={hotelInfo} rateInfo={rateInfo} rooms={rooms} />;
                            })}
                        </div>
                        <div className={styles.trailing_container}>
                            <section className={styles.guest_container}>
                                {rooms.map((room, index) => {
                                    return (
                                        <div key={index}>
                                            <h3>Lead Guest For Room {index + 1}</h3>
                                            <Form id={`guest${index + 1}`}>
                                                <FormGroup widths={'equal'}>
                                                    <FormField required>
                                                        <label>First Name(名)</label>
                                                        <input
                                                            name="firstname"
                                                            required
                                                            placeholder="First Name(名)"
                                                            type="text"
                                                        />
                                                    </FormField>
                                                    <FormField required>
                                                        <label>Last Name(姓)</label>
                                                        <input
                                                            name="lastname"
                                                            required
                                                            placeholder="Last Name"
                                                            type="text"
                                                        />
                                                    </FormField>
                                                    <FormField required>
                                                        <label>Email</label>
                                                        <input name="email" required type="email" />
                                                    </FormField>
                                                    <FormField required>
                                                        <label>Mobile</label>
                                                        <input name="mobile" required type="tel" />
                                                    </FormField>
                                                </FormGroup>
                                            </Form>
                                        </div>
                                    );
                                })}
                            </section>
                            <section className={styles.payment_container}>
                                <Checkbox checked={useCc} onChange={(e) => setUseCc(e.target.checked)}>
                                    Use Credit Card
                                </Checkbox>

                                {useCc && (
                                    <>
                                        <h3>
                                            Payment Information{DevMode() && <Icon name="spy" onClick={fillDemoCard} />}
                                        </h3>
                                        <Form id={paymentFormID} onSubmit={handleSubmit}>
                                            <FormField required>
                                                <label>Card Issuer</label>
                                                <select
                                                    name="cardCode"
                                                    value={paymentData.cardCode}
                                                    required
                                                    onChange={handlePaymentChange}
                                                >
                                                    <option disabled value=""></option>
                                                    {acceptedCards?.map((item, index) => {
                                                        return (
                                                            <option key={index} value={item.cardCode}>
                                                                {item.value}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </FormField>
                                            <FormField required>
                                                <label>Card Number</label>
                                                <input
                                                    name="cardNumber"
                                                    value={paymentData.cardNumber}
                                                    required
                                                    onChange={handlePaymentChange}
                                                    autoComplete="cc-number"
                                                    maxLength="23"
                                                    type="text"
                                                />
                                            </FormField>
                                            <FormGroup widths={'equal'}>
                                                <FormField required>
                                                    <label>First Name</label>
                                                    <input
                                                        name="firstname"
                                                        value={paymentData.firstname}
                                                        required
                                                        type="text"
                                                        onChange={handlePaymentChange}
                                                    />
                                                </FormField>
                                                <FormField required>
                                                    <label>Last Name</label>
                                                    <input
                                                        name="lastname"
                                                        value={paymentData.lastname}
                                                        required
                                                        type="text"
                                                        onChange={handlePaymentChange}
                                                    />
                                                </FormField>
                                            </FormGroup>
                                            <FormGroup widths={'equal'}>
                                                <FormField required>
                                                    <label>Month</label>
                                                    <select
                                                        name="expiryMonth"
                                                        value={paymentData.expiryMonth}
                                                        required
                                                        onChange={handlePaymentChange}
                                                        autoComplete="cc-exp-month"
                                                    >
                                                        <option disabled value=""></option>
                                                        <option value="1">01</option>
                                                        <option value="2">02</option>
                                                        <option value="3">03</option>
                                                        <option value="4">04</option>
                                                        <option value="5">05</option>
                                                        <option value="6">06</option>
                                                        <option value="7">07</option>
                                                        <option value="8">08</option>
                                                        <option value="9">09</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                    </select>
                                                </FormField>
                                                <FormField required>
                                                    <label>Year</label>
                                                    <select
                                                        name="expiryYear"
                                                        value={paymentData.expiryYear}
                                                        required
                                                        onChange={handlePaymentChange}
                                                        autoComplete="cc-exp-year"
                                                    >
                                                        <option disabled value=""></option>
                                                        {[...Array(6)].map((_, i) => {
                                                            const currentYear = new Date().getFullYear();
                                                            return (
                                                                <option key={i} value={currentYear + i}>
                                                                    {currentYear + i}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </FormField>
                                                <FormField required>
                                                    <label>CVV</label>
                                                    <input
                                                        name="cvv"
                                                        value={paymentData.cvv}
                                                        required
                                                        type="number"
                                                        onChange={handlePaymentChange}
                                                    />
                                                </FormField>
                                            </FormGroup>
                                        </Form>
                                    </>
                                )}
                            </section>
                        </div>
                        <div className={styles.action_container}>
                            <Button type="primary" onClick={handleSubmit}>
                                Submit
                            </Button>
                        </div>
                        {DevMode() && <pre>{JSON.stringify(guarantee, null, 2)}</pre>}
                    </div>
                </>
            )}
        </>
    );
}

HotelCheckout.propTypes = {};

export default HotelCheckout;

const RateTable = ({ hoteInfo = {}, rateInfo = {}, rooms = [] }) => {
    let selectedRooms = rooms.filter((room) => room.ratePlans.some((plan) => plan.rateKey == rateInfo.rateKey));

    const PriceDetails = ({ roomRate = {} }) => {
        const [open, setOpen] = useState(false);
        return (
            <>
                <div>
                    {roomRate.currencyCode} {roomRate.averageNightlyRate}/night
                </div>
                <div>
                    <a onClick={() => setOpen(true)}>Details</a>
                    <Modal open={open} onClose={() => setOpen(false)}>
                        <Modal.Header>Price Details</Modal.Header>
                        <Modal.Content>
                            <Table celled>
                                <TableHeader>
                                    <Table.Row>
                                        <Table.HeaderCell>Before Tax</Table.HeaderCell>
                                        <Table.HeaderCell>After Tax</Table.HeaderCell>
                                        <Table.HeaderCell>Taxes</Table.HeaderCell>
                                        <Table.HeaderCell>Fees</Table.HeaderCell>
                                    </Table.Row>
                                </TableHeader>
                                <TableBody>
                                    <Table.Row>
                                        <Table.Cell>
                                            <div>
                                                {roomRate.currencyCode} {roomRate.amountBeforeTax}
                                            </div>
                                            <div>
                                                {roomRate.currencyCode} {roomRate.averageNightlyRateBeforeTax}/night
                                            </div>
                                        </Table.Cell>

                                        <Table.Cell>
                                            <div>
                                                {roomRate.currencyCode} {roomRate.amountAfterTax}
                                            </div>
                                            <div>
                                                {roomRate.currencyCode} {roomRate.averageNightlyRate}/night
                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>
                                            {roomRate.taxes &&
                                                `${roomRate.taxes.currencyCode} ${roomRate.taxes.amount}`}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {roomRate.fees && `${roomRate.fees.currencyCode} ${roomRate.fees.amount}`}
                                        </Table.Cell>
                                    </Table.Row>
                                </TableBody>
                            </Table>
                        </Modal.Content>
                        <ModalActions>
                            <Button type="primary" onClick={() => setOpen(false)}>
                                Close
                            </Button>
                        </ModalActions>
                    </Modal>
                </div>
            </>
        );
    };

    const AdditionalInfo = ({ roomRate = {} }) => {
        const [open, setOpen] = useState(false);
        return (
            <div>
                <a onClick={() => setOpen(true)}>Additional</a>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <Modal.Header>Additional Info</Modal.Header>
                    <Modal.Content>
                        {roomRate.additionalDetails && (
                            <ol style={{ paddingLeft: '16px' }}>
                                {roomRate.additionalDetails.map((item, index) => {
                                    return <li key={index}>{item.text}</li>;
                                })}
                            </ol>
                        )}
                    </Modal.Content>
                    <ModalActions>
                        <Button type="primary" onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </ModalActions>
                </Modal>
            </div>
        );
    };

    const Policy = ({ roomRate = {} }) => {
        const [open, setOpen] = useState(false);
        return (
            <>
                <div>
                    <a onClick={() => setOpen(true)}>Cancel Penalties</a>
                    <Modal open={open} onClose={() => setOpen(false)}>
                        <Modal.Header>Cancel Penalties</Modal.Header>
                        <Modal.Content>
                            <p style={{ whiteSpace: 'pre-wrap' }}>{roomRate.cancelPenaltiesText}</p>
                        </Modal.Content>
                        <ModalActions>
                            <Button type="primary" onClick={() => setOpen(false)}>
                                Close
                            </Button>
                        </ModalActions>
                    </Modal>
                </div>
            </>
        );
    };

    return (
        <Table celled>
            <TableHeader>
                <TableRow>
                    <TableHeaderCell>#</TableHeaderCell>
                    <TableHeaderCell>Hotel</TableHeaderCell>
                    <TableHeaderCell>Room</TableHeaderCell>
                    <TableHeaderCell>Check-in</TableHeaderCell>
                    <TableHeaderCell>Check-out</TableHeaderCell>
                    <TableHeaderCell>Stay</TableHeaderCell>
                    <TableHeaderCell>Cancel Penalties</TableHeaderCell>
                    <TableHeaderCell>Additional Info</TableHeaderCell>
                    <TableHeaderCell>Pricing</TableHeaderCell>
                    <TableHeaderCell>Commission</TableHeaderCell>
                </TableRow>
            </TableHeader>
            <TableBody>
                {selectedRooms.map((room, index) => {
                    const ratePlan = room.ratePlans.find((plan) => plan.rateKey == rateInfo.rateKey);
                    const roomRate = ratePlan.convertedRateInfo;
                    return (
                        <TableRow key={index}>
                            <TableCell>{room.roomIndex}</TableCell>
                            <TableCell>
                                <div>{hoteInfo.hotelName}</div>
                                <div className={styles.address}>{hoteInfo.fullAddress}</div>
                            </TableCell>
                            <TableCell>{room.roomType}</TableCell>
                            <TableCell>
                                <div>{timePrint(roomRate.startDate)}</div>
                                <div>From 2:00 PM</div>
                            </TableCell>
                            <TableCell>
                                <div>{timePrint(roomRate.endDate)}</div>
                                <div>Until 11:00 AM</div>
                            </TableCell>
                            <TableCell>{GetNightsBetweenDates(roomRate.startDate, roomRate.endDate)} night</TableCell>
                            <TableCell>
                                <Policy roomRate={roomRate} />
                            </TableCell>
                            <TableCell>
                                <AdditionalInfo roomRate={roomRate} />
                            </TableCell>

                            <TableCell>
                                <PriceDetails roomRate={roomRate} />
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
            <TableFooter>
                <TableRow>
                    <TableHeaderCell colSpan="8" textAlign="right">
                        Total:
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <b>
                            {rateInfo.currencyCode} {rateInfo.amountAfterTax}
                        </b>
                    </TableHeaderCell>
                    <TableHeaderCell>
                        <b>
                            {rateInfo.currencyCode} {rateInfo.commission2}
                        </b>
                    </TableHeaderCell>
                </TableRow>
            </TableFooter>
        </Table>
    );
};

RateTable.propTypes = {
    hoteInfo: PropTypes.object,
    rateInfos: PropTypes.array,
    rooms: PropTypes.array,
};

const formatCardNumber = (value) => {
    // Remove any non-digit characters
    const cleanedValue = value.replace(/\D/g, '');

    // Add a space after every 4 digits
    const formattedValue = cleanedValue.replace(/(\d{4})(?=\d)/g, '$1 ');

    return formattedValue.trim();
};

const ccNumberPrettyPrint = (value) => {
    const k = value.replaceAll(' ', '');
    const d = k.length / 4;
    const mod = k.length % 4;
    // console.log(k, mod);
    if (mod == 1 && d > 1) {
        let trimmedNumber = value.trim();
        //fix issue of deleting space
        if (trimmedNumber.slice(-2, -1) !== ' ') {
            value = trimmedNumber.slice(0, -1) + ' ' + trimmedNumber.slice(-1);
        }
    }
    return value;
};

//only alphabeta and space
function onlyAlphabetSpace(v) {
    return !/[^a-z ]/i.test(v);
}

function onlyNumberSpace(v) {
    return !/[^0-9 ]/i.test(v);
}

function timePrint(dataString) {
    const dateTime = parse(dataString, 'yyyy-MM-dd', new Date());
    if (isNaN(dateTime.getTime())) {
        return '';
    }
    return format(dateTime, 'EEE, MMM d, yyyy');
}
