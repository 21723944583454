import { useState, useCallback, useRef } from 'react';
import * as config from '@config';
import { GetToken } from '@utils/storage';

function useRideCancelOrder() {
    const [cancelling, setCancelling] = useState(false);
    const abortRef = useRef();

    const cancelRideOrder = useCallback(async (id) => {
        abort();
        setCancelling(true);
        abortRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL2}/ride/orders/${id}/cancel`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                },
                signal: abortRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while cancelling ride order');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error cancel ride order:', error);
            }
            throw error;
        } finally {
            setCancelling(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);

    return { cancelling, cancelRideOrder, abort };
}

export default useRideCancelOrder;
