import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';
import { useNavigate } from 'react-router';
import { Alert, Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

function LoginForm(props) {
    const { submit = () => {} } = props;
    const [loggingIn, setLoggingIn] = useState(false);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useEffect(() => {
        if (props.username) {
            console.log('pre set username:', props.username);
            form.setFieldsValue({ username: props.username });
            // setUsername(props.username);
        }
    }, [props]);

    const onFinish = (values) => {
        const req = {
            email: values.username?.trim(),
            passwordHash: md5(values.password?.trim()),
        };
        setLoggingIn(true);
        submit(req)
            .then((res) => {
                // console.log(res);
                if (res.error) {
                    setErrors({ error: res.payload });
                } else {
                    navigate('/dashboard');
                }
            })
            .finally(() => {
                setLoggingIn(false);
            });
    };

    const gotoRest = () => {
        navigate('/reset');
    };

    return (
        <>
            <div>
                {errors.error && (
                    <>
                        <Alert message={errors.error} type="error" closable onClose={() => setErrors({})} />
                        <br />
                    </>
                )}

                <Form form={form} autoComplete="off" autoCapitalize="off" autoCorrect="off" onFinish={onFinish}>
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined />} placeholder="Username" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password',
                            },
                        ]}
                    >
                        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                    </Form.Item>

                    <Form.Item>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button type="primary" htmlType="submit" loading={loggingIn}>
                                Login
                            </Button>
                            <Button type="link" onClick={gotoRest}>
                                Reset
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
}

LoginForm.propTypes = {
    submit: PropTypes.func.isRequired,
    username: PropTypes.string,
};

// here is why we can dispatch login directly in above code
export default LoginForm;
