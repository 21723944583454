import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Selector } from '../utils/constants';
import { useSearchParams } from 'react-router-dom';
import ActivitiesGrid from '../components/activity/ActivitiesGrid';

function PackagesPage() {
    const activities = useSelector((state) => state.activities.data);
    const fetching = useSelector((state) => state.activities.fetching);
    const [searchParams, setSearchParams] = useSearchParams();

    const [data, setData] = useState([]);

    useEffect(() => {
        filterActivities();
    }, [fetching]);

    const filterActivities = () => {
        // Accept an array of selectors instead of a single fixed one
        const selectedSelectors = [Selector.Package, Selector.Team]; // Example array of selectors
        const params = new URLSearchParams(searchParams);
        const sort = params.get('sort');
        let newData = [...activities];

        // Filter for multiple selectors
        newData = activities.filter((item) => {
            // Check if activity matches ANY of the selected selectors
            return selectedSelectors.some((selector) => item.selectors?.includes(selector));
        });

        // Modified selector sorting to handle multiple selectors
        newData?.sort((a, b) => {
            // Get the lowest sort value among all matching selectors for each item
            const getMinSortValue = (activity) => {
                const matchingItems = activity.selectorItems
                    .filter((item) => selectedSelectors.includes(item.name))
                    .map((item) => item.sort);
                return Math.min(...matchingItems);
            };

            const sortA = getMinSortValue(a);
            const sortB = getMinSortValue(b);
            return sortA - sortB;
        });

        // Price sorting remains the same
        if (sort) {
            const calculatePrice = (pricing) => {
                if (pricing.bundlePriced) {
                    return pricing.bundlePrice;
                }
                return pricing.adultPrice;
            };

            switch (sort) {
                case 'price-asc':
                    newData?.sort((a, b) => {
                        return calculatePrice(a.pricing) - calculatePrice(b.pricing);
                    });
                    break;
                case 'price-desc':
                    newData?.sort((a, b) => {
                        return calculatePrice(b.pricing) - calculatePrice(a.pricing);
                    });
                    break;
                default:
                    break;
            }
        }
        setData(newData);
    };

    return (
        <div>
            <h3>套餐(一日游/当地参团/当地包团)</h3>
            {fetching && <div>加载中...</div>}
            <ActivitiesGrid activities={data} isFromPrice={true} />
        </div>
    );
}

export default PackagesPage;
