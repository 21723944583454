import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Result } from 'antd';
import TransfersHomePage from './TransfersHomePage';
import TransfersVehiclesPage from './TransfersVehiclesPage';
import TransfersBookingPage from './TransfersBookingPage';
import TransfersOrdersPage from './TransfersOrdersPage';
import TransfersOrderDetailPage from './TransfersOrderDetailPage';
import TransferEnquiryHomePage from './enquiry/TransferEnquiryHomePage';
import TransferEnquiriesPage from './enquiry/TransferEnquiriesPage';
import TransferEnquiryDetailPage from './enquiry/TransferEnquiryDetailPage';

function index() {
    return (
        <Routes>
            <Route path="/">
                <Route index exact element={<TransferEnquiryHomePage />} />
                {/* <Route index exact element={<TransfersHomePage />} /> */}
                <Route path="vehicles/*">
                    <Route index element={<TransfersVehiclesPage />} />
                    <Route path="booking" element={<TransfersBookingPage />} />
                </Route>
                <Route path="enquiries/*">
                    <Route index element={<TransferEnquiriesPage />} />
                    <Route path=":id" element={<TransferEnquiryDetailPage />} />
                </Route>

                <Route path="orders/*">
                    <Route index element={<TransfersOrdersPage />} />
                    <Route path=":id" element={<TransfersOrderDetailPage />} />
                </Route>
                <Route
                    path="*"
                    element={<Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />}
                />
            </Route>
        </Routes>
    );
}

export default index;
