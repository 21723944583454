import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import BackBar from '@components/BackBar';
import useOrderItem from '../../hooks/activity-order/useOrderItem';
import { Alert, App, Collapse, Spin, Button, Typography, Result } from 'antd';
import OrderItemDetail from '../../components/activity/OrderItemDetail';
import Contacts from '../../components/Contacts';
import { WarningOutlined } from '@ant-design/icons';
import useContacts from '../../hooks/useContacts';

const { Title } = Typography;

function OrderItemPage() {
    const { orderItemID } = useParams();
    const navigate = useNavigate();
    const {
        fetching,
        fetched,
        orderItem,
        fetchOrderItem,
        verifyData,
        verifying,
        verifyOrderItem,
        confirming,
        confirmOrderItem,
    } = useOrderItem();
    const { contacts } = useContacts({ relationID: orderItemID });
    const { message } = App.useApp();
    const [openSucess, setOpenSucess] = useState(false);

    if (!orderItemID) {
        return <Alert message="No data available" type="warning" showIcon />;
    }

    useEffect(() => {
        if (orderItemID) {
            verifyOrderItem(orderItemID);
            fetchOrderItem(orderItemID)
                .then((data) => {
                    // console.log(data);
                })
                .catch((error) => {
                    console.error('Error fetching order item:', error);
                    message?.error(error.message);
                    navigate(-1);
                });
        }
        return () => {};
    }, [orderItemID]);

    const handleSubmit = () => {
        // console.log(emailData);
        if (confirm('确认预订') != true) {
            return;
        }
        confirmOrderItem(orderItemID)
            .then((data) => {
                const { reply } = data;
                if (reply) {
                    message?.success(reply);
                }
                fetchOrderItem(orderItemID);
                setOpenSucess(true);
            })
            .catch((error) => {
                console.error('Error confirming order item:', error);
                message?.error(error.message);
            });
    };

    if (openSucess) {
        return (
            <Result
                status="success"
                title={`Order ${orderItem.ref} confirmed`}
                extra={
                    <Button type="primary" onClick={() => navigate(-1)}>
                        返回
                    </Button>
                }
            />
        );
    }

    return (
        <div>
            <BackBar />
            <br />
            {orderItem.confirmed ? (
                <OrderItemDetail orderItemData={orderItem} contacts={contacts} />
            ) : (
                <>
                    <OrderItemDetail orderItemData={orderItem} />
                    {verifyData.confirmWarning && (
                        <div style={{ whiteSpace: 'pre-wrap', marginTop: 20 }}>
                            <WarningOutlined /> {verifyData.confirmWarning}
                        </div>
                    )}
                    <br />
                    <Contacts relationID={orderItem.id} callback={() => verifyOrderItem(orderItemID)} />
                    <Button
                        type="primary"
                        onClick={handleSubmit}
                        loading={confirming}
                        disabled={confirming || verifying || verifyData.readyToConfirm === false}
                        style={{ marginTop: 20 }}
                    >
                        提交
                    </Button>
                </>
            )}

            <Spin spinning={fetching || confirming || verifying} size="large" fullscreen />
        </div>
    );
}

export default OrderItemPage;
