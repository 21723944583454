import { useCallback, useRef, useState } from 'react';
import * as config from '@config';
import { GetToken } from '@utils/storage';

function useRideCreateOrder() {
    const [submitting, setSubmitting] = useState(false);
    const abortRef = useRef();

    const createRideOrder = useCallback(async (req) => {
        abort();
        setSubmitting(true);
        abortRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL2}/ride/create-order`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${GetToken()}`,
                },
                body: JSON.stringify(req),
                signal: abortRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred while create ride order');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error create ride order:', error);
            }
            throw error;
        } finally {
            setSubmitting(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);

    return { submitting, createRideOrder, abort };
}

export default useRideCreateOrder;
