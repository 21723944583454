import React, { useEffect, useState } from 'react';
import useRideOrders from '@hooks/transfer/useRideOrders';
import TransferOrdersTable from '@components/transfer/TransferOrdersTable';
import { useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'qs';

const DEFAULT_QUERY = {
    page: 1,
    pageSize: 10,
};

function TransfersOrdersPage() {
    const { fetching, orders, total, fetchOrders, abort } = useRideOrders();
    const [query, setQuery] = useState(DEFAULT_QUERY);
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            abort();
        };
    }, []);

    useEffect(() => {
        const query = qs.parse(searchParams.toString());
        setQuery(query);
        fetchOrders(query);
    }, [searchParams]);

    const handleTableChange = (pagination, filters, sorter) => {
        setSearchParams({ ...query, page: pagination.current, pageSize: pagination.pageSize });
    };

    const onSelect = (record) => {
        // console.log('Selected order:', record);
        navigate(`${record.id}`);
    };

    return (
        <div>
            <h3>用车订单</h3>
            <TransferOrdersTable
                orders={orders}
                total={total}
                query={query}
                loading={fetching}
                handleTableChange={handleTableChange}
                onSelect={onSelect}
            />
        </div>
    );
}

export default TransfersOrdersPage;
