import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import styles from '@styles/TransfersBookingPage.module.css';
import { MdGroup, MdOutlineLuggage } from 'react-icons/md';
import BackBar from '@components/BackBar';
import useRideCreateOrder from '@hooks/transfer/useRideCreateOrder';
import { Button, Form, Input, Radio, Select, Result, Spin } from 'antd';
import TransferPolicy from '../../components/transfer/TransferPolicy';
import { ScrollToTop } from '@utils/scrollPosition';

const initialValues = { meetAndGreet: true, childSeat: false };

function TransfersBookingPage() {
    const location = useLocation();
    const { request, quote, vehicle } = location.state || {};
    // console.log('TransfersBookingPage:', request, quote, vehicle);

    const [form] = Form.useForm();
    const [total, setTotal] = useState();
    const { submitting, createRideOrder, abort } = useRideCreateOrder();
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        // default meet and greet service
        ScrollToTop();
        const { meetAndGreet } = form.getFieldsValue();
        if (meetAndGreet) {
            setTotal(vehicle.price.salePrice + quote.meetAndGreet.price.salePrice);
        } else {
            setTotal(vehicle.price.salePrice);
        }
    }, [form]);

    const onFieldsChange = (changedFields, allFields) => {
        const { meetAndGreet } = form.getFieldsValue();
        if (meetAndGreet) {
            setTotal(vehicle.price.salePrice + quote.meetAndGreet.price.salePrice);
        } else {
            setTotal(vehicle.price.salePrice);
        }
    };

    const onFinish = (values) => {
        if (confirm('确定要预订吗？') !== true) return;
        const { passenger, ...rest } = values;
        passenger.passengerCount = request.passengerCount || vehicle.passengerCapacity;
        passenger.luggageCount = request.luggageCount || vehicle.luggageCapacity;
        if (passenger.welcomeSign && rest.meetAndGreet) {
            passenger.welcomeSign = `欢迎 ${passenger.welcomeSign}`;
        }
        const req = {
            ...request,
            passenger: passenger,
            ...rest,
            salePrice: total,
            netPrice: vehicle.price.netPrice,
            currency: vehicle.price.currency,
            meetAndGreetFee: quote.meetAndGreet.price.netPrice,
            vehicleClass: vehicle.vehicleClass,
        };
        // console.log('req:', req);

        createRideOrder(req)
            .then((res) => {
                form.resetFields();
                setSuccess(true);
            })
            .catch((error) => {
                console.error('Error:', error);
                alert(error);
            });
    };

    if (success) {
        return (
            <Result
                status="success"
                title="预订成功"
                subTitle={<div>请留意您的邮箱(包括垃圾邮件文件夹)或在”用车订单“页面查看进展。</div>}
                extra={[
                    <Button
                        type="primary"
                        key="console"
                        onClick={() => navigate('/dashboard/transfers/orders', { replace: true })}
                    >
                        查看
                    </Button>,
                    <Button key="back" onClick={() => navigate('/dashboard/transfers')}>
                        返回
                    </Button>,
                ]}
            />
        );
    }

    return (
        <>
            <BackBar />
            <Spin spinning={submitting} fullscreen />
            <div style={{ marginTop: 16 }}></div>
            <div className={styles.container}>
                <div className={styles.form_container}>
                    <Form
                        layout="vertical"
                        form={form}
                        onFinish={onFinish}
                        initialValues={initialValues}
                        onFieldsChange={onFieldsChange}
                        autoComplete="off"
                    >
                        <div className={styles.title_container}>
                            <div className={styles.title}>联系方式</div>
                        </div>
                        <div className={styles.group_container}>
                            <Form.Item
                                label="姓名"
                                name={['passenger', 'firstName']}
                                rules={[{ required: true, message: '' }]}
                                required
                                style={{ width: '100%' }}
                            >
                                <Input type="text" />
                            </Form.Item>
                            <Form.Item label="称呼" name={['passenger', 'salutation']} style={{ width: '100%' }}>
                                <Select
                                    suffixIcon={null}
                                    allowClear
                                    options={[
                                        {
                                            label: '先生',
                                            value: 'Mr',
                                        },
                                        {
                                            label: '女士',
                                            value: 'Mrs',
                                        },
                                        {
                                            label: '小姐',
                                            value: 'Ms',
                                        },
                                        {
                                            label: '其他',
                                            value: 'Other',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </div>
                        <div className={styles.group_container}>
                            <Form.Item
                                label="常用邮箱"
                                name={['passenger', 'email']}
                                rules={[{ required: true, message: '' }]}
                                required
                                style={{ width: '100%' }}
                            >
                                <Input type="text" />
                            </Form.Item>
                            <Form.Item
                                label="联系电话"
                                name={['passenger', 'cellPhone']}
                                rules={[{ required: true, message: '' }]}
                                required
                                style={{ width: '100%' }}
                            >
                                <Input type="text" />
                            </Form.Item>
                        </div>
                        <div className={styles.title_container}>
                            <div className={styles.title}>其他信息</div>
                        </div>
                        <div className={styles.group_container}>
                            {quote?.arrFlightNum && (
                                <Form.Item name={'arrFlightNumber'} style={{ width: '100%' }}>
                                    <Input
                                        type="text"
                                        placeholder="到达航班号"
                                        autoComplete="off"
                                        onChange={(e) => {
                                            // Get the form instance and set the uppercase value
                                            form.setFieldValue('arrFlightNumber', e.target.value.toUpperCase());
                                        }}
                                    />
                                </Form.Item>
                            )}
                            {quote?.depFlightNum && (
                                <Form.Item name={'depFlightNumber'} style={{ width: '100%' }}>
                                    <Input
                                        type="text"
                                        placeholder="出发航班号"
                                        autoComplete="off"
                                        onChange={(e) => {
                                            // Get the form instance and set the uppercase value
                                            form.setFieldValue('depFlightNumber', e.target.value.toUpperCase());
                                        }}
                                    />
                                </Form.Item>
                            )}
                        </div>
                        <Form.Item
                            name="meetAndGreet"
                            label={
                                <p style={{ fontSize: '16px', fontWeight: '500' }}>
                                    您想花费 {quote.meetAndGreet.price.currency} {quote.meetAndGreet.price.salePrice}{' '}
                                    添加举牌接机服务吗？
                                </p>
                            }
                            // style={{ marginBottom: 0 }}
                        >
                            <Radio.Group>
                                <Radio value={true}>是</Radio>
                                <Radio value={false}>否</Radio>
                            </Radio.Group>
                        </Form.Item>

                        {form.getFieldValue('meetAndGreet') && (
                            <Form.Item name={['passenger', 'welcomeSign']} label="举牌内容">
                                <Input type="text" placeholder="举牌姓名" prefix="欢迎" />
                            </Form.Item>
                        )}

                        <Form.Item
                            name="childSeat"
                            label={<p style={{ fontSize: '16px', fontWeight: '500' }}>是否需要儿童座椅？</p>}
                        >
                            <Radio.Group>
                                <Radio value={true}>是</Radio>
                                <Radio value={false}>否</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="备注" name={['passenger', 'instruction']}>
                            <Input.TextArea rows={4} placeholder="其他备注" />
                        </Form.Item>
                        <div></div>
                        <Button type="primary" htmlType="submit" loading={submitting}>
                            预订
                        </Button>
                    </Form>
                </div>

                <div className={styles.summary_container}>
                    <div className={styles.title_container}>
                        <div className={styles.title}>订单详情</div>
                        <div className={styles.total_container}>
                            <span className={styles.currency}>{vehicle.price.currency}</span>
                            <span className={styles.value}>{total}</span>
                        </div>
                    </div>
                    <div className={styles.request_container}>
                        <div>起点：{request.pickup}</div>
                        <div>终点：{request.dropoff}</div>
                        <div>用车日期：{request.date}</div>
                        <div>用车时间：{request.time}</div>
                        {request.hours && <div>包车时长：{request.hours}h</div>}

                        {!request.hours && request.retDate && <div>返程日期：{request.retDate}</div>}
                        {!request.hours && request.retTime && <div>返程时间：{request.retTime}</div>}
                    </div>

                    <div className={styles.title_container}>
                        <div className={styles.title}>车型</div>
                    </div>
                    <div className={styles.vehicle_class_container}>
                        <div className={styles.image_container}>
                            <div className={styles.img_wrap}>
                                <img src={vehicle.imageURL} alt={vehicle.vehicleClass} />
                            </div>
                        </div>
                        <div className={styles.car_info_container}>
                            <div className={styles.car_class}>{vehicle.vehicleClass}</div>
                            <div className={styles.car_model}>
                                {vehicle.typicalVehicle.model} {vehicle.typicalVehicle.manufacturer} or same class/grade
                            </div>
                            <div className={styles.group_container}>
                                <div className={styles.passenger}>
                                    <MdGroup />
                                    <span>最多{vehicle.passengerCapacity}人</span>
                                </div>
                                <div className={styles.luggage}>
                                    <MdOutlineLuggage />
                                    <span>最多{vehicle.luggageCapacity}件</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.title_container}>
                        <div className={styles.title}>条款政策</div>
                    </div>
                    <div>
                        <TransferPolicy />
                    </div>
                </div>
            </div>
        </>
    );
}

export default TransfersBookingPage;
