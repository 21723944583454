import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/Nav.module.css';
import Burger from './Burger';
import Logo from './Logo';
import Drawer from '@components/Drawer';
import SpinnerInline from '@components/SpinnerInline';
import Console from '../Console';
import { MdAccountCircle, MdClose } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { AiOutlineShopping } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router';
import {
    AccommodationEnabled,
    ActivityEnabled,
    FlightEnabled,
    GetCurrency,
    GetUserInfo,
    SaveCurrency,
} from '../utils/storage';
import { DevMode } from '../utils/utils';
import { useCart } from '@contexts/CartContext';

function Nav({ title = '', children }) {
    return (
        <div className={styles.container}>
            <nav className={styles.container__nav}>
                <div className={styles.leading_container}>
                    <div className={styles.logo_container}>
                        <Link to={`/dashboard`}>
                            <Logo />
                        </Link>
                    </div>
                    <div className={styles.title_container}>{title}</div>
                </div>

                <div className={styles.trailing_container}>{children}</div>
            </nav>
        </div>
    );
}

Nav.Account = () => {
    const loginInfo = GetUserInfo();
    return (
        <ul className={styles.username_container}>
            <Link to="/dashboard/account">
                <li aria-selected={location.pathname.startsWith('/dashboard/account') && 'true'}>
                    <MdAccountCircle className={styles.icon} />
                    {loginInfo.contactName}
                </li>
            </Link>
        </ul>
    );
};

Nav.ActivityCart = () => {
    const { data, fetching } = useCart();

    const navigate = useNavigate();

    const gotoCart = () => {
        navigate('/dashboard/cart');
    };

    return (
        <>
            {ActivityEnabled() && (
                <div
                    className={styles.cart_container}
                    onClick={gotoCart}
                    aria-selected={location.pathname.startsWith('/dashboard/cart') && 'true'}
                >
                    {!fetching ? (
                        <>
                            <AiOutlineShopping className={styles.icon} />
                            {data?.qty > 0 && <div className={styles.badge}>{data.qty}</div>}
                        </>
                    ) : (
                        <SpinnerInline active={fetching} />
                    )}
                </div>
            )}
        </>
    );
};

Nav.CloseIcon = ({ onClose }) => {
    const navigate = useNavigate();
    const handleOnClick = () => {
        if (confirm('Do you want to leave?') != true) return;
        if (!!onClose) {
            onClose();
        } else {
            if (window.history.length == 1) {
                window.close();
            } else {
                navigate(-1);
            }
        }
    };
    return (
        <div className={styles.close_icon_container} onClick={handleOnClick}>
            <MdClose />
        </div>
    );
};

Nav.CloseIcon.propTypes = {
    onClose: PropTypes.func,
};

Nav.Burger = () => {
    const [active, setActive] = useState(false);
    return (
        <>
            <div className={styles.burger_container} onClick={() => setActive((prev) => !prev)}>
                <Burger active={active} />
            </div>
            <Drawer active={active} setPropsActive={setActive}>
                <Console />
            </Drawer>
        </>
    );
};

Nav.propTypes = {
    title: PropTypes.string,
};

export default Nav;
