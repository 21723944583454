import React, { useState, useEffect, useCallback } from 'react';
import { Table, Typography, Space, Button } from 'antd';
import { parse, format, isValid } from 'date-fns';
import useFlightOrders from '../../../hooks/flight/useFlightOrders';
import Refresh from './Refresh';
import { zhCN } from 'date-fns/locale';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';

const { Text } = Typography;

const FlightOrdersTable = () => {
    const [expandedRows, setExpandedRows] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { orders, total, fetching, refetch } = useFlightOrders();
    const navigate = useNavigate();

    const fetchData = useCallback(() => {
        refetch({ page: currentPage, pageSize: pageSize });
    }, [refetch, currentPage, pageSize]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const FlightInfo = ({ label, content }) => (
        <Space direction="horizontal" size={0} style={{ width: '100%' }}>
            {label && <Text type="secondary">{label}</Text>}
            <Text>{content}</Text>
        </Space>
    );

    const timeParse = (dateString) => {
        if (!dateString) return '-';
        const parsedDate = parse(dateString, 'yyyyMMddHHmm', new Date());
        if (!isValid(parsedDate)) return '-';
        return format(parsedDate, 'yyyy/MM/dd HH:mm', { locale: zhCN });
    };

    const columns = [
        {
            title: '单号',
            dataIndex: 'ref',
            key: 'ref',
        },
        {
            title: '创建日期',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => {
                return text ? format(new Date(text), 'yyyy-MM-dd', { locale: zhCN }) : '-';
            },
        },
        {
            title: '航司',
            dataIndex: 'carrier',
            key: 'carrier',
        },
        {
            title: '出发',
            key: 'depAirport',
            render: (_, record) => {
                const isRoundTrip = record.retDepAirport ? true : false;
                return (
                    <Space direction="vertical" size={16} style={{ width: '100%' }}>
                        <FlightInfo label={isRoundTrip ? '去程' : null} content={record.fromDepAirport} />
                        {record.retDepAirport && (
                            <FlightInfo label={isRoundTrip ? '返程' : null} content={record.retDepAirport} />
                        )}
                    </Space>
                );
            },
        },

        {
            title: '出发日期',
            key: 'depTime',
            render: (_, record) => {
                return (
                    <Space direction="vertical" size={16} style={{ width: '100%' }}>
                        <FlightInfo content={timeParse(record.fromDepTime)} />
                        {record.retDepTime && <FlightInfo content={timeParse(record.retDepTime)} />}
                    </Space>
                );
            },
        },
        {
            title: '到达',
            key: 'arrAirport',
            render: (_, record) => {
                return (
                    <Space direction="vertical" size={16} style={{ width: '100%' }}>
                        <FlightInfo content={record.fromArrAirport} />
                        {record.retArrAirport && <FlightInfo content={record.retArrAirport} />}
                    </Space>
                );
            },
        },
        {
            title: '到达日期',
            key: 'arrTime',
            render: (_, record) => {
                return (
                    <Space direction="vertical" size={16} style={{ width: '100%' }}>
                        <FlightInfo content={timeParse(record.fromArrTime)} />
                        {record.retArrTime && <FlightInfo content={timeParse(record.retArrTime)} />}
                    </Space>
                );
            },
        },
        // {
        //     title: '成人',
        //     dataIndex: 'numAdult',
        //     key: 'numAdult',
        // },
        // {
        //     title: '儿童',
        //     dataIndex: 'numChild',
        //     key: 'numAnumChilddult',
        // },
        {
            title: '总价',
            dataIndex: 'totalPrice',
            key: 'totalPrice',
            render: (price, record) => (price ? `${record.currency || 'CNY'} ${price} ` : '-'),
        },
        {
            title: '状态',
            dataIndex: 'statusTxt',
            key: 'statusTxt',
        },
        {
            title: '系统',
            dataIndex: 'channelSourceName',
            key: 'channelSourceName',
        },

        {
            title: '操作',
            key: 'action',
            render: (_, record) => {
                const key = record.ref;
                const isExpanded = expandedRows[key];
                return (
                    <Space size="small">
                        <Refresh refCode={record.ref} />
                        <Button type="link" size="small" style={{ padding: 0 }} onClick={() => navigate(record.ref)}>
                            查看
                        </Button>
                        {/* <Button
                            type="link"
                            size="small"
                            style={{ padding: 0 }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setExpandedRows((prev) => ({
                                    ...prev,
                                    [key]: !prev[key],
                                }));
                            }}
                            icon={
                                isExpanded ? (
                                    <UpOutlined style={{ fontSize: 10 }} />
                                ) : (
                                    <DownOutlined style={{ fontSize: 10 }} />
                                )
                            }
                            iconPosition="end"
                        >
                            {isExpanded ? '收起' : '展开'}
                        </Button> */}
                    </Space>
                );
            },
        },
    ];

    const handleTableChange = (pagination) => {
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    const expandedRowRender = (record) => {
        return (
            <p>
                <Text strong>Passengers:</Text> {record.passengers.map((p) => p).join(', ')}
                <br />
                <Text strong>OID:</Text> {record.oid}
            </p>
        );
    };

    return (
        <Table
            bordered
            columns={columns}
            dataSource={orders}
            loading={fetching}
            pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: total,
                showSizeChanger: true,
                showQuickJumper: true,
                showTotal: (total) => `共 ${total} 条`,
                pageSizeOptions: ['10', '20', '30'],
            }}
            onChange={handleTableChange}
            rowKey="orderNo"
            // expandable={{
            //     expandedRowRender: expandedRowRender,
            //     expandedRowKeys: Object.keys(expandedRows).filter((key) => expandedRows[key]),
            //     onExpand: (expanded, record) => {
            //         const key = record.orderNo;
            //         setExpandedRows((prev) => ({
            //             ...prev,
            //             [key]: expanded,
            //         }));
            //     },
            // }}
        />
    );
};

export default FlightOrdersTable;
