import { useCallback, useState, useRef } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useCity() {
    const [fetching, setFetching] = useState(false);
    const [results, setResults] = useState([]);
    const abortControllerRef = useRef();

    const searchCity = useCallback(async ({ value, limit = 5, cityOnly = true }) => {
        abort();
        try {
            setFetching(true);
            abortControllerRef.current = new AbortController();
            const response = await fetch(`${config.apiURL}/city?value=${value}&limit=${limit}&cityOnly=${cityOnly}`, {
                method: 'GET',
                headers: { Authorization: `Bearer ${GetToken()}` },
                signal: abortControllerRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to search city');
            }
            const data = await response.json();
            const results = data.results;
            setResults(results);
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error searching city:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { fetching, results, searchCity, abort };
}

export default useCity;
