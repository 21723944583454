import dayjs from 'dayjs';

export const minutesToText = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    if (hours === 0) {
        return `${remainingMinutes} mins`;
    }

    if (remainingMinutes === 0) {
        return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    }

    return `${hours} ${hours === 1 ? 'hour' : 'hours'} ${remainingMinutes} mins`;
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-AU', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });
};

export const formatYYYYMMDD = (dateString) => {
    return dayjs(dateString).format('YYYY-MM-DD');
};

export const formatHHMM = (dateString) => {
    return dayjs(dateString).format('HH:mm');
};
