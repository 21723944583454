import React, { useState } from 'react';
import GroupEnquiryCreateForm from '@components/air/GroupEnquiryCreateForm';
import { Form, App, Result, Button, Spin } from 'antd';
import useGroupEnquiryCreate from '@hooks/flight/useGroupEnquiryCreate';
import { useNavigate } from 'react-router-dom';
import styles from '@styles/GroupTravelPage.module.css';
import TopHeaderBg from '@assets/flight_top_bg.png';

function GroupTravelPage() {
    const [form] = Form.useForm();
    const { message } = App.useApp();
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();

    const { submitting, handleEnquiryCreate, precent } = useGroupEnquiryCreate();

    const onFinish = async (values) => {
        console.log(values);
        if (confirm('确定提交吗？') !== true) return;
        const UPLOAD_FIELD_NAME = 'files';
        const formData = new FormData();
        const fileList = form.getFieldValue(UPLOAD_FIELD_NAME);
        if (!!fileList && fileList.length != 0) {
            fileList.forEach((file) => {
                formData.append('files[]', file.originFileObj);
            });
        }
        // Remove file field from values
        delete values[UPLOAD_FIELD_NAME];
        // console.log(JSON.stringify(values));
        formData.append('data', JSON.stringify(values));
        try {
            const resp = await handleEnquiryCreate(formData);
            if (resp.reply) {
                message?.success(resp.reply);
            } else {
                message?.success('Form submitted and file uploaded successfully');
            }
            // Reset form after successful submission
            form.resetFields();
            setSuccess(true);
        } catch (error) {
            message?.error(error.message);
            console.error('Error:', error);
        }
        return;
    };

    const Success = () => {
        return (
            <Result
                status="success"
                title="提交成功"
                subTitle={<div>请留意您的邮箱(包括垃圾邮件文件夹)或在”团队订单“页面查看进展。</div>}
                extra={[
                    <Button type="primary" key="console" onClick={() => navigate('enquiries')}>
                        查看
                    </Button>,
                    <Button key="back" onClick={() => setSuccess(false)}>
                        返回
                    </Button>,
                ]}
            />
        );
    };

    if (success) {
        return <Success />;
    }

    return (
        <div className={styles.container}>
            <div className={styles.top_bg_container}>
                <img src={TopHeaderBg} />
                <div className={styles.words_container}>
                    <div className={styles.paragraph}>随想旅途</div>
                    <div className={styles.paragraph}>
                        全球航线 <span className={styles.colorful}>1000+</span> 条自在飞
                    </div>
                </div>
            </div>
            <Spin spinning={submitting} percent={precent} fullscreen size="large" />
            <div className={styles.content_container}>
                <GroupEnquiryCreateForm form={form} onFinish={onFinish} />
            </div>
        </div>
    );
}

export default GroupTravelPage;
