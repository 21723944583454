import React, { useEffect, useId, useState } from 'react';
import styles from '@styles/AirBookingPage.module.css';
import { TableRow, TableHeaderCell, TableHeader, TableCell, TableBody, Table, Dimmer, Loader } from 'semantic-ui-react';
import PassengersForm, { AgeType } from '../../components/air/PassengersForm';
import BackBar from '@components/BackBar';
import { useLocation, useNavigate } from 'react-router';
import { GetUserInfo } from '@utils/storage';
import PropTypes from 'prop-types';
import useFlightCreateOrder from '../../hooks/flight/useFlightCreateOrder';
import { App, Result, Button, Alert, Space, Typography, Modal } from 'antd';
import { ScrollToTop } from '@utils/scrollPosition';
import FlightSegments from '../../components/air/FlightSegments';
import { prettifyCurrency } from '@utils/utils';

const { Title, Text } = Typography;

function AirBookingPage(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const loginUser = GetUserInfo();
    const { data, request } = location.state || {};
    const { routing, sessionId, maxSeats = 1, ticketInfo, channel } = data || {};
    // console.log('data:', data, 'request:', request);

    if (!routing || routing.data == '') {
        return (
            <div className={styles.container}>
                <BackBar />
                <br />
                <div> No routing!!!</div>
            </div>
        );
    }
    const [passengers, setPassengers] = useState([]);
    const formID = useId();
    const { submitting, handleCreateOrder, abort } = useFlightCreateOrder();
    const { message } = App.useApp();
    const [success, SetSuccess] = useState(false);

    useEffect(() => {
        ScrollToTop();
        return () => {
            abort();
        };
    }, []);

    useEffect(() => {
        if (request) {
            const { numAdult, numChild } = request;
            const passengers = [];
            for (let i = 0; i < numAdult; i++) {
                passengers.push({ ageType: AgeType.Adult });
            }
            for (let i = 0; i < numChild; i++) {
                passengers.push({ ageType: AgeType.Child });
            }
            setPassengers(passengers);
        }
    }, [request]);

    const total = () => {
        let total = 0;
        for (let p of passengers) {
            if (p.ageType == AgeType.Child) {
                total += Number(routing.childTotal);
            } else {
                total += Number(routing.adultTotal);
            }
        }
        return prettifyCurrency(total);
    };

    const handleSubmit = () => {
        // Validate form
        const passengerForm = document.getElementById(formID);
        if (passengerForm.reportValidity() != true) return;
        // Confirm
        if (confirm('确定提交') != true) return;
        const req = {
            channel: channel,
            channelSource: routing.channelSource,
            sessionId: sessionId,
            passengers: passengers,
            routing: routing,
            contact: {
                name: loginUser.contactName,
                email: loginUser.email,
                mobile: loginUser.contactNumber,
            },
            tripType: request.tripType,
        };
        console.log(req);
        handleCreateOrder(req)
            .then((data) => {
                const reply = data.reply;
                message?.success(reply);
                SetSuccess(true);
            })
            .catch((err) => {
                console.error(err);
                message?.error(err.message);
            });
    };

    const gotoOrders = () => {
        navigate({ pathname: '/dashboard/air-tickets/fit/orders' }, { replace: true });
    };

    const goBack = () => {
        navigate({ pathname: '/dashboard/air-tickets/fit' }, { replace: true });
    };

    const Success = () => {
        return (
            <Result
                status="success"
                title={`Successfully!`}
                subTitle={'出票时间为当天澳大利亚时间晚上11点。'}
                extra={[
                    <Button type="primary" key="orders" size="small" onClick={gotoOrders}>
                        查看订单
                    </Button>,
                    <Button key="back" size="small" onClick={goBack}>
                        返回
                    </Button>,
                ]}
            />
        );
    };

    const Refund = ({ rules = {} }) => {
        const [open, setOpen] = useState(false);
        const RuleSection = ({ title, content }) => (
            <Space direction="vertical" size="small">
                <Title level={5}>{title}</Title>
                <Text style={{ whiteSpace: 'pre-line' }}>{content}</Text>
            </Space>
        );
        return (
            <>
                <a onClick={() => setOpen(true)}>退改规则</a>
                <Modal title="退改规则" open={open} onCancel={() => setOpen(false)} footer={null} width={600} centered>
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        <RuleSection title="改签" content={rules.change} />
                        <RuleSection title="退票" content={rules.refund} />
                        <RuleSection title="出票" content={rules.tktgInfo} />
                        <RuleSection title="其他" content={rules.other} />
                    </Space>
                </Modal>
            </>
        );
    };

    const Baggage = ({ rules = {} }) => {
        const [open, setOpen] = useState(false);
        return (
            <>
                <a onClick={() => setOpen(true)}>行李配额</a>
                <Modal title="行李配额" open={open} onCancel={() => setOpen(false)} footer={null} width={600} centered>
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                        <Text style={{ whiteSpace: 'pre-line' }}>{rules.baggage}</Text>
                    </Space>
                </Modal>
            </>
        );
    };

    if (success) {
        return <Success />;
    }

    // return <Success />;

    return (
        <div className={styles.container}>
            <BackBar />
            <div className={styles.segments_title_container}>
                <div className={styles.title}>航班信息</div>
                <div className={styles.rules_container}>
                    <Refund rules={routing.rule} />
                    <span className={styles.divider}></span>
                    <Baggage rules={routing.rule} />
                </div>
            </div>
            <FlightSegments fromSegments={routing.fromSegments} retSegments={routing.retSegments} />

            <h3>乘客信息 {maxSeats < 9 && <>(剩余 {maxSeats} 个位置)</>} </h3>
            <div className={styles.passengers_container}>
                <PassengersForm
                    passengers={passengers}
                    setPassengers={setPassengers}
                    formID={formID}
                    maxSeats={maxSeats}
                    allowAges={[request?.numAdult > 0 && AgeType.Adult, request?.numChild > 0 && AgeType.Child]}
                />
            </div>

            <h3>价格</h3>
            <Table celled structured>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell>乘客类型</TableHeaderCell>
                        <TableHeaderCell>票面</TableHeaderCell>
                        <TableHeaderCell>税金</TableHeaderCell>
                        <TableHeaderCell>单张结算价格</TableHeaderCell>
                        <TableHeaderCell>票数</TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    <TableRow>
                        <TableCell>成人</TableCell>
                        <TableCell>
                            {routing.currency ?? 'CNY'} {prettifyCurrency(routing.adultPrice)}
                        </TableCell>
                        <TableCell>
                            {routing.currency ?? 'CNY'} {prettifyCurrency(routing.adultTax)}
                        </TableCell>
                        <TableCell>
                            {routing.currency ?? 'CNY'} {prettifyCurrency(routing.adultTotal)}
                        </TableCell>
                        <TableCell>{passengers?.filter((p) => p.ageType == AgeType.Adult).length}</TableCell>
                    </TableRow>
                    {request?.numChild > 0 && (
                        <TableRow>
                            <TableCell>儿童</TableCell>
                            <TableCell>
                                {routing.currency ?? 'CNY'} {prettifyCurrency(routing.childPrice)}
                            </TableCell>
                            <TableCell>
                                {routing.currency ?? 'CNY'} {prettifyCurrency(routing.childTax)}
                            </TableCell>
                            <TableCell>
                                {routing.currency ?? 'CNY'} {prettifyCurrency(routing.childTotal)}
                            </TableCell>
                            <TableCell>{passengers?.filter((p) => p.ageType == AgeType.Child).length}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            <div className={styles.bottom_container}>
                <div className={styles.trailing_container}>
                    <span className={styles.total_container}>
                        <div className={styles.label}>总价:</div>
                        <span className={styles.currency}>{routing.currency ?? 'CNY'}</span>
                        <span className={styles.total}>{total()}</span>
                    </span>
                    <Button type="primary" onClick={handleSubmit} form={formID}>
                        预订
                    </Button>
                </div>
            </div>
            <Dimmer active={submitting} page inverted>
                <Loader>处理中...</Loader>
            </Dimmer>
        </div>
    );
}

export default AirBookingPage;
