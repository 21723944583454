import React from 'react';
import PropTypes from 'prop-types';
import Nav from './components/Nav';
import MessageBoard from './components/MessageBoard';
import styles from '@styles/Layout.module.css';
import Console from './Console';
import { Outlet } from 'react-router';
import { useWs } from './contexts/WsContext';
import { FloatButton } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import Feedback from './components/Feedback';

function Layout({ children }) {
    const { isConnected } = useWs();
    return (
        <div className={styles.container}>
            <div className={styles.leading_container}>
                <Nav>
                    <Nav.Account />
                    <Nav.ActivityCart />
                    <Nav.Burger />
                </Nav>
            </div>

            <div className={styles.trailing_container}>
                <aside className={styles.sidebar}>
                    <Console />
                    <div style={{ position: 'absolute', left: 0, bottom: 0 }}>{isConnected ? '🟢' : '🔴'}</div>
                </aside>

                <main className={styles.content}>
                    <MessageBoard />
                    {children}
                    <FloatButton.Group
                        trigger="click"
                        type="primary"
                        style={{
                            insetInlineEnd: 24,
                        }}
                        icon={<MailOutlined />}
                    >
                        <div
                            style={{
                                right: 0,
                                bottom: 0,
                                width: 300,
                                maxHeight: '90vw',
                                position: 'absolute',
                            }}
                        >
                            <Feedback />
                        </div>
                    </FloatButton.Group>
                    <Outlet />
                </main>
            </div>
        </div>
    );
}

Layout.propTypes = {};

export default Layout;
