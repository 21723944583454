import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Space, Form, Select, DatePicker, Button, Flex, InputNumber, Input, Upload, Popconfirm, App } from 'antd';
import { PlusOutlined, CloseOutlined, InboxOutlined, RedoOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import styles from './styles.module.css';
import { CabinClass, australianAirports, airlines } from './constants';
import GroupFeatures from '../GroupFeatures';

const MAX_PASSENGER = 99;

const labelCol = {
    span: 12,
    offset: 0,
};

const Tab = {
    Domestic: 'Domestic',
    International: 'International',
};

function GroupEnquiryCreateForm({ form, onFinish, maxFileSizeInMB = 10 }) {
    const [tabName, setTabName] = useState(Tab.International);
    const [totalSize, setTotalSize] = useState(0); // total size of all files in bytes
    const submitBtnRef = useRef(null);
    const { message } = App.useApp();

    // Set max total file size to 10MB (10 * 1024 * 1024 bytes)
    const MAX_TOTAL_SIZE = maxFileSizeInMB * 1024 * 1024;

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const beforeUpload = (file, fileList) => {
        // loop fileList to get total size
        let newTotalSize = totalSize;
        for (let i = 0; i < fileList.length; i++) {
            newTotalSize += fileList[i].size;
            if (newTotalSize > MAX_TOTAL_SIZE) {
                message?.error(`上传文件大小不能超过 ${MAX_TOTAL_SIZE / 1024 / 1024}MB`);
                return Upload.LIST_IGNORE;
            }
        }
        setTotalSize(newTotalSize);
        return false; // Prevent automatic upload
    };

    const onRemove = (file) => {
        setTotalSize((prevSize) => prevSize - file.size);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        if (errorInfo.errorFields.length > 0) {
            const firstErrorElement = errorInfo.errorFields[0];
            if (firstErrorElement.name.length > 0) {
                const name = firstErrorElement.name[0];
                const errors = firstErrorElement.errors;
                if (Array.isArray(errors) && errors.length > 0) {
                    console.log(errors);
                    const errMsg = errors.join('\n');
                    message?.error(errMsg);
                }
                switch (name) {
                    case 'flightSegments':
                        setTabName(Tab.Domestic);
                        break;
                    case 'intlFlightSegments':
                        setTabName(Tab.International);

                        break;
                    default:
                        return;
                }
                submitBtnRef.current.click();
            }
        }
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            labelWrap
            autoComplete="off"
            scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
        >
            <section className={styles.segments_panel}>
                <section className={styles.tabs_section}>
                    <div className={styles.tabs}>
                        <button
                            className={styles.tab_btn}
                            type="button"
                            onClick={() => setTabName(Tab.International)}
                            aria-selected={tabName === Tab.International && 'true'}
                        >
                            国际航班
                        </button>
                        <button
                            className={styles.tab_btn}
                            type="button"
                            onClick={() => setTabName(Tab.Domestic)}
                            aria-selected={tabName === Tab.Domestic && 'true'}
                        >
                            澳新航班
                        </button>
                    </div>
                </section>
                <section
                    className={`${styles.segments_section} ${styles.domestic}`}
                    aria-selected={tabName === Tab.Domestic && 'true'}
                >
                    <Form.List name="flightSegments" initialValue={[]}>
                        {(fields, { add, remove, move }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div className={styles.flight_segment_container} key={field.key}>
                                        <div className={styles.from_container}>
                                            <Form.Item
                                                {...field}
                                                key={Math.random()}
                                                name={[field.name, 'fromAirport']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择出发地',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    placeholder="出发地"
                                                    options={australianAirports}
                                                    popupMatchSelectWidth={false}
                                                    optionRender={(option) => {
                                                        return (
                                                            <Space>
                                                                <span>{option.data.airportCode}</span>
                                                                <span>({option.data.cityEnglishName})</span>
                                                            </Space>
                                                        );
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className={styles.to_container}>
                                            <Form.Item
                                                {...field}
                                                key={Math.random()}
                                                name={[field.name, 'toAirport']}
                                                required
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择目的地',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    placeholder="目的地"
                                                    options={australianAirports}
                                                    popupMatchSelectWidth={false}
                                                    optionRender={(option) => {
                                                        return (
                                                            <Space>
                                                                <span>{option.data.airportCode}</span>
                                                                <span>({option.data.cityEnglishName})</span>
                                                            </Space>
                                                        );
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className={styles.date_container}>
                                            <Form.Item
                                                {...field}
                                                key={Math.random()}
                                                name={[field.name, 'date']}
                                                required
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择日期',
                                                    },
                                                ]}
                                                getValueProps={(value) => ({
                                                    value: value ? dayjs(value) : undefined,
                                                })}
                                                getValueFromEvent={(date) =>
                                                    date ? date.format('YYYY-MM-DD') : undefined
                                                }
                                            >
                                                <DatePicker
                                                    type="date"
                                                    format="YYYY-MM-DD"
                                                    placeholder="日期"
                                                    disabledDate={(current) => {
                                                        // Can not select days before today and today
                                                        return current && current < dayjs().endOf('day');
                                                    }}
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className={styles.carrier_container}>
                                            <Form.Item
                                                {...field}
                                                key={Math.random()}
                                                name={[field.name, 'carrierCode']}
                                            >
                                                <Select
                                                    allowClear
                                                    placeholder={'航司'}
                                                    options={airlines}
                                                    popupMatchSelectWidth={false}
                                                    optionRender={(option) => {
                                                        return (
                                                            <Space>
                                                                <span>{option.data.carrierCode}</span>
                                                                <span>({option.data.name})</span>
                                                            </Space>
                                                        );
                                                    }}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className={styles.class_container}>
                                            <Form.Item
                                                {...field}
                                                key={Math.random()}
                                                name={[field.name, 'class']}
                                                initialValue={CabinClass.Economy}
                                                required
                                            >
                                                <Select
                                                    popupMatchSelectWidth={false}
                                                    options={[
                                                        { value: CabinClass.Economy, label: '经济舱' },
                                                        { value: CabinClass.Business, label: '商务舱' },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className={styles.flight_number_container}>
                                            <Form.Item
                                                {...field}
                                                key={Math.random()}
                                                name={[field.name, 'flightNumbers']}
                                                normalize={(value) => value.map((item) => item.trim().toUpperCase())} // trim and uppercase
                                            >
                                                <Select
                                                    mode="tags"
                                                    placeholder="优先航班"
                                                    tokenSeparators={[',']}
                                                    notFoundContent={null}
                                                    suffixIcon={null}
                                                />
                                                {/* <Input placeholder="优先航班" /> */}
                                            </Form.Item>
                                        </div>

                                        <div className={styles.action_container}>
                                            <CloseOutlined
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => remove(field.name)}
                                            />
                                        </div>
                                    </div>
                                ))}
                                <div className={styles.add_segment_container}>
                                    <Button type="primary" ghost onClick={() => add()}>
                                        <PlusOutlined /> 添加航段
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form.List>
                </section>
                <section
                    className={`${styles.segments_section} ${styles.international}`}
                    aria-selected={tabName === Tab.International && 'true'}
                >
                    <Form.List name="intlFlightSegments" initialValue={[]}>
                        {(fields, { add, remove, move }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div className={styles.flight_segment_container} key={field.key}>
                                        <div className={styles.from_container}>
                                            <Form.Item
                                                {...field}
                                                key={Math.random()}
                                                name={[field.name, 'fromAirport']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择出发地',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="出发地" />
                                            </Form.Item>
                                        </div>
                                        <div className={styles.to_container}>
                                            <Form.Item
                                                {...field}
                                                key={Math.random()}
                                                name={[field.name, 'toAirport']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择目的地',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="目的地" />
                                            </Form.Item>
                                        </div>
                                        <div className={styles.date_container}>
                                            <Form.Item
                                                {...field}
                                                key={Math.random()}
                                                name={[field.name, 'date']}
                                                getValueProps={(value) => ({
                                                    value: value ? dayjs(value) : undefined,
                                                })}
                                                getValueFromEvent={(date) =>
                                                    date ? date.format('YYYY-MM-DD') : undefined
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择日期',
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    type="date"
                                                    format="YYYY-MM-DD"
                                                    placeholder="日期"
                                                    disabledDate={(current) => {
                                                        // Can not select days before today and today
                                                        return current && current < dayjs().endOf('day');
                                                    }}
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className={styles.carrier_container}>
                                            <Form.Item
                                                {...field}
                                                key={Math.random()}
                                                name={[field.name, 'carrierCode']}
                                            >
                                                <Input placeholder="航司" />
                                            </Form.Item>
                                        </div>
                                        <div className={styles.class_container}>
                                            <Form.Item
                                                {...field}
                                                key={Math.random()}
                                                name={[field.name, 'class']}
                                                initialValue={CabinClass.Economy}
                                            >
                                                <Select
                                                    popupMatchSelectWidth={false}
                                                    options={[
                                                        { value: CabinClass.Economy, label: '经济舱' },
                                                        { value: CabinClass.Business, label: '商务舱' },
                                                    ]}
                                                />
                                            </Form.Item>
                                        </div>
                                        <div className={styles.flight_number_container}>
                                            <Form.Item
                                                {...field}
                                                key={Math.random()}
                                                name={[field.name, 'flightNumbers']}
                                                normalize={(value) => value.map((item) => item.trim().toUpperCase())} // trim and uppercase
                                            >
                                                <Select
                                                    mode="tags"
                                                    placeholder="优先航班"
                                                    tokenSeparators={[',']}
                                                    notFoundContent={null}
                                                    suffixIcon={null}
                                                />
                                                {/* <Input placeholder="优先航班" /> */}
                                            </Form.Item>
                                        </div>

                                        {/* <ArrowUpOutlined
                                                onClick={() => {
                                                    if (index > 0) move(index, index - 1);
                                                }}
                                                style={{
                                                    cursor: index === 0 ? 'not-allowed' : 'pointer',
                                                    color: index === 0 ? '#d9d9d9' : undefined,
                                                }}
                                            />
                                            <ArrowDownOutlined
                                                onClick={() => {
                                                    if (index < fields.length - 1) move(index, index + 1);
                                                }}
                                                style={{
                                                    cursor: index === fields.length - 1 ? 'not-allowed' : 'pointer',
                                                    color: index === fields.length - 1 ? '#d9d9d9' : undefined,
                                                }}
                                            /> */}
                                        <div className={styles.action_container}>
                                            <CloseOutlined
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => remove(field.name)}
                                            />
                                        </div>
                                    </div>
                                ))}
                                <div className={styles.add_segment_container}>
                                    <Button type="primary" ghost onClick={() => add()}>
                                        <PlusOutlined /> 添加航段
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form.List>
                </section>
            </section>

            <br />
            <div className={styles.non_segments_container}>
                <div className={styles.leading_container}>
                    <section className={styles.passenger_section}>
                        <div className={styles.adult_container}>
                            <Form.Item
                                label="成人"
                                name="numAdult"
                                initialValue={20}
                                rules={[
                                    {
                                        required: true,
                                        // message: '必填',
                                    },
                                ]}
                            >
                                <InputNumber
                                    type="number"
                                    placeholder="成人"
                                    min={10}
                                    max={MAX_PASSENGER}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>
                        <div className={styles.child_container}>
                            <Form.Item name="numChild" label="儿童">
                                <InputNumber
                                    type="number"
                                    placeholder="儿童"
                                    min={0}
                                    max={MAX_PASSENGER}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </div>
                    </section>
                    <section className={styles.group_name_section}>
                        <Form.Item label="团队名称" name="groupName">
                            <Input placeholder="团队名称" maxLength={30} />
                        </Form.Item>
                    </section>

                    <section className={styles.remark_section}>
                        <Form.Item name="remark" label="备注" hasFeedback={false} labelCol={labelCol}>
                            <Input.TextArea
                                placeholder="请提供尽可能多的信息，以便我们为您提供更多更准确的报价"
                                allowClear
                                showCount
                                autoSize={{ minRows: 4, maxRows: 8 }}
                                maxLength={500}
                            />
                        </Form.Item>
                    </section>

                    <section className={styles.upload_section}>
                        <Form.Item
                            labelWrap
                            label="根据行程为您制定团队机票"
                            labelCol={labelCol}
                            extra={
                                <p>
                                    文件大小: {(totalSize / 1024 / 1024).toFixed(2)} MB / {MAX_TOTAL_SIZE / 1024 / 1024}{' '}
                                    MB
                                </p>
                            }
                        >
                            <Form.Item name="files" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                                <Upload.Dragger
                                    name="files"
                                    beforeUpload={beforeUpload}
                                    onRemove={onRemove}
                                    listType="text"
                                    multiple
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">根据行程为您制定团队机票</p>
                                    <p className="ant-upload-hint">点击或将文件拖拽到此区域上传</p>
                                    <p className="ant-upload-hint">支持单个或批量上传</p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Form.Item>
                    </section>

                    <section className={styles.submit_section}>
                        <Form.Item labelCol={labelCol}>
                            <Flex>
                                <Button ref={submitBtnRef} type="primary" htmlType="submit" block>
                                    提交查询
                                </Button>
                            </Flex>
                        </Form.Item>
                    </section>
                    <section className={styles.reset_section}>
                        <Form.Item labelCol={labelCol}>
                            <Popconfirm
                                title="重置表格"
                                description="重置后将清空所有输入内容"
                                onConfirm={() => form.resetFields()}
                                onCancel={(e) => {
                                    console.log(e);
                                }}
                                okText="重置"
                                cancelText="取消"
                            >
                                <Button type="link" size="small" icon={<RedoOutlined />}>
                                    重置表格
                                </Button>
                            </Popconfirm>
                        </Form.Item>
                    </section>
                </div>
                <div className={styles.trailing_container}>
                    <GroupFeatures />
                </div>
            </div>
        </Form>
    );
}

GroupEnquiryCreateForm.propTypes = {
    form: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired,
    maxFileSizeInMB: PropTypes.number,
};

export default GroupEnquiryCreateForm;
