import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import qs from 'qs';
import LodgingForm from '@components/hotel/LodgingForm';
import { Dimmer, Loader } from 'semantic-ui-react';
import HotelsTable from '@components/hotel/HotelsTable';
import styles from '@styles/HotelResultsPage.module.css';
import useHotelAvail from '@hooks/hotel/useHotelAvail';
import { GetHotelAvailData } from '@utils/storage';

function HotelResultsPage() {
    const [req, setReq] = useState({});
    const { hotelAvailInfo, fetchHotelAvail, fetched, fetching, abort } = useHotelAvail();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.toString() !== '') {
            const req = qs.parse(searchParams.toString());
            // Cast types
            req.rooms.forEach((item) => {
                item.adult = Number(item.adult);
                item.child = Number(item.child);
            });
            const data = GetHotelAvailData();
            if (Object.keys(data).length == 0) {
                fetchData(req);
            } else {
                setReq(req);
            }
        }
        return () => {
            abort();
        };
    }, []);

    const fetchData = (req) => {
        setReq(req);
        fetchHotelAvail(req);
    };

    const handleSearch = (req) => {
        const queryString = qs.stringify(req);
        setSearchParams(queryString);
        fetchData(req);
    };

    const handelHotelClick = (data) => {
        const queryObj = qs.parse(location.search, { ignoreQueryPrefix: true });
        queryObj.rateKey = data.rateKey;
        queryObj.hotelCode = data.hotelCode;
        queryObj.codeContext = data.codeContext;
        const queryString = qs.stringify(queryObj);
        // Goto hotel page, need to keep the original query string
        navigate(
            {
                pathname: `/dashboard/accommodations/hotels/hotel/${data.hotelCode}`,
                search: `${queryString}`,
            },
            { replace: false }
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.title}>Hotels</div>
            <div className={styles.search_container}>
                <LodgingForm onSubmit={handleSearch} initData={req} />
            </div>
            <HotelsTable hotels={hotelAvailInfo} onSubmit={handelHotelClick} />
            <Dimmer active={fetching} inverted page>
                <Loader>
                    <div>Loading...</div>
                    <br />
                    <div>
                        <button className="secondary_btn" onClick={abort}>
                            Cancel
                        </button>
                    </div>
                </Loader>
            </Dimmer>
        </div>
    );
}

export default HotelResultsPage;
