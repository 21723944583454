import React from 'react';
import styles from '@styles/AirFeaturesBanner.module.css';
import Object from '@assets/air-features/objects.svg';
import Rectangle from '@assets/air-features/rectangle.svg';
import IconCheck from '@assets/air-features/icon_check.svg';

function AirFeaturesBanner() {
    return (
        <div className={styles.container}>
            <div className={styles.rectangle_container}>
                <img src={Rectangle} />
            </div>
            <div className={styles.objects_container}>
                <img src={Object} />
            </div>
            <div className={styles.caption_container}>
                <div className={styles.content}>
                    <div className={styles.title}>还在为比价找机票烦恼吗？一键搜索多家航司，实时智能比价</div>
                    <div className={styles.point}>
                        <div className={styles.icon_container}>
                            <img src={IconCheck} />
                        </div>
                        全天候服务
                    </div>
                    <div className={styles.point}>
                        <div className={styles.icon_container}>
                            <img src={IconCheck} />
                        </div>
                        一站式预订
                    </div>
                    <div className={styles.point}>
                        <div className={styles.icon_container}>
                            <img src={IconCheck} />
                        </div>
                        为您呈现最优惠的机票价格
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AirFeaturesBanner;
