import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '@styles/FilterLayout.module.css';
import { useSearchParams } from 'react-router-dom';
import { Selector } from '@utils/constants';
import { SelectorParser, ActivityDuration, ActivityDurationParse } from '../../utils/constants';

export const K_Kinds = 'kinds';
export const K_Durations = 'durations';
export const K_Recommendations = 'recommendations';

export const FilterConstants = {
    Duration: {
        ...ActivityDuration,
    },
    Family: 'family',
    Kind: {
        Ticket: 'ticket', //门票
        Team: Selector.Team,
        Car: Selector.Cars,
        Package: Selector.Package,
        // Transfers: Selector.Transfers,
        Others: Selector.OtherExperience, //其他体验
    },
};

//`replace`: replace link stack
function ActivitiesFilter({ replace = true, sticky = true }) {
    const [kinds, setKinds] = useState([]);
    const [recommendations, setRecommendations] = useState([]);
    const [durations, setDurations] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const params = new URLSearchParams(searchParams);
        const kinds = params.getAll(K_Kinds) || [];
        const durations = params.getAll(K_Durations) || [];
        const recommendations = params.getAll(K_Recommendations) || [];
        setKinds(kinds);
        setDurations(durations);
        setRecommendations(recommendations);
    }, [searchParams]);

    const handleOnChangeFilter = (e, k) => {
        if (!k) {
            console.error('Empty key');
            return;
        }
        const name = e.target.getAttribute('name');
        let value = e.target.getAttribute('value');
        // workaround select tag
        if (!!value == false) {
            value = e.target.value;
        }
        // console.log({ [name]: value });
        const params = new URLSearchParams(searchParams);
        const filters = params.getAll(k) || [];
        if (filters.includes(value)) {
            params.delete(k);
        } else {
            params.set(k, value);
        }
        setSearchParams(params, { replace: replace });
    };

    return (
        <div className={`${styles.container} ${sticky && styles.sticky}`}>
            <div className={styles.sector_container}>
                <div className={styles.title}>分类</div>
                <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name={FilterConstants.Kind.Ticket}
                            type="checkbox"
                            id={FilterConstants.Kind.Ticket}
                            value={FilterConstants.Kind.Ticket}
                            onChange={(e) => handleOnChangeFilter(e, K_Kinds)}
                            checked={kinds.includes(FilterConstants.Kind.Ticket)}
                        />
                        <label htmlFor={FilterConstants.Kind.Ticket}>景点门票</label>
                    </div>
                </div>
                <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name={FilterConstants.Kind.Team}
                            type="checkbox"
                            id={FilterConstants.Kind.Team}
                            value={FilterConstants.Kind.Team}
                            onChange={(e) => handleOnChangeFilter(e, K_Kinds)}
                            checked={kinds.includes(FilterConstants.Kind.Team)}
                        />
                        <label htmlFor={FilterConstants.Kind.Team}>{SelectorParser(FilterConstants.Kind.Team)}</label>
                    </div>
                </div>

                <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name={FilterConstants.Kind.Package}
                            type="checkbox"
                            id={FilterConstants.Kind.Package}
                            value={FilterConstants.Kind.Package}
                            onChange={(e) => handleOnChangeFilter(e, K_Kinds)}
                            checked={kinds.includes(FilterConstants.Kind.Package)}
                        />
                        <label htmlFor={FilterConstants.Kind.Package}>
                            {SelectorParser(FilterConstants.Kind.Package)}
                        </label>
                    </div>
                </div>
                {/* <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name={FilterConstants.Kind.Transfers}
                            type="checkbox"
                            id={FilterConstants.Kind.Transfers}
                            value={FilterConstants.Kind.Transfers}
                            onChange={(e) => handleOnChangeFilter(e, K_Kinds)}
                            checked={kinds.includes(FilterConstants.Kind.Transfers)}
                        />
                        <label htmlFor={FilterConstants.Kind.Transfers}>
                            {SelectorParser(FilterConstants.Kind.Transfers)}
                        </label>
                    </div>
                </div> */}
                <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name={FilterConstants.Kind.Others}
                            type="checkbox"
                            id={FilterConstants.Kind.Others}
                            value={FilterConstants.Kind.Others}
                            onChange={(e) => handleOnChangeFilter(e, K_Kinds)}
                            checked={kinds.includes(FilterConstants.Kind.Others)}
                        />
                        <label htmlFor={FilterConstants.Kind.Others}>
                            {SelectorParser(FilterConstants.Kind.Others)}
                        </label>
                    </div>
                </div>
            </div>
            <div className={styles.sector_container}>
                <div className={styles.title}>推荐</div>
                <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name={FilterConstants.Family}
                            type="checkbox"
                            id={FilterConstants.Family}
                            value={FilterConstants.Family}
                            onChange={(e) => handleOnChangeFilter(e, K_Recommendations)}
                            checked={recommendations.includes(FilterConstants.Family)}
                        />
                        <label htmlFor={FilterConstants.Family}>适合家庭</label>
                    </div>
                </div>
            </div>
            <div className={styles.sector_container}>
                <div className={styles.title}>活动时长</div>
                <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name="duration"
                            value={FilterConstants.Duration.LessOneHour}
                            type="checkbox"
                            id={FilterConstants.Duration.LessOneHour}
                            onChange={(e) => handleOnChangeFilter(e, K_Durations)}
                            checked={durations.includes(FilterConstants.Duration.LessOneHour.toString())}
                        />
                        <label htmlFor={FilterConstants.Duration.LessOneHour}>
                            {ActivityDurationParse(FilterConstants.Duration.LessOneHour)}
                        </label>
                    </div>
                </div>
                <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name="duration"
                            value={FilterConstants.Duration.OneToFourHour}
                            type="checkbox"
                            id={FilterConstants.Duration.OneToFourHour}
                            onChange={(e) => handleOnChangeFilter(e, K_Durations)}
                            checked={durations.includes(FilterConstants.Duration.OneToFourHour.toString())}
                        />
                        <label htmlFor={FilterConstants.Duration.OneToFourHour}>
                            {ActivityDurationParse(FilterConstants.Duration.OneToFourHour)}
                        </label>
                    </div>
                </div>
                <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name="duration"
                            value={FilterConstants.Duration.FourToOneDay}
                            type="checkbox"
                            id={FilterConstants.Duration.FourToOneDay}
                            onChange={(e) => handleOnChangeFilter(e, K_Durations)}
                            checked={durations.includes(FilterConstants.Duration.FourToOneDay.toString())}
                        />
                        <label htmlFor={FilterConstants.Duration.FourToOneDay}>
                            {ActivityDurationParse(FilterConstants.Duration.FourToOneDay)}
                        </label>
                    </div>
                </div>
                <div className={styles.item_container}>
                    <div className={styles.checkbox_container}>
                        <input
                            name="duration"
                            value={FilterConstants.Duration.MoreThanOneDay}
                            type="checkbox"
                            id={FilterConstants.Duration.MoreThanOneDay}
                            onChange={(e) => handleOnChangeFilter(e, K_Durations)}
                            checked={durations.includes(FilterConstants.Duration.MoreThanOneDay.toString())}
                        />
                        <label htmlFor={FilterConstants.Duration.MoreThanOneDay}>
                            {ActivityDurationParse(FilterConstants.Duration.MoreThanOneDay)}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
}

ActivitiesFilter.propTypes = {
    replace: PropTypes.bool,
    sticky: PropTypes.bool,
};

export default ActivitiesFilter;
