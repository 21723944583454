import { useState, useCallback, useRef } from 'react';
import * as config from '@config';
import { GetToken } from '@utils/storage';

function useRidePaxUpdate() {
    const [updating, setUpdating] = useState(false);
    const abortRef = useRef();

    const updateRidePax = useCallback(async (id, req) => {
        abort();
        setUpdating(true);
        abortRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL2}/ride/orders/${id}/pax`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${GetToken()}`,
                },
                body: JSON.stringify(req),
                signal: abortRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to update ride pax');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error cancel ride order:', error);
            }
            throw error;
        } finally {
            setUpdating(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);
    return { updating, updateRidePax, abort };
}

export default useRidePaxUpdate;
