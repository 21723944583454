import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import BackBar from '@components/BackBar';
import { Descriptions } from 'antd';
import useTransferEnquiry from '@hooks/transfer-enquiry/useTransferEnquiry';
import dayjs from 'dayjs';
import Comments from '../../../components/Comments';

function TransferEnquiryDetailPage() {
    const { id } = useParams();

    const { fetching, enquiry, fetchEnquiry, abort } = useTransferEnquiry();

    useEffect(() => {
        fetchEnquiry(id);
        return () => abort();
    }, [id]);

    const getItems = (data) => {
        console.log('Data:', data);

        if (!data) {
            return [];
        }
        const items = [];
        items.push(
            ...[
                {
                    key: 1,
                    label: '提交时间',
                    children: dayjs(data.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                },
                {
                    key: 2,
                    label: '状态',
                    children: data.status,
                },
            ]
        );

        // by hour
        if (data.duration) {
            items.push(
                ...[
                    {
                        key: 3,
                        label: '起点/终点',
                        children: data.pickup,
                    },
                    {
                        key: 4,
                        label: '用车时长',
                        children: data.duration,
                    },
                    {
                        key: 5,
                        label: '乘客人数',
                        children: data.passengerCount,
                    },
                    { key: 6, label: '行李数', children: data.luggageCount },
                ]
            );
        } else {
            items.push(
                ...[
                    {
                        key: 3,
                        label: '起点',
                        children: data.pickup,
                    },
                    {
                        key: 4,
                        label: '终点',
                        children: data.dropoff,
                    },
                ]
            );
            items.push(
                ...[
                    {
                        key: 5,
                        label: '乘客数',
                        children: data.passengerCount,
                    },
                    { key: 6, label: '行李数', children: data.luggageCount },
                ]
            );
            items.push({
                key: 7,
                label: '用车时间',
                children: data.time,
            });
            if (data.returnTime) {
                items.push({
                    key: 8,
                    label: '返程时间',
                    children: data.returnTime,
                });
            }
        }
        console.log('Items:', items);

        return items;
    };

    return (
        <div>
            <BackBar />
            <h3>用车咨询详情</h3>
            {fetching && <div>Loading...</div>}
            <Descriptions items={getItems(enquiry)} bordered column={2} />
            {enquiry && <Comments commentOnID={enquiry.id} />}
        </div>
    );
}

export default TransferEnquiryDetailPage;
