import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Modal, Select } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import useRidePaxUpdate from './hooks/useRidePaxUpdate';

function index({ id, title, pax, editWelcomeSign = false, callback = () => {} }) {
    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const { updating, updateRidePax, abort } = useRidePaxUpdate();

    useEffect(() => {
        return () => {
            if (!open) {
                form.resetFields();
            }
        };
    }, [open, form]);

    const handleUpdate = () => {
        const values = form.getFieldsValue();
        const req = {
            ...values,
        };
        updateRidePax(id, req)
            .then((data) => {
                console.log('data:', data);
                setOpen(false);
                callback();
            })
            .catch((error) => {
                alert(error.message);
            });
    };

    const groupStyle = { display: 'flex', gap: 16 };

    return (
        <>
            <a style={{ padding: '0 8px' }}>
                <EditOutlined onClick={() => setOpen((prev) => !prev)} />
            </a>
            <Modal title={title} open={open} onClose={() => setOpen(false)} footer={null} closeIcon={false} centered>
                <Form form={form} layout="vertical" initialValues={pax}>
                    {!editWelcomeSign && (
                        <>
                            <div style={groupStyle}>
                                <Form.Item
                                    label="姓名"
                                    name={'firstName'}
                                    rules={[{ required: true, message: '' }]}
                                    required
                                    style={{ width: '100%' }}
                                >
                                    <Input type="text" />
                                </Form.Item>
                                <Form.Item label="称呼" name={'salutation'} style={{ width: '100%' }}>
                                    <Select
                                        suffixIcon={null}
                                        allowClear
                                        options={[
                                            {
                                                label: '先生',
                                                value: 'Mr',
                                            },
                                            {
                                                label: '女士',
                                                value: 'Mrs',
                                            },
                                            {
                                                label: '小姐',
                                                value: 'Ms',
                                            },
                                            {
                                                label: '其他',
                                                value: 'Other',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                            <div style={groupStyle}>
                                <Form.Item
                                    label="常用邮箱"
                                    name={'email'}
                                    rules={[{ required: true, message: '' }]}
                                    required
                                    style={{ width: '100%' }}
                                >
                                    <Input type="text" />
                                </Form.Item>
                                <Form.Item
                                    label="联系电话"
                                    name={'cellPhone'}
                                    rules={[{ required: true, message: '' }]}
                                    required
                                    style={{ width: '100%' }}
                                >
                                    <Input type="text" />
                                </Form.Item>
                            </div>
                        </>
                    )}
                    {editWelcomeSign && (
                        <Form.Item label="举牌内容" name={'welcomeSign'} style={{ width: '100%' }}>
                            <Input type="text" />
                        </Form.Item>
                    )}
                </Form>
                <div style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end', gap: 8 }}>
                    <Button type="primary" onClick={handleUpdate} loading={updating}>
                        Update
                    </Button>
                    <Button type="default" onClick={() => setOpen(false)}>
                        Close
                    </Button>
                </div>
            </Modal>
        </>
    );
}

index.propTypes = {
    id: PropTypes.string.isRequired,
    pax: PropTypes.object,
    callback: PropTypes.func,
    editWelcomeSign: PropTypes.bool,
};

export default index;
