import { useCallback, useRef, useState } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useAccount() {
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState(null); //default value is null

    const abortRef = useRef();

    const fetchAccount = useCallback(async () => {
        abort();
        setFetching(true);
        abortRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL}/users`, {
                signal: abortRef.current.signal,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                },
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Fetch account failed');
            }
            const data = await response.json();
            setData(data);
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetch account:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);
    return { fetching, data, fetchAccount, abort };
}

export default useAccount;
