import { useState, useRef, useCallback } from 'react';
import * as config from '@config';
import { GetToken } from '@utils/storage';

function useRefreshFlightOrder(props) {
    const [refreshing, setRefreshing] = useState(false);
    const abortControllerRef = useRef(null);

    const handleRefresh = useCallback(
        async (ref) => {
            console.log(ref);
            if (!ref) {
                throw new Error('Order number is required');
            }
            abortControllerRef.current = new AbortController();
            setRefreshing(true);
            try {
                const response = await fetch(`${config.apiURL2}/flights/orders/refresh/${ref}`, {
                    method: 'POST',
                    headers: { Authorization: `Bearer ${GetToken()}` },
                    signal: abortControllerRef.current.signal,
                });
                if (!response.ok) {
                    const data = await response.json();
                    throw new Error(data.error || 'An error occurred while refreshing flight order');
                }
                const responseData = await response.json();
                console.log('responseData', responseData);
                return responseData;
            } catch (error) {
                if (error.name === 'AbortError') {
                    console.log('Request was aborted');
                    throw new Error('Request was aborted');
                } else {
                    console.error('Error refreshing flight order:', error);
                }
                throw error;
            } finally {
                setRefreshing(false);
            }
        },
        [config.apiURL2]
    );

    const abort = useCallback(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    }, []);

    return { handleRefresh, refreshing, abort };
}

useRefreshFlightOrder.propTypes = {};

export default useRefreshFlightOrder;
