import React, { useEffect, useState } from 'react';
import styles from '@styles/HotelPage.module.css';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import qs from 'qs';
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';
import LodgingForm from '@components/hotel/LodgingForm';
import Gallery from '@components/Gallery';
import BackBar from '@components/BackBar';
import RoomsTable from '@components/hotel/RoomsTable';
import useHotelDetail from '@hooks/hotel/useHotelDetail';
import { Image, Result } from 'antd';
import { IoMdImages } from 'react-icons/io';

function HotelPage(props) {
    const [req, setReq] = useState({});
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();

    const {
        fetching,
        fetched,
        fetchHotelDetail,
        hotelInfo,
        hotelDescriptiveInfo,
        rooms,
        rateInfos,
        mediaItems,
        imageURLs,
        abort,
    } = useHotelDetail();

    useEffect(() => {
        fetchData();
        return () => {
            abort();
        };
    }, [searchParams]);

    const fetchData = () => {
        const req = qs.parse(searchParams.toString());
        req.rooms?.forEach((item) => {
            item.adult = Number(item.adult);
            item.child = Number(item.child);
        });
        if (req.rateKey) {
            req.rateKey = decodeURIComponent(req.rateKey);
        }
        setReq(req);
        fetchHotelDetail(req);
    };

    //Hotel ref search
    const handleSearch = (formReq) => {
        // Delete rateKey
        delete formReq.rateKey;
        if (hotelInfo.hotelCode) {
            formReq.hotelCode = hotelInfo.hotelCode;
        }
        if (hotelInfo.codeContext) {
            formReq.codeContext = hotelInfo.codeContext;
        }
        formReq.timestamp = Date.now();
        const queryString = qs.stringify(formReq);
        console.log(queryString);
        navigate(
            {
                search: `?${queryString}`,
            },
            { replace: true }
        );
    };

    const Photos = () => {
        const [open, setOpen] = useState(false);
        return (
            <>
                <a onClick={() => setOpen(true)} style={{ display: 'flex', alignItems: 'center' }}>
                    <IoMdImages />
                </a>
                <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} closeIcon>
                    <Modal.Header>Photos</Modal.Header>
                    <Modal.Content>
                        <Image.PreviewGroup
                            preview={{
                                onChange: (current, prev) =>
                                    console.log(`current index: ${current}, prev index: ${prev}`),
                                toolbarRender: false,
                            }}
                        >
                            {imageURLs.map((src, index) => {
                                return <Image key={index} src={src} width={200} height={120} />;
                            })}
                        </Image.PreviewGroup>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className="primary_btn" onClick={() => setOpen(false)}>
                            Close
                        </Button>
                    </Modal.Actions>
                </Modal>
            </>
        );
    };

    const handleBook = (rateKey) => {
        console.log('rateKey', rateKey);
        navigate({
            pathname: 'book',
            search: `rateKey=${encodeURIComponent(rateKey)}`,
        });
    };

    return (
        <div className={styles.container}>
            <BackBar />
            <section className={styles.hotel_info_container}>
                {hotelInfo && (
                    <div style={{ fontSize: '24px', fontWeight: 500, marginBottom: 16, display: 'flex', gap: 8 }}>
                        <span>{hotelInfo.hotelName}</span> <Photos />
                    </div>
                )}
                <p>{hotelDescriptiveInfo?.locationInfo?.fullAddress}</p>
            </section>
            <section>
                <LodgingForm initData={req} onSubmit={handleSearch} noLocation={true} />
            </section>
            <section className={styles.rooms_container}>
                {rateInfos.length > 0 && (
                    <RoomsTable rateInfos={rateInfos} rooms={rooms} mediaItems={mediaItems} onBooking={handleBook} />
                )}
                {rateInfos.length == 0 && fetched == true && <Result status="warning" title="No rooms available" />}
            </section>
            <Dimmer active={fetching} inverted page>
                <Loader></Loader>
            </Dimmer>
        </div>
    );
}

HotelPage.propTypes = {};

export default HotelPage;
