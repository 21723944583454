import { zhCN } from 'date-fns/locale';
import {
    parse,
    format,
    differenceInMinutes,
    differenceInDays,
    setMinutes,
    setHours,
    isAfter,
    isBefore,
    isSameHour,
    startOfDay,
} from 'date-fns';

export const timeParse = (str) => {
    const parsedDate = parse(str, 'yyyyMMddHHmm', new Date());
    return format(parsedDate, 'MM月dd日 HH:mm', { locale: zhCN });
};

export const timePrint = (str) => {
    const parsedDate = parse(str, 'yyyyMMddHHmm', new Date());
    return format(parsedDate, 'HH:mm', { locale: zhCN });
};

export const diffInDays = (segments) => {
    if (!segments || segments.length === 0) return '-';
    const depTime = parse(segments[0].depTime, 'yyyyMMddHHmm', new Date());
    const arrTime = parse(segments[segments.length - 1].arrTime, 'yyyyMMddHHmm', new Date());
    return differenceInDays(startOfDay(arrTime), startOfDay(depTime));
};

export const getFlightNumbers = (segments) => {
    return segments?.map((seg) => seg.flightNumber).join('/') || '-';
};

//add up duration because if timezone difference we can not use arrTime - depTime
export const getTotalDuration = (segments) => {
    if (!segments || segments.length === 0) return '-';
    const flightMinutes = segments.reduce((acc, seg) => acc + (seg.duration || 0), 0);
    const stopMinutes = stopDurationInMinutes(segments);
    const totalMinutes = flightMinutes + stopMinutes;
    return `${Math.floor(totalMinutes / 60)}h${totalMinutes % 60}m`;
};

const stopDurationInMinutes = (segments) => {
    if (segments.length === 1) return 0;
    const stopDurations = segments.slice(0, -1).map((seg, index) => {
        const nextSeg = segments[index + 1];
        const arrTime = parse(seg.arrTime, 'yyyyMMddHHmm', new Date());
        const nextDepTime = parse(nextSeg.depTime, 'yyyyMMddHHmm', new Date());
        return differenceInMinutes(nextDepTime, arrTime);
    });
    return stopDurations.reduce((acc, duration) => acc + duration, 0);
};

// Transfer is in the same timezone so it is safe to use arrTime - depTime
export const getTransferInfo = (segments) => {
    if (segments.length === 1) return '直达';
    const transfers = segments.slice(0, -1).map((seg, index) => {
        const nextSeg = segments[index + 1];
        const arrTime = parse(seg.arrTime, 'yyyyMMddHHmm', new Date());
        const nextDepTime = parse(nextSeg.depTime, 'yyyyMMddHHmm', new Date());
        const stopDuration = differenceInMinutes(nextDepTime, arrTime);
        return {
            airport: seg.arrAirportName,
            duration: `${Math.floor(stopDuration / 60)}h${stopDuration % 60}m`,
        };
    });
    return transfers.map((t) => `${t.airport}/${t.duration}`).join(' → ');
};

//Time stirng in format 'yyyyMMddHHmm'
export const isInTimeSlot = (timeString, [startHour, endHour]) => {
    if (!timeString) return false;
    const date = parse(timeString, 'yyyyMMddHHmm', new Date());
    const startTime = setMinutes(setHours(date, startHour), 0);
    const endTime = setMinutes(setHours(date, endHour), 0);

    if (startHour < endHour) {
        return (isAfter(date, startTime) || isSameHour(date, startTime)) && isBefore(date, endTime);
    } else {
        // For time slots that cross midnight
        return isAfter(date, startTime) || isBefore(date, endTime) || isSameHour(date, startTime);
    }
};
