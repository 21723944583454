import React from 'react';
import PropTypes from 'prop-types';
import { Card, List, Tabs, Space } from 'antd';
import { FaCheck, FaXmark } from 'react-icons/fa6';
import { ActivityInfoRole, ActivityInfoRoleParse } from '@utils/constants';
import { FileOutlined } from '@ant-design/icons';

function index({ activity }) {
    const { name, nameEN, tourCode, content, information, duration, variants, segmentOptions: segments } = activity;

    const renderInformation = () => {
        if (!information || information.length === 0) {
            if (activity.demo) {
                return null;
            }
        }
        const renderInfoList = (role) => {
            let bulletNode = <span style={{ fontSize: 18 }}>&#x2022;</span>;
            switch (role) {
                case ActivityInfoRole.Include:
                    bulletNode = <FaCheck style={{ color: 'green' }} />;
                    break;
                case ActivityInfoRole.Exclude:
                    bulletNode = <FaXmark style={{ color: 'red' }} />;
                    break;
                case ActivityInfoRole.Note:
                    break;
                case ActivityInfoRole.ChangePolicy:
                    break;
            }
            return (
                <List
                    dataSource={(information || []).filter((item) => item.role === role)}
                    renderItem={(item) => (
                        <List.Item
                            style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '8px' }}
                        >
                            {bulletNode} {item.content}
                        </List.Item>
                    )}
                    locale={{ emptyText: '没有可用信息' }}
                />
            );
        };

        const tabItems = Object.values(ActivityInfoRole).map((role) => ({
            key: String(role),
            label: ActivityInfoRoleParse(role),
            children: renderInfoList(role),
        }));

        return (
            <Card title="活动信息" style={{ marginBottom: '16px' }}>
                <Tabs defaultActiveKey={String(ActivityInfoRole.Include)} items={tabItems} tabPosition={'top'} />
            </Card>
        );
    };

    return (
        <div>
            {renderInformation()}
            {content && (
                <Card>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                </Card>
            )}
            <div style={{ marginTop: '24px' }}>
                {activity?.brochures?.map((item, index) => {
                    return (
                        <Space key={index}>
                            <FileOutlined />
                            <a onClick={() => OpenURLFile(item.filename)} title={item.filename}>
                                {item.filename}
                            </a>
                        </Space>
                    );
                })}
            </div>
        </div>
    );
}

index.propTypes = {
    activity: PropTypes.object.isRequired,
};

export default index;
