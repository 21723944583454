import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select, DatePicker, Button, Input, Space, Flex } from 'antd';
import styles from './index.module.css';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

function GroupStayForm({ form, onFinish, onValuesChange = () => {} }) {
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onValuesChange}
            labelWrap
            autoComplete="off"
            scrollToFirstError={{ behavior: 'smooth', block: 'center' }}
        >
            <section className={styles.hotels_section}>
                <Form.List name="hotels" initialValue={[]}>
                    {(fields, { add, remove, move }) => (
                        <>
                            {fields.map((field, index) => (
                                <div className={styles.hotel_container} key={field.key}>
                                    <div className={styles.name_container}>
                                        <Form.Item
                                            {...field}
                                            key={Math.random()}
                                            name={[field.name, 'name']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Input placeholder={`酒店名称 ${index + 1}`} />
                                        </Form.Item>
                                    </div>

                                    <div className={styles.city_container}>
                                        <Form.Item
                                            {...field}
                                            key={Math.random()}
                                            name={[field.name, 'city']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                        >
                                            <Select
                                                allowClear
                                                showSearch
                                                placeholder="城市"
                                                options={[
                                                    { value: 'sydney', text: '悉尼(Sydney)', label: '悉尼' },
                                                    { value: 'melbourne', text: '墨尔本(Melbourne)', label: '墨尔本' },
                                                    {
                                                        value: 'brisbane',
                                                        text: '布里斯班(Brisbane)',
                                                        label: '布里斯班',
                                                    },
                                                    {
                                                        value: 'gold coast',
                                                        text: '黄金海岸(Gold Coast)',
                                                        label: '黄金海岸',
                                                    },
                                                    { value: 'perth', text: '珀斯(Perth)', label: '珀斯' },
                                                    { value: 'cairns', text: '凯恩斯(Cairns)', label: '凯恩斯' },
                                                    {
                                                        value: 'adelaide',
                                                        text: '阿德莱德(Adelaide)',
                                                        label: '阿德莱德',
                                                    },
                                                    { value: 'canberra', text: '堪培拉(Canberra)', label: '堪培拉' },
                                                    { value: 'hobart', text: '霍巴特(Hobart)', label: '霍巴特' },
                                                    { value: 'darwin', text: '达尔文(Darwin)', label: '达尔文' },
                                                    {
                                                        value: 'hamilton',
                                                        text: '哈密尔顿(Hamilton)',
                                                        label: '哈密尔顿',
                                                    },
                                                    { value: 'auckland', text: '奥克兰(Auckland)', label: '奥克兰' },
                                                    {
                                                        value: 'christchurch',
                                                        text: '基督城(Christchurch)',
                                                        label: '基督城',
                                                    },
                                                    {
                                                        value: 'queenstown',
                                                        text: '皇后镇(Queenstown)',
                                                        label: '皇后镇',
                                                    },
                                                    {
                                                        value: 'other',
                                                        text: '其他城市',
                                                        label: '其他城市',
                                                    },
                                                ]}
                                                popupMatchSelectWidth={false}
                                                optionRender={(option) => {
                                                    return (
                                                        <Space>
                                                            <span>{option.data.text}</span>
                                                        </Space>
                                                    );
                                                }}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className={styles.check_in_container}>
                                        <Form.Item
                                            {...field}
                                            key={Math.random()}
                                            name={[field.name, 'checkIn']}
                                            required
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                            getValueProps={(value) => ({
                                                value: value ? dayjs(value) : undefined,
                                            })}
                                            getValueFromEvent={(date) => (date ? date.format('YYYY-MM-DD') : undefined)}
                                        >
                                            <DatePicker
                                                type="date"
                                                format="YYYY-MM-DD"
                                                placeholder="入住日期"
                                                disabledDate={(current) => {
                                                    // Can not select days before today and today
                                                    return current && current < dayjs().endOf('day');
                                                }}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={styles.check_out_container}>
                                        <Form.Item
                                            {...field}
                                            key={Math.random()}
                                            name={[field.name, 'checkOut']}
                                            required
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '',
                                                },
                                            ]}
                                            getValueProps={(value) => ({
                                                value: value ? dayjs(value) : undefined,
                                            })}
                                            getValueFromEvent={(date) => (date ? date.format('YYYY-MM-DD') : undefined)}
                                        >
                                            <DatePicker
                                                type="date"
                                                format="YYYY-MM-DD"
                                                placeholder="退房日期"
                                                disabledDate={(current) => {
                                                    const checkIn = form.getFieldValue([
                                                        'hotels',
                                                        field.name,
                                                        'checkIn',
                                                    ]);
                                                    if (checkIn) {
                                                        return current && current < dayjs(checkIn).endOf('day');
                                                    }
                                                    // Can not select days before today and today
                                                    return current && current < dayjs().endOf('day');
                                                }}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className={styles.num_room_container}>
                                        <Form.Item
                                            {...field}
                                            key={Math.random()}
                                            name={[field.name, 'numRoom']}
                                            initialValue={1}
                                        >
                                            <Select
                                                popupMatchSelectWidth={false}
                                                options={[
                                                    { value: 1, label: '1间' },
                                                    { value: 2, label: '2间' },
                                                    { value: 3, label: '3间' },
                                                    { value: 4, label: '4间' },
                                                    { value: 5, label: '5间' },
                                                    { value: 6, label: '6间' },
                                                    { value: 7, label: '7间' },
                                                    { value: 8, label: '8间' },
                                                    { value: 9, label: '9间' },
                                                    { value: 10, label: '10间' },
                                                    { value: 11, label: '11间' },
                                                    { value: 12, label: '12间' },
                                                    { value: 13, label: '13间' },
                                                    { value: 14, label: '14间' },
                                                    { value: 15, label: '15间' },
                                                    { value: 16, label: '16间' },
                                                    { value: 17, label: '17间' },
                                                    { value: 18, label: '18间' },
                                                    { value: 19, label: '19间' },
                                                    { value: 20, label: '20间' },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className={styles.num_adult_container}>
                                        <Form.Item
                                            {...field}
                                            key={Math.random()}
                                            name={[field.name, 'numAdult']}
                                            initialValue={2}
                                        >
                                            <Select
                                                popupMatchSelectWidth={false}
                                                options={[
                                                    { value: 1, label: '1成人' },
                                                    { value: 2, label: '2成人' },
                                                    { value: 3, label: '3成人' },
                                                    { value: 4, label: '4成人' },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className={styles.num_child_container}>
                                        <Form.Item
                                            {...field}
                                            key={Math.random()}
                                            name={[field.name, 'numChild']}
                                            initialValue={0}
                                        >
                                            <Select
                                                popupMatchSelectWidth={false}
                                                options={[
                                                    { value: 0, label: '0儿童' },
                                                    { value: 1, label: '1儿童' },
                                                    { value: 2, label: '2儿童' },
                                                    { value: 3, label: '3儿童' },
                                                ]}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div className={styles.action_container}>
                                        <CloseOutlined
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => remove(field.name)}
                                        />
                                    </div>
                                </div>
                            ))}
                            <div className={styles.add_hotel_container}>
                                <Button type="primary" ghost onClick={() => add()}>
                                    <PlusOutlined /> 添加住宿
                                </Button>
                            </div>
                        </>
                    )}
                </Form.List>
            </section>
            <section className={styles.meta_section}>
                <div className={styles.group_name_container}>
                    <Form.Item name="groupName">
                        <Input placeholder="团队名称" maxLength={30} />
                    </Form.Item>
                </div>
                <div className={styles.remark_container}>
                    <Form.Item name="remark" hasFeedback={false}>
                        <Input.TextArea
                            placeholder="备注"
                            allowClear
                            autoSize={{ minRows: 1, maxRows: 3 }}
                            maxLength={500}
                        />
                    </Form.Item>
                </div>
            </section>

            <section className={styles.submit_section}>
                <Flex>
                    <Button type="primary" htmlType="submit" block>
                        提交查询
                    </Button>
                </Flex>
            </section>
        </Form>
    );
}

GroupStayForm.propTypes = {
    form: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired,
    onValuesChange: PropTypes.func,
};

export default GroupStayForm;
