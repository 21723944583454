import { useCallback, useEffect, useRef } from 'react';

export const useDebounce = (callback, delay) => {
    const timeoutRef = useRef(null);
    const promiseResolveRef = useRef(null);
    const promiseRejectRef = useRef(null);

    useEffect(() => {
        // Cleanup on unmount
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
                if (promiseRejectRef.current) {
                    promiseRejectRef.current(new Error('Component unmounted'));
                }
            }
        };
    }, []);

    const debouncedCallback = useCallback(
        (...args) => {
            return new Promise((resolve, reject) => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                    if (promiseRejectRef.current) {
                        promiseRejectRef.current(new Error('Debounce cancelled'));
                    }
                }

                promiseResolveRef.current = resolve;
                promiseRejectRef.current = reject;

                timeoutRef.current = setTimeout(async () => {
                    try {
                        const result = await callback(...args);
                        promiseResolveRef.current(result);
                    } catch (error) {
                        promiseRejectRef.current(error);
                    } finally {
                        promiseResolveRef.current = null;
                        promiseRejectRef.current = null;
                    }
                }, delay);
            });
        },
        [callback, delay]
    );

    // Add cancel method to handle manual cancellation
    debouncedCallback.cancel = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            if (promiseRejectRef.current) {
                promiseRejectRef.current(new Error('Manually cancelled'));
            }
            promiseResolveRef.current = null;
            promiseRejectRef.current = null;
        }
    };

    return debouncedCallback;
};
