import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';
import { ConfigProvider, App as AntApp } from 'antd';
import * as Sentry from '@sentry/react';
import { DevMode } from './utils/utils';
import 'semantic-ui-css/semantic.min.css';

if (DevMode() == false) {
    Sentry.init({
        dsn: 'https://b5a643adefcd3de3d5bd053d19dac5fd@o4507914726735872.ingest.us.sentry.io/4507914729816064',
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', /^https:\/\/agency.aotravelconnect\.com/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

// $UIColorPrimary: #0088b8; //品牌色
// $UIColorPrimaryLight: #e5f3f8; //品牌色浅色
// $UIColorSecondary: #ec9c29; //辅助色

// $UIWordColorPrimary: #333333; //文字颜色 主要
// $UIWordColorSecondary: #666666; //文字颜色 次要
// $UIWordColorIndicative: #999999; //文字颜色 提示

// $UIBorderColor: #e4e6e8; //边框颜色
// $UILineColor: #eeeeee; //分割线颜色
// $UILightGrey: #f8f9fa; //浅灰色背景
// $UIWhite: #ffffff; //白色背景

// Ant Design theme customization
//https://ant.design/docs/react/customize-theme#seedtoken

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: '#0088b8',
                        colorText: '#333333',
                        colorBorder: '#e4e6e8',
                        controlHeight: 40,
                    },
                    components: {
                        Menu: {
                            itemHeight: 40,
                        },
                        Collapse: {
                            // headerPadding: '0px',
                            // contentPadding: '0px',
                        },
                    },
                }}
                button={{ autoInsertSpace: false }} // for better Chinese words display
            >
                <AntApp>
                    <App />
                </AntApp>
            </ConfigProvider>
        </Provider>
    </BrowserRouter>
);
