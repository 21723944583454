import React from 'react';
import GroupFeaturesBanner from '../GroupFeaturesBanner';
import styles from './GroupFeatures.module.css';
import Icon1 from '@assets/air-features/group_feature_icon_1.svg';
import Icon2 from '@assets/air-features/group_feature_icon_2.svg';
import Icon3 from '@assets/air-features/group_feature_icon_3.svg';
import Icon4 from '@assets/air-features/group_feature_icon_4.svg';

function GroupFeatures() {
    return (
        <div className={styles.container}>
            <div className={styles.banner_container}>
                <GroupFeaturesBanner />
            </div>

            <div className={styles.features_container}>
                <div className={styles.feature_container}>
                    <div className={styles.top_container}>
                        <img src={Icon1} />
                    </div>
                    <div className={styles.bottom_container}>
                        <div className={styles.title}>资质保障</div>
                        <li>IATA认证代理 + 航司直签资质，双重权威认证</li>
                        <li>让您以最优惠的价格锁定团队舱位</li>
                    </div>
                </div>
                <div className={styles.feature_container}>
                    <div className={styles.top_container}>
                        <img src={Icon2} />
                    </div>
                    <div className={styles.bottom_container}>
                        <div className={styles.title}>价格优势</div>
                        <li>享受航司直签价格，确保采购价格优势</li>
                        <li>专享QF & VA团队账户，轻松掌握市场行情</li>
                    </div>
                </div>
                <div className={styles.feature_container}>
                    <div className={styles.top_container}>
                        <img src={Icon3} />
                    </div>
                    <div className={styles.bottom_container}>
                        <div className={styles.title}>系统支持</div>
                        <li>多重安全保障，确保价格稳定可控</li>
                        <li>用专业为您的团队创造价值</li>
                    </div>
                </div>
                <div className={styles.feature_container}>
                    <div className={styles.top_container}>
                        <img src={Icon4} />
                    </div>
                    <div className={styles.bottom_container}>
                        <div className={styles.title}>经验丰富</div>
                        <li>从询价到售后支持全程无忧</li>
                        <li>覆盖各类出行场景，深谙各类团队需求</li>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GroupFeatures;
