import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import BackBar from '@components/BackBar';
import useRideOrder from '@hooks/transfer/useRideOrder';
import TransferOrderDetail from '@components/transfer/TransferOrderDetail';
import { Button } from 'antd';
import useRideCancelOrder from '@hooks/transfer/useRideCancelOrder';

function TransfersOrderDetailPage() {
    const { id } = useParams();
    const { fetching, order, fetchOrder, abort } = useRideOrder();
    const { cancelling, cancelRideOrder, abort: abortCancel } = useRideCancelOrder();

    useEffect(() => {
        console.log(id);
        fetchOrder(id)
            .then((data) => {
                console.log('data:', data);
            })
            .catch((error) => {
                alert(error.message);
            });
        return () => {
            abort();
        };
    }, [id]);

    const handleCancel = () => {
        if (confirm('确定要取消预订吗？') !== true) return;
        cancelRideOrder(id)
            .then((data) => {
                // refetch order
                fetchOrder(id);
                const { reply } = data;
                if (reply) {
                    alert(reply);
                }
            })
            .catch((error) => {
                alert(error.message);
            });
    };

    return (
        <div>
            <BackBar />
            {fetching && <div>Loading...</div>}
            {order && (
                <>
                    <TransferOrderDetail data={order} style={{ margin: '24px 0' }} refresh={() => fetchOrder(id)} />
                    {order.status !== 'Cancelled' && (
                        <Button type="primary" danger onClick={handleCancel} loading={cancelling}>
                            取消预订
                        </Button>
                    )}
                </>
            )}
        </div>
    );
}

export default TransfersOrderDetailPage;
