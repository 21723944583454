import { useCallback, useState, useRef } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useMapsPlaceDetails() {
    const [fetching, setFetching] = useState(false);
    const [data, setData] = useState({});

    const abortRef = useRef();

    const fetchPlaceDetails = useCallback(async (placeID) => {
        abort();
        abortRef.current = new AbortController();
        setFetching(true);
        try {
            const response = await fetch(`${config.apiURL2}/maps/place-details`, {
                method: 'POST',
                signal: abortRef.current.signal,
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${GetToken()}`,
                },
                body: JSON.stringify({ placeID }),
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to fetch place details');
            }
            const data = await response.json();
            setData(data);
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetch place details :', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);

    return { fetching, data, fetchPlaceDetails, abort };
}

export default useMapsPlaceDetails;
