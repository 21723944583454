import React from 'react';
import styles from '@styles/GroupFeaturesBanner.module.css';
import Object from '@assets/air-features/objects.svg';
import Rectangle from '@assets/air-features/rectangle.svg';
import IconCheck from '@assets/air-features/icon_check.svg';

function GroupFeaturesBanner() {
    return (
        <div className={styles.container}>
            <div className={styles.rectangle_container}>
                <img src={Rectangle} />
            </div>
            <div className={styles.objects_container}>
                <img src={Object} />
            </div>
            <div className={styles.caption_container}>
                <div className={styles.content}>
                    <div className={styles.column}>
                        <div className={styles.point}>
                            <div className={styles.icon_container}>
                                <img src={IconCheck} />
                            </div>
                            20年专业经验
                        </div>
                        <div className={styles.point}>
                            <div className={styles.icon_container}>
                                <img src={IconCheck} />
                            </div>
                            专属价格优势
                        </div>
                        <div className={styles.point}>
                            <div className={styles.icon_container}>
                                <img src={IconCheck} />
                            </div>
                            优质客户背书
                        </div>
                    </div>
                    <div className={styles.column}>
                        <div className={styles.point}>
                            <div className={styles.icon_container}>
                                <img src={IconCheck} />
                            </div>
                            官方授权资质
                        </div>
                        <div className={styles.point}>
                            <div className={styles.icon_container}>
                                <img src={IconCheck} />
                            </div>
                            便捷安全系统
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GroupFeaturesBanner;
