import React from 'react';

function TransferPolicy() {
    return (
        <div>
            <p>航班抵达后含60分钟的等候时间。</p>
            <p>
                在预约接送时间24小时之前取消可获得100%退款（全额退款），在预约接送时间24小时之内取消则不予退款（0%退款）。
            </p>
            <p>司机会在行程前一天联系您, 会说当地语言和英语。</p>
        </div>
    );
}

export default TransferPolicy;
