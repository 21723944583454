import { useEffect, useCallback, useRef, useState } from 'react';

/**
 * Custom hook for polling any async function with progress indication
 * @param {function} action - Async function to be called periodically
 * @param {Object} options - Configuration options
 * @param {number} options.interval - Polling interval in milliseconds (default: 5000)
 * @param {boolean} options.immediate - Whether to execute immediately on mount (default: true)
 * @param {boolean} options.enabled - Whether polling should be active (default: true)
 * @returns {Object} - Contains polling control functions, status and progress
 */
const usePolling = (action, { interval = 5000, immediate = true, enabled = true } = {}) => {
    const timeoutRef = useRef(null);
    const progressIntervalRef = useRef(null);
    const mountedRef = useRef(true);
    const actionRef = useRef(action);
    const [isActive, setIsActive] = useState(enabled);
    const [nextPollIn, setNextPollIn] = useState(interval);

    // Keep the latest action function in ref
    useEffect(() => {
        actionRef.current = action;
    }, [action]);

    // Clear all timers
    const clearTimers = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        if (progressIntervalRef.current) {
            clearInterval(progressIntervalRef.current);
        }
    }, []);

    // Start progress tracking
    const startProgressTracking = useCallback(() => {
        setNextPollIn(interval);
        const startTime = Date.now();

        progressIntervalRef.current = setInterval(() => {
            const elapsed = Date.now() - startTime;
            const remaining = interval - elapsed;

            if (remaining <= 0) {
                clearInterval(progressIntervalRef.current);
            } else {
                setNextPollIn(remaining);
            }
        }, 1000); // Update every second
    }, [interval]);

    const execute = useCallback(async () => {
        if (!isActive || !mountedRef.current) return;

        clearTimers();

        try {
            await actionRef.current();
        } finally {
            if (mountedRef.current && isActive) {
                timeoutRef.current = setTimeout(execute, interval);
                startProgressTracking();
            }
        }
    }, [interval, isActive, clearTimers, startProgressTracking]);

    const start = useCallback(() => {
        setIsActive(true);
    }, []);

    const stop = useCallback(() => {
        setIsActive(false);
        clearTimers();
        setNextPollIn(interval);
    }, [clearTimers, interval]);

    const refresh = useCallback(() => {
        clearTimers();
        execute();
    }, [execute, clearTimers]);

    // Cleanup on unmount
    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
            clearTimers();
        };
    }, [clearTimers]);

    // Handle enabled prop changes
    useEffect(() => {
        setIsActive(enabled);
    }, [enabled]);

    // Start/stop polling based on isActive state
    useEffect(() => {
        if (isActive) {
            if (immediate) {
                execute();
            } else {
                timeoutRef.current = setTimeout(execute, interval);
                startProgressTracking();
            }
        } else {
            clearTimers();
        }

        return clearTimers;
    }, [execute, isActive, immediate, interval, clearTimers, startProgressTracking]);

    return {
        start,
        stop,
        refresh,
        isPolling: isActive,
        nextPollIn, // Milliseconds until next poll
        progress: Math.floor(((interval - nextPollIn) / interval) * 100), // Percentage complete (0-100)
    };
};

export default usePolling;
