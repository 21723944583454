import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styles_input from '@styles/InputContainerLarge.module.css';

const QtyPickerField = ({ icon: Icon, label, name, placeholder, value, onChange }) => {
    const inputRef = useRef();
    return (
        <div className={styles_input.input_container}>
            <Icon className={styles_input.icon} />
            <label>
                <div className={styles_input.label_content}>{label}</div>
            </label>
            <input
                ref={inputRef}
                className={styles_input.input}
                name={name}
                type="number"
                placeholder={placeholder || '输入数量'}
                value={value || ''}
                onChange={onChange}
            />
        </div>
    );
};

QtyPickerField.propTypes = {
    icon: PropTypes.elementType.isRequired,
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default QtyPickerField;
