import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import BackBar from '../../components/BackBar';
import useFlightOrder from '../../hooks/flight/useFlightOrder';
import { Descriptions, Space, Button, Alert, Spin } from 'antd';
import { ClockCircleOutlined, UserOutlined } from '@ant-design/icons';
import { format, parse } from 'date-fns';
import { zhCN } from 'date-fns/locale';
import useFlightIssue, { IssueType } from '../../hooks/flight/useFlightIssue';
import { TicketingMsg } from '@utils/constants';

function FlightOrderPage(props) {
    const { id: ref } = useParams();
    const { fetching, fetchOrder, order, abort: abortFetch } = useFlightOrder();
    const { submitting, handleIssue, abort: abortIssue } = useFlightIssue();

    if (!ref) {
        return <div>Empty order number</div>;
    }

    useEffect(() => {
        if (ref) {
            fetchOrder(ref);
        }
        return () => {
            abortFetch();
            abortIssue();
        };
    }, [ref]);

    const formatDateTime = (str) => {
        if (!str) return '-';
        const parsedDate = parse(str, 'yyyyMMddHHmm', new Date());
        return format(parsedDate, 'MM月dd日 HH:mm', { locale: zhCN });
    };

    if (fetching) {
        return <div>Loading...</div>;
    }

    const handleClick = (issueType) => {
        let msg = '';
        switch (issueType) {
            case IssueType.Issue:
                msg = '确定要申请出票？';
                break;
            case IssueType.Cancel:
                msg = '确定要申请取消？';
                break;
            default:
                console.error('Unknown issue type:', issueType);
                return;
        }
        if (confirm(msg) !== true) {
            return;
        }
        const req = {
            channelSource: order.channelSource,
            orderNo: order.orderNo,
            issueType: issueType,
            totalPrice: order.totalPrice,
        };
        handleIssue(req)
            .then((data) => {
                alert(data.reply);
            })
            .catch((error) => {
                alert(error.message);
            });
    };

    return (
        <div>
            <BackBar />
            <br />
            <Spin spinning={submitting} fullscreen />
            <Descriptions bordered>
                <Descriptions.Item label="Ref">{order.ref}</Descriptions.Item>
                <Descriptions.Item label="OrderNo">{order.orderNo}</Descriptions.Item>
                <Descriptions.Item label="PNR Code">{order.pnrCode}</Descriptions.Item>
                <Descriptions.Item label="系统">{order.channelSourceName}</Descriptions.Item>
                <Descriptions.Item label="航司">{order.carrier}</Descriptions.Item>
                <Descriptions.Item label="状态">{order.statusTxt}</Descriptions.Item>
                <Descriptions.Item label="出发">
                    <ClockCircleOutlined /> {formatDateTime(order.fromDepTime)} - {order.fromDepAirport}
                </Descriptions.Item>
                <Descriptions.Item label="到达">
                    <ClockCircleOutlined /> {formatDateTime(order.fromArrTime)} - {order.fromArrAirport}
                </Descriptions.Item>
                <Descriptions.Item label="总价">
                    {order.currency ?? 'CNY'} {order.totalPrice}
                </Descriptions.Item>
                <Descriptions.Item label="乘客" span={3}>
                    <UserOutlined /> {order.passengers?.join(', ')} (成人: {order.numAdult}; 儿童: {order.numChild})
                </Descriptions.Item>
            </Descriptions>
            <br />
            <Alert message={TicketingMsg} type="warning" showIcon />
            <br />
            <Space direction="horizontal" align="right">
                <Button type="link" size="small" onClick={() => handleClick(IssueType.Issue)} disabled={submitting}>
                    申请出票
                </Button>
                <Button type="link" size="small" onClick={() => handleClick(IssueType.Cancel)} disabled={submitting}>
                    申请取消
                </Button>
            </Space>
        </div>
    );
}

export default FlightOrderPage;
