import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as config from '@config';
import { Loader, Segment } from 'semantic-ui-react';
import styles from '@styles/AccountPage.module.css';
import { logout } from '@features/account/accountSlice';
import { useDispatch } from 'react-redux';
import { GetRoleName, AgentRole } from '@utils/constants';
import { IsChannelUser, WhiteLabelName } from '@utils/storage';
import OperatorAccountForm, { DefaultData } from '@components/OperatorAccountForm';
import { useId } from 'react';
import OperatorTable from '@components/OperatorTable';
import useAccount from '../../hooks/account/useAccount';
import useAccountUpdate from '../../hooks/account/useAccountUpdate';
import { Form, Input, Modal, Button } from 'antd';

function AccountPage() {
    const formID = useId();
    const dispatch = useDispatch();
    const [submitting, setSubmitting] = useState(false);
    const [modalActive, setModalActive] = useState(false);
    const [modalEditActive, setModalEditActive] = useState(false);
    const [operatorReq, setOperatorReq] = useState(DefaultData);
    const { fetching, fetchAccount: fetchData, data, abort } = useAccount();
    const { updating, updateAccount } = useAccountUpdate();
    const [form] = Form.useForm();

    useEffect(() => {
        fetchData();
        return () => {
            abort();
        };
    }, []);

    useEffect(() => {
        if (modalEditActive) {
            form.setFieldsValue({
                companyName: data.user.agency.companyName,
                registrationNumber: data.user.agency.registrationNumber,
                contactName: data.user.contactName,
                contactNumber: data.user.contactNumber,
                wechat: data.user.wechat,
            });
        }
    }, [data, modalEditActive]);

    useEffect(() => {
        if (modalActive == false) {
            setOperatorReq(DefaultData);
        }
    }, [modalActive]);

    // const SubAccounts = () => {
    //     if (data.user.role != AgentRole.Manager) return <></>;
    //     return (
    //         <>
    //             <Button icon="add" onClick={() => setModalActive(true)} />
    //             <OperatorTable users={data.agents} callback={fetchData} />
    //             <Modal
    //                 closeIcon
    //                 onClose={() => setModalActive(false)}
    //                 onOpen={() => setModalActive(true)}
    //                 open={modalActive}
    //             >
    //                 <Modal.Header>创建子账号</Modal.Header>
    //                 <Modal.Content>
    //                     <OperatorAccountForm
    //                         formID={formID}
    //                         data={operatorReq}
    //                         setData={setOperatorReq}
    //                         handleSubmit={handleCreate}
    //                     />
    //                 </Modal.Content>
    //                 <Modal.Actions>
    //                     <Button content="关闭" onClick={() => setModalActive(false)} />
    //                     <Button content="创建" primary form={formID} />
    //                 </Modal.Actions>
    //             </Modal>
    //         </>
    //     );
    // };

    const AccountInfo = () => {
        return (
            <Segment>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <span>公司名称： {data.user.agency.companyName}</span>
                    <span>账户类型： {GetRoleName(data.user?.role)}</span>
                    <span>账户邮箱： {data.user.email}</span>
                    <span>联系人： {data.user.contactName}</span>
                    <span>联系号码： {data.user.contactNumber}</span>
                    <span>微信： {data.user.wechat}</span>
                </div>
                <div style={{ marginTop: '10px' }}></div>
                <Button type="primary" onClick={() => setModalEditActive(true)}>
                    修改
                </Button>

                <Modal
                    title="公司信息"
                    open={modalEditActive}
                    onCancel={() => setModalEditActive(false)}
                    footer={null}
                    centered
                >
                    <Form form={form} onFinish={handleUpdate} labelCol={{ span: 5 }}>
                        {data.user.role == AgentRole.Manager && (
                            <>
                                <Form.Item name="companyName" label="公司名称">
                                    <Input disabled={data.user.role != AgentRole.Manager} />
                                </Form.Item>
                                <Form.Item name="registrationNumber" label="旅行社注册号">
                                    <Input disabled={data.user.role != AgentRole.Manager} />
                                </Form.Item>
                            </>
                        )}

                        <Form.Item name="contactName" label="联系人">
                            <Input />
                        </Form.Item>
                        <Form.Item name="contactNumber" label="联系号码">
                            <Input />
                        </Form.Item>
                        <Form.Item name="wechat" label="微信">
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={updating}>
                                更新
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </Segment>
        );
    };

    const ChannelInfo = () => {
        return (
            <Segment>
                <div>Channel: {WhiteLabelName()}</div>
            </Segment>
        );
    };

    const handleLogout = () => {
        if (window.confirm('是否要登出?') == true) {
            dispatch(logout());
        }
    };

    const handleUpdate = (values) => {
        console.log('update', values);
        const req = {
            ...values,
        };
        updateAccount(data.user.id, req)
            .then((data) => {
                setModalEditActive(false);
                fetchData();
                const reply = data.reply;
                if (reply) {
                    console.log(reply);
                    window.alert(reply);
                }
            })
            .catch((err) => {
                let msg = err.toString();
                if (err.response?.data?.error) {
                    console.log('***', err.response.data.error);
                    msg = err.response.data.error;
                }
                window.alert(msg);
            });
    };

    // const handleCreate = () => {
    //     const req = {
    //         contactName: operatorReq.contactName,
    //         mobile: operatorReq.mobile,
    //         email: operatorReq.email,
    //         password: operatorReq.password,
    //         wechat: operatorReq.wechat,
    //     };
    //     console.log('creaete operator', req);
    //     setSubmitting(true);
    //     axios
    //         .post(`${config.apiURL}/users`, req, {
    //             signal: controller.signal,
    //             headers: { Authorization: `Bearer ${GetToken()}` },
    //         })
    //         .then((res) => {
    //             setModalActive(false);
    //             fetchData();
    //             const reply = res.data.reply;
    //             if (reply) {
    //                 alert(reply);
    //             }
    //         })
    //         .catch((err) => {
    //             let msg = err.toString();
    //             if (err.response?.data?.error) {
    //                 console.log('***', err.response.data.error);
    //                 msg = err.response.data.error;
    //             }
    //             window.alert(msg);
    //         })
    //         .finally(() => {
    //             setSubmitting(false);
    //         });
    // };

    return (
        <div className={styles.container}>
            <div className={styles.top_container}>
                <h3>
                    账号 <Loader active={fetching || submitting}></Loader>
                </h3>
                <Button className="primary_btn" onClick={handleLogout}>
                    登出
                </Button>
            </div>
            {fetching && <p>Loading...</p>}
            {data && (
                <>
                    {AccountInfo()}
                    {IsChannelUser() == true && <ChannelInfo />}
                    {/* {SubAccounts()} */}
                </>
            )}
        </div>
    );
}

export default AccountPage;
