import React from 'react';
import PropTypes from 'prop-types';
import Floated from '@components/Floated';
import GeoPicker from '@components/GeoPicker';
import styles_input from '@styles/InputContainerLarge.module.css';

const LocationField = ({ icon: Icon, label, value, isOpen, setIsOpen, onSelect, placeholder }) => (
    <div className={styles_input.input_container}>
        <Icon className={styles_input.icon} />
        <label>
            <div className={styles_input.label_content}>{label}</div>
        </label>
        <input className={styles_input.input} value={value || ''} placeholder={placeholder} readOnly />
        <button className={styles_input.trigger_btn} onClick={() => setIsOpen(true)} />
        <Floated
            active={isOpen}
            setActive={setIsOpen}
            modal={false}
            onClose={() => console.log('Float close')}
            style={{ left: 0, top: 0, width: '100%' }}
        >
            <div style={{ padding: 16 }}>
                <GeoPicker active={isOpen} placeholder="机场/酒店/地址" defaultValue={value} onSelect={onSelect} />
            </div>
        </Floated>
    </div>
);

LocationField.propTypes = {
    icon: PropTypes.elementType.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
};

export default LocationField;
