import React, { useEffect, useRef, useState } from 'react';
import styles from '@styles/Feedback.module.css';
import { Button, Input, App, Result } from 'antd';
import useFeedback from '../hooks/useFeedback';
const { TextArea } = Input;

const k_feedback = 'k_feedback';

function Feedback() {
    const { submitting, postFeedback } = useFeedback();
    const { message } = App.useApp();
    const [reply, setReply] = useState();

    // Restore feedback content from session storage
    const storedValue = sessionStorage.getItem(k_feedback) ?? '';
    let initContent = '';
    if (typeof storedValue === 'string') {
        initContent = storedValue;
    }
    const [content, setContent] = useState(initContent);

    // useEffect(() => {
    //     console.log('Feedback mounted');
    //     return () => {
    //         console.log('Feedback unmounted');
    //     };
    // }, []);

    useEffect(() => {
        // console.log('Saving feedback to session storage', content);
        sessionStorage.setItem(k_feedback, content);
    }, [content]);

    const handleSubmit = () => {
        postFeedback(content)
            .then((data) => {
                const { reply } = data;
                setContent('');
                setReply(reply);
            })
            .catch((error) => {
                console.error(error.message);
                message?.error(error.message);
            });
    };

    if (reply) {
        return (
            <div className={styles.container}>
                <Result status="success" title={reply} subTitle="我们将尽快回复，请留意您的邮箱(包括垃圾邮件文件夹)" />
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <h2>给我们留言</h2>
            <p>遇到问题或需要其他旅游资源请联系我们</p>
            <div>
                <TextArea
                    value={content}
                    placeholder="Enter your feedback here"
                    autoSize={{ minRows: 5, maxRows: 8 }}
                    onChange={(e) => setContent(e.target.value)}
                    maxLength={500}
                    showCount
                />
                <div style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-start' }}>
                    <Button type="primary" onClick={handleSubmit} loading={submitting}>
                        发送
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Feedback;
