import { useCallback, useState, useRef } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useRideOrder() {
    const [fetching, setFetching] = useState(false);
    const [order, setOrder] = useState();

    const abortRef = useRef();

    const fetchOrder = useCallback(async (id) => {
        abort();
        setFetching(true);
        abortRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL2}/ride/orders/${id}`, {
                headers: {
                    Authorization: `Bearer ${GetToken()}`,
                },
                signal: abortRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'Failed to fetch order');
            }
            const data = await response.json();
            const { order } = data;
            setOrder(order);
            return order;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetch ride order:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);

    return { fetching, order, fetchOrder, abort };
}

export default useRideOrder;
