import React, { useLayoutEffect, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import BackBar from '@components/BackBar';
import { Divider } from 'semantic-ui-react';
import styles from '@styles/OrderPage.module.css';
import { ScrollToTop } from '@utils/scrollPosition';
import Comments from '@components/Comments';
import OrderItemsTable from '@components/activity/OrderItemsTable';
import AddOrderItem from '../../components/activity/AddOrderItem';
import ActivityOrderDescription from '../../components/activity/ActivityOrderDescription';
import { App, Spin } from 'antd';
import useActivityOrder from '../../hooks/activity-order/useActivityOrder';

function OrderPage() {
    const { orderID: id } = useParams();
    const navigate = useNavigate();
    const { fetching, order, fetchActivityOrder, abort } = useActivityOrder();
    const { message } = App.useApp();

    useEffect(() => {
        if (id) fetchData();
        return () => {
            abort();
        };
    }, [id]);

    useLayoutEffect(() => {
        ScrollToTop();
    }, []);

    const fetchData = () => {
        fetchActivityOrder(id)
            .then((data) => {})
            .catch((error) => {
                console.error('Error fetching activity order:', error);
                message?.error(error.message);
                navigate(-1);
            });
    };

    return (
        <div className={`${styles.container}`}>
            <BackBar />
            <br />
            <Spin spinning={fetching} fullscreen />
            <ActivityOrderDescription data={order} callback={fetchData} />
            <br />
            <OrderItemsTable
                orderItems={order.orderItems || []}
                total={order.billingTotal || 0}
                commissionAmount={order.commissionAmount || 0}
                callback={fetchData}
            />
            {order.id && <AddOrderItem orderID={order.id} callback={fetchData} />}
            <Divider horizontal>备注</Divider>
            <Comments commentOnID={order.id} />
        </div>
    );
}

export default OrderPage;
