import React from 'react';
import { Typography, Descriptions, Card, Tag, Space, Collapse, List, Empty, Skeleton } from 'antd';
import { CalendarOutlined, UserOutlined, EnvironmentOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const HotelBookingOrderDescription = ({ order, loading = false }) => {
    if (loading) {
        return (
            <Card>
                <Skeleton active avatar paragraph={{ rows: 4 }} />
            </Card>
        );
    }

    if (!order || !order.hotels || order.hotels.length === 0) {
        return <Empty description="No booking information available" />;
    }

    const hotelInfo = order.hotels[0];
    const traveler = order.travelers && order.travelers.length > 0 ? order.travelers[0] : null;
    const payment =
        order.payments && order.payments.hotelTotals && order.payments.hotelTotals.length > 0
            ? order.payments.hotelTotals[0]
            : null;
    const formOfPayment =
        order.payments && order.payments.formsOfPayment && order.payments.formsOfPayment.length > 0
            ? order.payments.formsOfPayment[0]
            : null;

    const collapseItems = [
        {
            key: '1',
            label: 'Traveler Information',
            children: traveler ? (
                <Descriptions column={1}>
                    <Descriptions.Item label="Name">
                        {`${traveler.givenName || ''} ${traveler.middleName || ''} ${traveler.surname || ''}`.trim()}
                    </Descriptions.Item>
                    <Descriptions.Item label="Type">{traveler.type || 'Not specified'}</Descriptions.Item>
                    <Descriptions.Item label={<Space>Email</Space>}>
                        {traveler.emails && traveler.emails.length > 0 ? traveler.emails[0] : 'Not provided'}
                    </Descriptions.Item>
                </Descriptions>
            ) : (
                <Text>No traveler information available</Text>
            ),
        },
        {
            key: '2',
            label: 'Payment Information',
            children: (
                <Descriptions column={1}>
                    <Descriptions.Item label={<Space>Total Price</Space>}>
                        {payment ? `${payment.total || 'N/A'} ${payment.currencyCode || ''}`.trim() : 'Not specified'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Payment Method">
                        {formOfPayment ? (
                            <Space>
                                {`${formOfPayment.cardTypeCode || ''} **** ${formOfPayment.cardNumber.slice(-4)}`}
                            </Space>
                        ) : (
                            'Not specified'
                        )}
                    </Descriptions.Item>
                </Descriptions>
            ),
        },
        {
            key: '3',
            label: 'Hotel Details',
            children: (
                <Descriptions column={1}>
                    <Descriptions.Item label="Chain">{hotelInfo.chainName || 'Not specified'}</Descriptions.Item>
                    <Descriptions.Item label="Property ID">{hotelInfo.propertyId || 'Not specified'}</Descriptions.Item>
                    <Descriptions.Item label="Guarantee Type">
                        {hotelInfo.guaranteeTypeName || 'Not specified'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Space>Contact</Space>}>
                        {hotelInfo.contactInfo &&
                        hotelInfo.contactInfo.phones &&
                        hotelInfo.contactInfo.phones.length > 0
                            ? hotelInfo.contactInfo.phones[0]
                            : 'Not provided'}
                    </Descriptions.Item>
                </Descriptions>
            ),
        },
        {
            key: '4',
            label: 'Cancellation Policy',
            children: hotelInfo.isRefundable ? (
                <List
                    dataSource={hotelInfo.refundPenalties || []}
                    renderItem={(penalty, index) => (
                        <List.Item>
                            <Text>{`From ${penalty.applicableFromDate} to ${penalty.applicableToDate}: ${penalty.penalty.amount} ${penalty.penalty.currencyCode}`}</Text>
                        </List.Item>
                    )}
                />
            ) : (
                <Text>Non-refundable</Text>
            ),
        },
    ];

    return (
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
            <Card
                title={<Title level={3}>{hotelInfo.hotelName || 'Hotel Name Not Available'}</Title>}
                extra={hotelInfo.hotelStatusName && <Tag color="green">{hotelInfo.hotelStatusName}</Tag>}
            >
                <Descriptions column={1}>
                    <Descriptions.Item label="Confirmation ID">{hotelInfo.confirmationId || 'N/A'}</Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <Space>
                                <EnvironmentOutlined /> Address
                            </Space>
                        }
                    >
                        {hotelInfo.address
                            ? `${hotelInfo.address.street || ''}, ${hotelInfo.address.city || ''}, ${
                                  hotelInfo.address.postalCode || ''
                              }, ${hotelInfo.address.countryCode || ''}`.replace(/^[,\s]+|[,\s]+$/g, '') ||
                              'Address not available'
                            : 'Address not available'}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <Space>
                                <CalendarOutlined /> Check-in
                            </Space>
                        }
                    >
                        {hotelInfo.checkInDate
                            ? `${hotelInfo.checkInDate} ${hotelInfo.checkInTime || ''}`.trim()
                            : 'Not specified'}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <Space>
                                <CalendarOutlined /> Check-out
                            </Space>
                        }
                    >
                        {hotelInfo.checkOutDate
                            ? `${hotelInfo.checkOutDate} ${hotelInfo.checkOutTime || ''}`.trim()
                            : 'Not specified'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Room Type">
                        {hotelInfo.room?.roomType || 'Not specified'}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <Space>
                                <UserOutlined /> Guests
                            </Space>
                        }
                    >
                        {hotelInfo.numberOfGuests || 'Not specified'}
                    </Descriptions.Item>
                </Descriptions>
            </Card>

            <Collapse defaultActiveKey={['1', '2', '3', '4']} items={collapseItems} />
        </Space>
    );
};

export default HotelBookingOrderDescription;
