import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import qs from 'qs';
import TransferForm, { k_transfer_form_data } from '../../components/transfer/TransferForm';
import BackBar from '../../components/BackBar';
import useRideQuote from '../../hooks/transfer/useRideQuote';
import { Skeleton } from 'antd';
import Vehicles from '../../components/transfer/Vehicles';
import { Button } from 'antd';

function TransfersVehiclesPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState({});
    const { submitting, handleRideQuote, data: quote, abort } = useRideQuote();
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            abort();
        };
    }, []);

    useEffect(() => {
        // Change search params when data changes
        const searchParams = new URLSearchParams(data);
        setSearchParams(searchParams, { replace: true });
        return () => {
            // save form data to session storage
            sessionStorage.setItem(k_transfer_form_data, JSON.stringify(data));
        };
    }, [data]);

    // set initial data from Search params at frist render
    useEffect(() => {
        const parsedObj = qs.parse(searchParams.toString());
        console.log('Search params:', parsedObj);
        setData(parsedObj);
        handleQuote(parsedObj);
    }, []);

    const handleQuote = (req) => {
        console.log('Submitted data:', req);
        handleRideQuote(req)
            .then((res) => {
                console.log('Ride quote:', res.quote);
            })
            .catch((error) => {
                console.error('Error:', error);
                alert(error);
            });
    };

    const handleOnSelect = (item) => {
        console.log('Selected vehicle:', item);
        navigate('booking', { state: { request: data, vehicle: item, quote: quote } });
    };

    return (
        <div>
            <BackBar />
            <div style={{ marginTop: 16 }}></div>
            <TransferForm data={data} setData={setData} loading={submitting} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="primary" size="large" style={{ width: '160px' }} onClick={() => handleQuote(data)}>
                    查看车辆
                </Button>
            </div>
            {submitting && <Skeleton active={submitting} />}
            <div style={{ marginTop: 16 }}></div>
            <Vehicles data={quote.vehicleClasses || []} onSelect={handleOnSelect} />
        </div>
    );
}

export default TransfersVehiclesPage;
