import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

// Add the duration plugin
dayjs.extend(duration);

function index({ enquiries, total, handleTableChange, loading = false, query = {}, onSelect = () => {} }) {
    useEffect(() => {
        console.log(enquiries);
        return () => {};
    }, [enquiries]);

    const columns = [
        {
            title: '用车时间',
            key: 'datetime',
            render: (text, record) => (
                <div>
                    <div>{dayjs(record.enquiry.time).format('YYYY-MM-DD HH:mm')}</div>
                    {record.retTime && <div>返程{dayjs(record.enquiry.retTime).format('YYYY-MM-DD HH:mm')}</div>}
                </div>
            ),
        },
        {
            title: '起点',
            key: 'from',
            render: (text, record) => (
                <div>
                    <div>{record.enquiry.pickup}</div>
                </div>
            ),
        },
        {
            title: '终点',
            key: 'to',
            render: (text, record) => (
                <div>
                    <div>{record.enquiry.dropoff}</div>
                </div>
            ),
        },
        {
            title: '用车时长',
            key: 'duration',
            render: (text, record) => (
                <div>
                    <div>{record.enquiry.duration ? `${record.enquiry.duration}小时` : ''}</div>
                </div>
            ),
        },
        {
            title: '人数',
            key: 'passengerCount',
            render: (text, record) => (
                <div>
                    <div>{record.enquiry.passengerCount}人</div>
                </div>
            ),
        },
        {
            title: '回复',
            key: 'comments',
            render: (text, record) => (
                <div>
                    <div>{record.comments?.length}</div>
                </div>
            ),
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => {
                return (
                    <Button type="link" size="small" onClick={() => onSelect(record)}>
                        查看
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <Table
                dataSource={enquiries}
                rowKey={'enquiry'}
                columns={columns}
                onChange={handleTableChange}
                loading={loading}
                pagination={{
                    current: query.page,
                    pageSize: query.pageSize,
                    total,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (num) => `Total ${num} items`,
                    pageSizeOptions: [1, 10, 20, 50, 100],
                }}
            />
        </div>
    );
}

index.propTypes = {
    enquiries: PropTypes.array,
    total: PropTypes.number.isRequired,
    handleTableChange: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    query: PropTypes.object,
    onSelect: PropTypes.func.isRequired,
};

export default index;
