import { useState, useRef, useCallback } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useTransferEnquiries() {
    const [fetching, setFetching] = useState(false);
    const [enquiries, setEnquiries] = useState();
    const [total, setTotal] = useState(0);
    const abortRef = useRef();

    const fetchEnquiries = useCallback(async () => {
        setFetching(true);
        abort();
        abortRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL2}/transfer-enquiries`, {
                headers: { Authorization: `Bearer ${GetToken()}` },
                signal: abortRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred fetching enquiries');
            }
            const data = await response.json();
            const { enquiries, total } = data;
            setTotal(total);
            setEnquiries(enquiries);
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetching enquiries:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);

    return { fetching, enquiries, total, fetchEnquiries, abort };
}

export default useTransferEnquiries;
