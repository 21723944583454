import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Routing.module.css';
import { diffInDays, getTotalDuration, getTransferInfo, timePrint } from './utils';
import { Space, Typography } from 'antd';
import FlightDetailsModal from './components/FlightDetailsModal';
import RulesModal from './components/RulesModal';
import { DownOutlined } from '@ant-design/icons';
import * as config from '@config';
import { useNavigate } from 'react-router';
import { App, Button, Grid, Spin } from 'antd';
import useFlightVerifyPrice from '../../../hooks/flight/useFlightVerifyPrice';
import { prettifyCurrency } from '../../../utils/utils';

const { Text } = Typography;
const { useBreakpoint } = Grid;

function Routing({ routing, request }) {
    const expandableRef = useRef();
    const [openRoutingDetails, setOpenRoutingDetails] = useState(false);
    const navigate = useNavigate();
    const { message } = App.useApp();
    const screens = useBreakpoint();
    // console.log('ddd');

    const [expanded, setExpanded] = useState(false);
    const isRoundTrip = routing.retSegments?.length > 0;

    const toggleExpanded = () => {
        const content = expandableRef.current;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
            setExpanded(false);
        } else {
            content.style.maxHeight = content.scrollHeight + 'px';
            setExpanded(true);
        }
    };

    // Adapte to both one-way and round-trip
    const Airline = ({ segments }) => {
        if (!segments || segments.length === 0) return 'Error';
        return (
            <div className={styles.airline_content}>
                <div className={styles.logo_container}>
                    <img src={`${config.baseURL}/images/airlineIcon/${segments[0].airline.companyName}.svg`} alt="" />
                </div>
                <div className={styles.right_container}>
                    <div className={styles.airline_company}>{segments[0].airline.companyName}</div>
                    <div className={styles.flight_number}>{segments[0].flightNumber}</div>
                    {!screens.sm && <FlightDetail />}
                </div>
            </div>
        );
    };

    const FlightInfo = ({ segments }) => {
        if (!segments || segments.length === 0) return 'Error';
        const firstSegment = segments[0];
        const lastSegment = segments[segments.length - 1];
        return (
            <div className={styles.info_content}>
                {/* departure */}
                <div className={styles.departure_container}>
                    <div className={styles.time_container}>
                        <div className={styles.time}>{timePrint(firstSegment.depTime)}</div>
                    </div>
                    <div className={styles.airport_container}>
                        <div className={styles.airport}>
                            <span className={styles.airport_name}>{firstSegment.depAirport.name}</span>
                            <span className={styles.airport_code}>{firstSegment.depAirportCode}</span>
                        </div>
                    </div>
                </div>
                {/* divider */}
                <div className={styles.middle_container}>
                    <div className={styles.duration_container}>{getTotalDuration(segments)}</div>
                    <div className={styles.line_container}></div>
                    <div className={styles.transfer_container}>{getTransferInfo(segments)}</div>
                </div>
                {/* arrival */}
                <div className={styles.arrival_container}>
                    <div className={styles.time_container}>
                        <div className={styles.time}>
                            {timePrint(lastSegment.arrTime)}
                            <div className={styles.badge}>{diffInDays(segments) > 0 && `+${diffInDays(segments)}`}</div>
                        </div>
                    </div>
                    <div className={styles.airport_container}>
                        <div className={styles.airport}>
                            <span className={styles.airport_code}>{lastSegment.arrAirportCode}</span>
                            <span className={styles.airport_name}>{lastSegment.arrAirport.name}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const FlightDetail = useCallback(() => {
        return (
            <>
                <Button type="link" size="small" onClick={() => setOpenRoutingDetails(true)} style={{ padding: 0 }}>
                    {screens.sm ? '航班详情' : '详情'}
                </Button>
                <FlightDetailsModal
                    open={openRoutingDetails}
                    routing={routing}
                    onClose={() => setOpenRoutingDetails(false)}
                />
            </>
        );
    }, [screens.sm, openRoutingDetails, routing, setOpenRoutingDetails]);

    const PriceListItem = ({ price }) => {
        const [openRuleDetails, setOpenRuleDetails] = useState(false);
        const { verifying, handleVerify, abort: abortVerify } = useFlightVerifyPrice();
        const { rule, flights } = price;
        const { from, ret } = flights;

        useEffect(() => {
            return () => {
                abortVerify();
            };
        }, []);

        const handlePriceVerify = (req) => {
            console.log('Verify price');
            handleVerify(req)
                .then((data) => {
                    // Navigate to book page, pass data to the page,
                    navigate({ pathname: 'book' }, { replace: false, state: data });
                })
                .catch((error) => {
                    console.error('Error verifying price:', error);
                    message?.error(error.message);
                });
        };

        return (
            <>
                <div className={styles.system_container}>系统 {price.channelName}</div>
                <div className={styles.detail_container}>
                    <div className={styles.left_container}>
                        <Space direction="vertical" size={0}>
                            {from.map((item, index) => (
                                <Text key={'from' + index}>
                                    {item.cabinClass}/{item.cabin}/{item.cabinCount}
                                </Text>
                            ))}
                            {ret.map((item, index) => (
                                <Text key={'ret' + index}>
                                    {item.cabinClass}/{item.cabin}/{item.cabinCount}
                                </Text>
                            ))}
                        </Space>
                    </div>
                    <div className={styles.middle_container}></div>
                    <div className={styles.right_container}>
                        <Button type="link" style={{ paddingLeft: 0 }} onClick={() => setOpenRuleDetails(true)}>
                            规则详情
                        </Button>
                        <RulesModal open={openRuleDetails} rules={rule} onClose={() => setOpenRuleDetails(false)} />
                    </div>
                </div>
                <div className={styles.hidden_container}>
                    <Button type="link">航班详情</Button>
                </div>
                <div className={styles.price_container}>
                    <div className={styles.price}>
                        <div className={styles.adult}>
                            <span className={styles.currency}>{price.currency}</span>
                            <span className={styles.number}>{prettifyCurrency(price.adultPrice + price.adultTax)}</span>
                        </div>
                        {/* <div className={styles.child}>
                            <span className={styles.currency}>{price.currency}</span>
                            <span className={styles.number}>{prettifyCurrency(price.childPrice)}</span>
                            <span className={styles.currency}>儿童</span>
                        </div> */}
                    </div>
                    <Button
                        type="primary"
                        style={{ color: 'white', borderColor: '#ec9c29', backgroundColor: '#ec9c29' }}
                        size="small"
                        loading={verifying}
                        onClick={() =>
                            handlePriceVerify({
                                request: request,
                                priceInfo: price,
                                fromSegments: routing.fromSegments,
                                retSegments: routing.retSegments,
                            })
                        }
                    >
                        预订
                    </Button>
                    <Spin
                        spinning={verifying}
                        fullscreen
                        tip={
                            <div>
                                <div>Verifying</div>
                                <Button onClick={abortVerify} size="small" style={{ marginTop: '8px' }}>
                                    取消
                                </Button>
                            </div>
                        }
                    />
                </div>
            </>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.content_container}>
                {isRoundTrip && <div className={styles.divider}></div>}
                <div className={styles.airline_container}>
                    <Airline segments={routing.fromSegments} />
                    {isRoundTrip && <Airline segments={routing.retSegments} />}
                </div>
                <div className={styles.flight_info_container}>
                    <FlightInfo segments={routing.fromSegments} />
                    {isRoundTrip && <FlightInfo segments={routing.retSegments} />}
                </div>
                <div className={styles.detail_container}>{screens.sm && <FlightDetail />}</div>
                <div className={styles.price_container}>
                    <div className={styles.price} aria-expanded={expanded && 'true'}>
                        <div className={styles.adult}>
                            <span className={styles.currency}>{routing.priceList[0].currency}</span>
                            <span className={styles.number}>
                                {prettifyCurrency(routing.priceList[0].adultPrice + routing.priceList[0].adultTax)}
                            </span>
                        </div>
                        {/* <div className={styles.child}>
                            <span className={styles.currency}>{routing.priceList[0].currency}</span>
                            <span className={styles.number}>{prettifyCurrency(routing.priceList[0].childPrice)}</span>
                            <span className={styles.currency}>儿童</span>
                        </div> */}
                    </div>
                    <Button type="default" onClick={toggleExpanded} className="button" size="small">
                        {expanded ? '收起' : `票价`}({routing.priceList.length})
                        <DownOutlined className={styles.icon} aria-expanded={expanded && 'true'} />
                    </Button>
                </div>
            </div>
            <div ref={expandableRef} className={styles.expandable_container}>
                {routing.priceList.map((price, index) => {
                    return (
                        <div key={index} className={styles.expandable_item_container}>
                            <PriceListItem price={price} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

Routing.propTypes = {
    routing: PropTypes.object.isRequired,
    request: PropTypes.object.isRequired,
};

export default React.memo(Routing);
