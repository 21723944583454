import React, { useEffect, useState } from 'react';
import styles from '@styles/AccommHomePage.module.css';
import SelectorsForm from '@components/SelectorsForm';
import { Loader } from 'semantic-ui-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AccommodationSelectorOptions } from '@utils/constants';
import { useSelector } from 'react-redux';
import { Selector, SelectorParser } from '@utils/constants';
import * as config from '@config';
import AccommAlert from '@components/AccommAlert';
import GroupStayForm from '../../components/hotel/GroupStayForm';
import { Form, Modal, Table } from 'antd';
import useStayEnquiryPost from '../../hooks/useStayEnquiryPost';

const k_hotel_form = 'k_hotel_form';

const getStoredHotelForm = () => {
    const formStr = localStorage.getItem(k_hotel_form);
    const initForm = JSON.parse(formStr) || {};
    return initForm;
};

export const AddHotelSegment = ({ name, city }) => {
    const storedForm = getStoredHotelForm();
    if (storedForm?.hotels) {
        storedForm.hotels.push({
            name: name,
            city: city,
        });
    } else {
        storedForm.hotels = [
            {
                name: name,
                city: city,
            },
        ];
    }
    localStorage.setItem(k_hotel_form, JSON.stringify(storedForm));
};

function HomePage() {
    const navigate = useNavigate();
    const fetching = useSelector((state) => state.accommodations.fetching);
    const [searchParams, setSearchParams] = useSearchParams();
    const [form] = Form.useForm();
    const [showConfimation, setShowConfimation] = useState(false);
    const { posting, postStayEnquiry } = useStayEnquiryPost();

    useEffect(() => {
        // Restore form value from local storage
        const initForm = getStoredHotelForm();
        form.setFieldsValue(initForm);
    }, []);

    const handleOnValuesChange = (changedValues, allValues) => {
        saveForm();
    };

    const saveForm = () => {
        const values = form.getFieldsValue();
        localStorage.setItem(k_hotel_form, JSON.stringify(values));
    };

    const gotoSearch = () => {
        const params = new URLSearchParams(searchParams);
        navigate(`search?${params.toString()}`);
    };

    const Hightlights = () => {
        const handleOnClick = (item) => {
            console.log(item);
            const selector = item.selector;
            const params = new URLSearchParams(searchParams);
            params.set('selector', selector || '');
            setSearchParams(params);
            navigate(`search?${params.toString()}`);
        };

        return (
            <section className={styles.highlights_container}>
                {[
                    {
                        selector: Selector.Sydney,
                    },
                    {
                        selector: Selector.Melbourne,
                    },
                    {
                        selector: Selector.Brisbane,
                    },
                    {
                        selector: Selector.GoldCoast,
                    },
                    {
                        selector: Selector.Perth,
                    },
                    {
                        selector: Selector.Cairns,
                    },
                    {
                        selector: Selector.Adelaide,
                    },
                    { selector: Selector.Canberra },
                ].map((item, index) => {
                    return (
                        <div key={index} className={styles.highlight_container} onClick={() => handleOnClick(item)}>
                            <div
                                className={styles.image_container}
                                style={{
                                    backgroundImage: `url('${config.baseURL}/images/place_${item.selector}.webp')`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}
                            ></div>
                            <div className={styles.caption}>{SelectorParser(item.selector)}</div>
                        </div>
                    );
                })}
            </section>
        );
    };

    const onFinish = (values) => {
        console.log('Received values:', values);
        setShowConfimation(true);
    };

    const Summary = () => {
        const { hotels } = form.getFieldsValue();
        const columns = [
            {
                title: 'Hotel Name',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
            },

            {
                title: 'Check-In',
                dataIndex: 'checkIn',
                key: 'checkIn',
            },
            {
                title: 'Check-Out',
                dataIndex: 'checkOut',
                key: 'checkOut',
            },
            {
                title: 'Room',
                dataIndex: 'numRoom',
                key: 'numRoom',
            },
            {
                title: 'Adult',
                dataIndex: 'numAdult',
                key: 'numAdult',
            },
            {
                title: 'Child',
                dataIndex: 'numChild',
                key: 'numChild',
            },
        ];
        return <Table dataSource={hotels} rowKey={'id'} columns={columns} bordered pagination={false} />;
    };

    const handleSubmit = () => {
        console.log('submit');
        postStayEnquiry(form.getFieldsValue())
            .then((data) => {
                const { reply } = data;
                if (reply) {
                    alert(reply);
                }
                localStorage.removeItem(k_hotel_form);
                form.resetFields();
                setShowConfimation(false);
            })
            .catch((error) => {
                console.error(error);
                alert(error);
            });
    };

    return (
        <div className={styles.container}>
            <section className={`${styles.form_section}`}>
                <div className={styles.label_container}>住宿</div>
                <div className={styles.form_container}>
                    <SelectorsForm
                        tagOptions={AccommodationSelectorOptions}
                        option={AccommodationSelectorOptions}
                        handleSubmit={gotoSearch}
                    />
                </div>
            </section>

            <AccommAlert />
            <div>
                <GroupStayForm form={form} onFinish={onFinish} onValuesChange={handleOnValuesChange} />
            </div>
            <div>{/* <Hightlights /> */}</div>
            <Modal
                title="预览"
                open={showConfimation}
                onCancel={() => setShowConfimation(false)}
                // footer={null}
                width={'auto'}
                centered
                cancelText="取消"
                cancelButtonProps={{ disabled: posting }}
                okText="提交"
                okButtonProps={{ loading: posting }}
                onOk={handleSubmit}
                closeIcon={false}
                maskClosable={false}
            >
                <Summary />
            </Modal>

            <Loader active={fetching} inline="centered">
                Loading
            </Loader>
        </div>
    );
}

export default HomePage;
