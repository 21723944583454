import React, { useEffect, lazy, Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import LoginPage from './public/LoginPage';
import PrivateRoute from './routes/PrivateRoute';
import { useDispatch } from 'react-redux';
import { logout } from '@features/account/accountSlice';
import '@styles/styles.css';
import { CartProvider } from './contexts/CartContext';
import { IsThirdPartyDomain } from './utils/domain';
import { WsProvider } from './contexts/WsContext';
import { MyProvider } from './contexts/MyContext';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Logo from './components/Logo';

const ResetPwdPage = lazy(() => import(/* vitePrefetch: true */ './public/ResetPwdPage'));
// const SignupPage = lazy(() => import(/* vitePrefetch: true */ './public/SignupPage'));
const ActivatePage = lazy(() => import(/* vitePrefetch: true */ './public/ActivatePage'));
const Dashboard = lazy(() => import(/* vitePrefetch: true */ './Dashboard'));

const App = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        // console.log(window.location.host);
        if (!location.pathname.startsWith('/dashboard')) {
            if (
                location.pathname.startsWith('/logout') ||
                location.pathname.startsWith('/login') ||
                location.pathname.startsWith('/reset')
            ) {
                dispatch(logout());
                return;
            }
        }
    }, [location]);

    const aotravelRoutes = () => {
        if (IsThirdPartyDomain() == false) {
            return <>{/* <Route path="/signup" element={<SignupPage />} /> */}</>;
        }
    };

    const LazyLoading = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw',
                    gap: '16px',
                }}
            >
                <Logo />
                <LoadingOutlined spin style={{ fontSize: '44px', color: '#08c' }} />
            </div>
        );
    };

    return (
        <Routes>
            <Route path="/" exact element={<LoginPage />} />
            <Route path="/login/:slug" element={<LoginPage />} />
            <Route
                path="/activate/:id"
                element={
                    <Suspense fallback={<LazyLoading />}>
                        <ActivatePage />
                    </Suspense>
                }
            />
            <Route
                path="/reset"
                element={
                    <Suspense fallback={<LazyLoading />}>
                        <ResetPwdPage />
                    </Suspense>
                }
            />

            {/* {aotravelRoutes()} */}

            <Route
                path="dashboard/*"
                element={
                    <PrivateRoute>
                        <MyProvider>
                            <WsProvider>
                                <CartProvider>
                                    <Suspense fallback={<LazyLoading />}>
                                        <Dashboard />
                                    </Suspense>
                                </CartProvider>
                            </WsProvider>
                        </MyProvider>
                    </PrivateRoute>
                }
            />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default App;
