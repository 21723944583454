import * as config from '../config';
import dayjs from 'dayjs';
import { WhiteLabelIconURL } from './storage';
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

/* Check if string is valid UUID */
export function IsValidUUID(str) {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(str);
}

//yyyy-mm-dd
export function ValidateDateStr(str) {
    var dateReg = /^\d{4}([./-])\d{2}\1\d{2}$/;
    return !!str.match(dateReg);
}

export function IsNumber(value) {
    if (typeof value === 'number') {
        return true;
    }
    return false;
}

export function WeekDays(dateString) {
    dayjs.extend(isoWeek);
    let week = [];
    for (let i = 1; i <= 7; i++) {
        let day = dayjs(dateString).isoWeekday(i).format('YYYY-MM-DD');
        week.push(day);
    }
    return week;
}

export function IsMobile() {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    return mediaQuery.matches;
}

export function ClockTime(dateString) {
    return new Date(dateString).toString().substring(16, 21);
}

export function CapitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function MyPriceFormat(v) {
    return Number(v).toFixed(1);
}

//YYYY-MM-DD return empty if '1-01-01'
export function MyDateParsor(value) {
    dayjs.extend(customParseFormat);
    const pattern = 'YYYY-MM-DD';
    if (dayjs(value, pattern).isValid()) {
        return dayjs(value).format(pattern);
    } else {
        return;
    }
}

export const Precentage = (value) => {
    return ((Number(value) || 0) * 100).toFixed(0) || 0;
};

export const OpenURLFile = (filename) => {
    const url = `${config.baseURL}/public/${encodeURIComponent(filename)}`;
    window.open(url, '_blank');
};

export const Timeago = (value) => {
    dayjs.extend(relativeTime);
    return dayjs().to(dayjs(value));
};

export const CopyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
};

export function TimeSpendPrint(duration) {
    return `${Math.trunc(duration?.asHours())}h${duration?.minutes()}m`;
}

export function TimeSpendMinutePrint(value) {
    const duration = dayjs.duration(0).add(dayjs.duration({ minutes: Number(value) }));
    return TimeSpendPrint(duration);
}

export function DevMode() {
    if (process.env.NODE_ENV !== 'production') {
        return true;
    }
    return false;
}

export function PreventBodyScroll(scrollable) {
    if (!!scrollable) {
        // console.info('prevent scroll');
        window.document.body.className += 'prevent_scroll';
    } else {
        // console.info('restore scroll');
        window.document.body.className = window.document.body.className.replaceAll('prevent_scroll', '');
    }
}

//Keep obj's properties that has same key in keep
export function ObjectFieldMatch(obj, keepObj) {
    let objKeys = Object.keys(keepObj);
    objKeys.forEach(function (item) {
        if (obj.hasOwnProperty(item) == false) {
            delete obj[item];
        }
    });
    return obj;
}

// "1200.23" --> "1,200.23"
export function PrettifyPrice(priceString) {
    // Convert the string to a number
    const price = parseFloat(priceString);

    // Check if it's a valid number
    if (isNaN(price)) {
        return 'Invalid price';
    }

    // Use Intl.NumberFormat to format the number
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });

    return formatter.format(price);
}

//Deep clone, browser only
export function deepCopy(obj) {
    return new Promise((resolve) => {
        const { port1, port2 } = new MessageChannel();
        port1.postMessage(obj);
        port2.onmessage = (e) => {
            resolve(e.data);
        };
    });
}

// 1000 -> 1,000
export function prettifyCurrency(amount, locale = 'en-US') {
    return amount.toLocaleString(locale);
}

// Remove undefined and null values from object
export const RemoveEmpty = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (value !== undefined && value !== null) {
            acc[key] = value;
        }
        return acc;
    }, {});
};
