import React, { useEffect, useCallback } from 'react';
import styles from './FlightDeals.module.css';
import useFlightDeals from './hooks/useFlightDeals';
import usePolling from '@hooks/usePolling';
import Row from './Row';

function FlightDeals() {
    const { fetching, deals, fetchDeals, abort } = useFlightDeals();

    const pollAction = useCallback(() => {
        return fetchDeals({ available: true });
    }, [fetchDeals]);

    const { progress } = usePolling(pollAction, {
        interval: 10000, // 30 seconds
        immediate: true,
        enabled: true,
    });

    useEffect(() => {
        return () => {
            abort();
        };
    }, []);

    const Footer = () => (
        <div className={styles.footer}>
            <p>
                1. 机票价格会随市场情况实时波动。为确保您获得当前显示的优惠价格,建议您在找到合适航班后立即完成
                <span className={styles.colored}>预订</span>。
            </p>
            <div>
                2. 如果您已选定航班但暂不确定出行计划,可以使用<span className={styles.colored}>预留</span>
                功能。系统将为您保留该航班2-3周时间,让您能在合适时机完成预订。
            </div>
        </div>
    );

    return (
        <div className={styles.container}>
            {fetching && deals?.length == 0 && <div>Loading...</div>}
            {/* {progress} */}
            <div className={styles.content_container}>
                {deals.map((item, index) => (
                    <Row key={index} item={item} />
                ))}
            </div>
            {deals?.length > 0 && <Footer />}
        </div>
    );
}

export default FlightDeals;
