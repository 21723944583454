import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    Icon,
    Label,
    Modal,
    ModalHeader,
    ModalContent,
    ModalActions,
    TableBody,
    TableRow,
    TableCell,
} from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import ActivityPricing from '@components/activity/ActivityPricing';
import styles from '@styles/OrderItemsTableRow.module.css';
import QtyController from '../QtyController';
import dayjs from 'dayjs';
import { DatePicker, Select, Typography, Button, App } from 'antd';
import useOrderItem from '../../hooks/activity-order/useOrderItem';
import { LoadingOutlined } from '@ant-design/icons';

function OrderItemsTableRow({ item = {}, callback = () => {} }) {
    const [req, setReq] = useState();
    const navigate = useNavigate();
    const { message } = App.useApp();

    const { verifying, verifyOrderItem, verifyData, updating, updateOrderItem, deleting, deleteOrderItem } =
        useOrderItem();

    useEffect(() => {
        console.log('Order item', item);
        if (item.ref) {
            handleVerify();
        }
    }, []);

    useEffect(() => {
        if (!!req) {
            handleBookingChange(req);
        }
    }, [req]);

    const handleVerify = () => {
        verifyOrderItem(item.id).catch((err) => {
            console.error(err);
            message?.error(err.message);
        });
    };

    const handleOnChangeSegment = (segmentID) => {
        setReq((prev) => {
            return { ...prev, segmentID };
        });
    };

    const handleOnChangeVariant = (variantID) => {
        setReq((prev) => {
            return { ...prev, variantID };
        });
    };

    const handleDateChange = (data, dateString) => {
        // console.log('Date change', dateString);
        setReq((prev) => {
            return { ...prev, date: dateString };
        });
    };

    const handleBookingChange = (req) => {
        // console.log(req);
        updateOrderItem(item.id, req)
            .then(() => {
                callback();
                handleVerify();
            })
            .catch((err) => {
                console.error(err);
                message?.error(err.message);
            });
    };

    const handleDelete = () => {
        if (confirm(`是否要移除 "${item.activity.name}" ?`) == true) {
            deleteOrderItem(item.id)
                .then((data) => {
                    callback();
                    const reply = data.reply;
                    if (reply) {
                        message?.success(reply);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    message?.error(err.message);
                });
        }
    };

    const Actions = ({ id = '' }) => {
        if (!id) {
            console.warn('Empty order item ID');
            return null;
        }
        if (!item.confirmed) {
            return (
                <div>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => navigate(`item/${id}`)}
                        disabled={verifying || deleting || updating || !verifyData.readyToBook}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        预订
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        danger
                        onClick={handleDelete}
                        loading={deleting}
                        disabled={verifying || deleting || updating}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        移除
                    </Button>
                </div>
            );
        } else {
            return (
                <Button
                    type="link"
                    size="small"
                    onClick={() => navigate(`item/${id}`)}
                    disabled={verifying || deleting || updating}
                    style={{ whiteSpace: 'nowrap' }}
                >
                    查看
                </Button>
            );
        }
    };

    const PriceDetail = () => {
        const [open, setOpen] = useState(false);
        return (
            <div>
                <div>A${item.total}</div>
                <div>
                    <a onClick={() => setOpen(true)}>详情</a>
                </div>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <ModalHeader>价格明细</ModalHeader>
                    <ModalContent>
                        <Table basic="very">
                            <TableBody>
                                <TableRow>
                                    <TableCell>票面</TableCell>
                                    <TableCell textAlign="right">A${Math.round(item.marketValue / 1.1)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>税费(GST)</TableCell>
                                    <TableCell textAlign="right">
                                        A${Math.round(item.marketValue - item.marketValue / 1.1)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>服务费</TableCell>
                                    <TableCell textAlign="right">A${Math.round(item.serviceFee)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <h4>总价</h4>
                                    </TableCell>
                                    <TableCell textAlign="right">
                                        <h4>A${item.total}</h4>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </ModalContent>
                    <ModalActions>
                        <Button type="primary" onClick={() => setOpen(false)}>
                            关闭
                        </Button>
                    </ModalActions>
                </Modal>
            </div>
        );
    };

    const renderSegments = () => {
        let segmentOptions = [];
        segmentOptions = item.activity?.segmentOptions?.map((item, index) => ({
            label: item.value,
            value: item.id,
        }));
        if (segmentOptions.length > 0) {
            return (
                <div>
                    <label>时段:</label>
                    <Select
                        popupMatchSelectWidth={false}
                        style={{
                            width: 150,
                            height: 24,
                        }}
                        options={segmentOptions}
                        placeholder="选择时段"
                        value={item.bookingInfo.segment?.id}
                        onChange={handleOnChangeSegment}
                    />
                </div>
            );
        }
    };

    const renderVariants = () => {
        let variantOptions = [];
        variantOptions = item.activity?.variants?.map((item, index) => ({
            ...item,
            label: item.nameZH,
            value: item.id,
        }));
        if (variantOptions.length > 0) {
            return (
                <div>
                    <label>套餐:</label>
                    <Select
                        popupMatchSelectWidth={false}
                        style={{
                            width: 150,
                            height: 24,
                        }}
                        options={variantOptions}
                        placeholder="选择套餐"
                        value={item.bookingInfo.variant?.id}
                        onChange={handleOnChangeVariant}
                        optionRender={(option) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography.Text>{option.data.nameZH}</Typography.Text>
                                    <Typography.Text type="secondary">{option.data.description}</Typography.Text>
                                </div>
                            );
                        }}
                    />
                </div>
            );
        }
    };

    const handleDisabledDate = (current) => {
        if (item.activity?.datedPriced) {
            const allowedDateStr = item.activity?.datedPrices?.map((item) => item.date);
            // Can not select days before today and today, and the day not in the allowed dates
            return (
                (current && current < dayjs().startOf('day')) || !allowedDateStr.includes(current.format('YYYY-MM-DD'))
            );
        } else {
            return current && current < dayjs().startOf('day');
        }
    };

    return (
        <>
            <Table.Row
                positive={item.confirmed ? true : false}
                negative={item.deletedAt ? true : false}
                style={
                    item.deletedAt
                        ? {
                              textDecoration: 'line-through',
                          }
                        : null
                }
            >
                <Table.Cell style={{ whiteSpace: 'nowrap' }}>{item.ref}</Table.Cell>
                <Table.Cell>
                    <div className={`${styles.group_container}`}>
                        <div>
                            {item.activity.name}
                            {item.activity.tourCode && (
                                <>
                                    &nbsp;<Label>{item.activity.tourCode}</Label>
                                </>
                            )}
                            &nbsp;
                            <Link to={`/dashboard/activities/${item.activity.id}`}>
                                <Icon
                                    style={{ cursor: 'pointer' }}
                                    name="external alternate"
                                    title={item.activity.name}
                                />
                            </Link>
                        </div>
                        {renderSegments()}
                        {renderVariants()}
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div className={styles.group_container}>
                        <div>{!item.bookingInfo.date && '日期待定'}</div>
                        <DatePicker
                            name="date"
                            format={'YYYY-MM-DD'}
                            value={dayjs(item.bookingInfo.date).isValid() ? dayjs(item.bookingInfo.date) : null}
                            onChange={handleDateChange}
                            disabled={item.confirmed}
                            style={{ minWidth: '124px', height: 24 }}
                            disabledDate={handleDisabledDate}
                        />
                    </div>
                </Table.Cell>

                <Table.Cell textAlign="right">
                    <div className={`${styles.group_container} ${styles.nowrap}`}>
                        <ActivityPricing pricing={item.effectPricing} showFamily={item.effectPricing.hasFamilyPlan} />
                    </div>
                </Table.Cell>
                <Table.Cell>
                    <div className={styles.group_container}>
                        <QtyController
                            bookingInfo={item.bookingInfo}
                            bundlePriced={item.effectPricing.bundlePriced}
                            hasFamilyPlan={item.effectPricing.hasFamilyPlan}
                            setReq={setReq}
                        />
                    </div>
                </Table.Cell>
                <Table.Cell textAlign="right">
                    <PriceDetail />
                </Table.Cell>
                {/* <Table.Cell textAlign="right">{!!item.commission && `A$${item.commission}`}</Table.Cell> */}
                <Table.Cell>
                    <div className={styles.nowrap}>
                        <Actions id={item.id} />
                    </div>
                </Table.Cell>
            </Table.Row>

            <Table.Row>
                <Table.Cell colSpan={8}>
                    <div className={styles.nowrap}>
                        {verifying ? (
                            <span>
                                <LoadingOutlined /> 检查中...
                            </span>
                        ) : (
                            <>
                                <span>{verifyData.bookingWarning}</span>
                                {verifyData.readyToBook && <span>可以预订</span>}
                            </>
                        )}
                    </div>
                </Table.Cell>
            </Table.Row>
        </>
    );
}

OrderItemsTableRow.propTypes = {
    item: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
};

export default OrderItemsTableRow;
