import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './CityPicker.module.css';
import { MdSearch, MdSearchOff } from 'react-icons/md';
import { Loader } from 'semantic-ui-react';
import ResultItem from './ResultItem';
import { AustralianCities } from './constant.js';
import useCity from './hooks/useCity.js';

const resultLimit = 5;

function CityPicker({
    active = false,
    placeholder = '',
    defaultValue = '',
    callback = () => {},
    cityOnly = true,
    defaultResults = [], // default results
}) {
    const ref = useRef();
    const [value, setValue] = useState('');
    const { fetching, searchCity, abort } = useCity();
    const [results, setResults] = useState([]);

    useEffect(() => {
        if (active) {
            ref.current.focus();
            if (defaultValue) {
                setValue(defaultValue);
                handleSearch(defaultValue);
            }
        }
        return () => {
            if (active) {
                console.log('CityPicker closed');
                abort();
                reset();
            }
        };
    }, [active, defaultValue]);

    useEffect(() => {
        if (Array.isArray(defaultResults) && defaultResults.length > 0) {
            setResults(defaultResults);
        }
    }, [defaultResults]);

    const reset = () => {
        flushResults();
        setValue(defaultValue);
    };

    const flushResults = () => {
        if (Array.isArray(defaultResults) && defaultResults.length > 0) {
            setResults(AustralianCities);
        } else {
            setResults([]);
        }
    };

    const onChange = (e) => {
        const value = e.target.value;
        // console.log(value);
        setValue(value);
        handleSearch(value);
    };

    const handleSearch = (value) => {
        if (value.length < 2) {
            flushResults();
            return;
        }
        let limit = resultLimit;
        searchCity({ value, limit, cityOnly })
            .then((data) => {
                const results = data.results;
                setResults(results);
            })
            .catch((error) => {
                console.error('Error searching city:', error);
            });
    };

    const handleOnClick = (item) => {
        callback(item);
    };

    const Results = () => {
        if (fetching) {
            return <Loader inline="centered" active={true} />;
        }
        if (results.length == 0 && !!value) {
            return (
                <div className={styles.icon_container}>
                    <MdSearchOff className={styles.icon} />
                    <div className={styles.text}>无结果</div>
                </div>
            );
        }
        if (results.length == 0 && !value) {
            return (
                <div className={styles.icon_container}>
                    <MdSearch className={styles.icon} />
                    <div className={styles.text}>搜索名称/代码</div>
                </div>
            );
        }
        return results.map((item, index) => {
            return <ResultItem key={index} item={item} handleOnClick={handleOnClick} cityOnly={cityOnly} />;
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.results_container}>
                <input
                    ref={ref}
                    className={styles.input}
                    placeholder={placeholder}
                    autoFocus
                    onChange={onChange}
                    value={value}
                />
                <Results />
            </div>
        </div>
    );
}

CityPicker.propTypes = {
    active: PropTypes.bool,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    callback: PropTypes.func.isRequired,
    cityOnly: PropTypes.bool,
};

export default CityPicker;
