import { useState, useRef, useCallback } from 'react';
import { GetToken } from '@utils/storage';
import * as config from '@config';

function useTransferEnquiry() {
    const [fetching, setFetching] = useState(false);
    const [enquiry, setEnquiry] = useState();
    const abortRef = useRef();

    const fetchEnquiry = useCallback(async (id) => {
        setFetching(true);
        abort();
        abortRef.current = new AbortController();
        try {
            const response = await fetch(`${config.apiURL2}/transfer-enquiries/${id}`, {
                headers: { Authorization: `Bearer ${GetToken()}` },
                signal: abortRef.current.signal,
            });
            if (!response.ok) {
                const data = await response.json();
                throw new Error(data.error || 'An error occurred fetching enquiry');
            }
            const data = await response.json();
            const { enquiry } = data;
            setEnquiry(enquiry);
            return data;
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Request was aborted');
                throw new Error('Request was aborted');
            } else {
                console.error('Error fetching enquiry:', error);
            }
            throw error;
        } finally {
            setFetching(false);
        }
    }, []);

    const abort = useCallback(() => {
        if (abortRef.current) {
            abortRef.current.abort();
        }
    }, []);

    return { fetching, enquiry, fetchEnquiry, abort };
}

export default useTransferEnquiry;
