import React from 'react';
import PropTypes from 'prop-types';
import { DayPicker } from 'react-day-picker';
import { zhCN } from 'react-day-picker/locale';
import { MdCalendarToday } from 'react-icons/md';
import dayjs from 'dayjs';
import Floated from '@components/Floated';
import styles_input from '@styles/InputContainerLarge.module.css';

const DatePickerField = ({ label, value, isOpen, setIsOpen, onSelect, defaultMonth }) => (
    <div className={styles_input.input_container}>
        <MdCalendarToday className={styles_input.icon} />
        <label>
            <div className={styles_input.label_content}>{label}</div>
        </label>
        <input
            className={styles_input.input}
            placeholder="选择日期"
            value={value ? dayjs(value).format('YYYY-MM-DD') : ''}
            readOnly
        />
        <button className={styles_input.trigger_btn} onClick={() => setIsOpen(true)} />
        {/* NOTE: isOpen is used to let defaultMonth take effect */}
        {isOpen && (
            <Floated
                active={isOpen}
                setActive={setIsOpen}
                modal={false}
                onClose={() => console.log('Float close')}
                style={{ left: 0, top: '100%' }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        height: '100%',
                    }}
                >
                    <DayPicker
                        mode="single"
                        locale={zhCN}
                        captionLayout="dropdown-buttons"
                        endMonth={new Date().setFullYear(new Date().getFullYear() + 2)}
                        hidden={[
                            {
                                after: new Date().setFullYear(new Date().getFullYear() + 2),
                            },
                        ]}
                        selected={value && new Date(value)}
                        onSelect={onSelect}
                        disabled={[{ from: new Date(0), to: new Date() }]}
                        defaultMonth={defaultMonth}
                    />
                </div>
            </Floated>
        )}
    </div>
);

DatePickerField.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    defaultMonth: PropTypes.instanceOf(Date),
};

export default DatePickerField;
