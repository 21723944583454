import React from 'react';
import styles from '@styles/FlightDealsPage.module.css';
import TopHeaderBg from '@assets/flight_top_bg.png';
import FlightDeals from '../../components/air/FlightDeals';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

function FlightDealsPage() {
    return (
        <div className={styles.container}>
            <div className={styles.top_bg_container}>
                <img src={TopHeaderBg} />
                <div className={styles.words_container}>
                    <div className={styles.paragraph}>随想旅途</div>
                    <div className={styles.paragraph}>
                        全球航线 <span className={styles.colorful}>1000+</span> 条自在飞
                    </div>
                </div>
            </div>
            <div className={styles.content_container}>
                <section className={styles.tabs_section}>
                    <div className={styles.tabs}>
                        <button className={styles.tab_btn} type="button" aria-selected={'true'}>
                            澳新团队促销价
                        </button>
                    </div>
                </section>
                <div className={styles.flight_deals_container}>
                    <div className={styles.title_container}>
                        <div className={styles.title}>您看到的价格就是您支付的价格</div>
                        <Link to="/dashboard/air-tickets/git">没有合适航班？提交您的需求，为您精准匹配</Link>
                    </div>
                    <FlightDeals />
                </div>
            </div>
        </div>
    );
}

export default FlightDealsPage;
