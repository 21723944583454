import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './FlightDeals.module.css';
import dayjs from 'dayjs';
import { Button, Modal } from 'antd';
import Actions from './Actions';
import * as config from '@config';

function getAirlineCode(flightNumber) {
    // Use a regular expression to match letters at the start
    const match = flightNumber.match(/^[A-Z]+/);
    return match ? match[0] : null;
}

function Row({ item }) {
    const [showRules, setShowRules] = useState(false);

    return (
        <div className={styles.deal_container}>
            <div className={styles.content}>
                <div className={styles.flight_container}>
                    <div className={styles.image_container}>
                        <img
                            src={`${config.baseURL}/images/airlineIcon/${getAirlineCode(item.flightNumber)}.svg`}
                            alt=""
                        />
                    </div>
                    <div>
                        <div>{item.flightNumber}</div>
                        <div>{dayjs(item.depTime).format('YYYY-MM-DD')}</div>
                    </div>
                </div>
                <div className={styles.ariports_container}>
                    <div className={styles.airports}>
                        <span>{item.depAirportCode}</span>
                        {' - '}
                        <span>{item.arrAirportCode}</span>
                    </div>
                    <div className={styles.time}>
                        <div>{dayjs(item.depTime).format('HH:mm')}</div>
                        {'-'}
                        <div> {dayjs(item.arrTime).format('HH:mm')}</div>
                    </div>
                </div>
                <div className={styles.policy_container}>
                    <Button type="link" size="small" onClick={() => setShowRules(true)}>
                        规则
                    </Button>
                    <Modal title="规则" open={showRules} onCancel={() => setShowRules(false)} footer={null} centered>
                        <p>
                            行李：{item.baggagePeices}*{item.baggageWeight}Kg
                        </p>
                        <p>成团率：{item.fulfillRate}%</p>
                        <p>最少订10个位置</p>
                    </Modal>
                </div>
                <div className={styles.seats_container}>
                    <div>共{item.totalSeats}个位置</div>
                    <div>剩余{item.remainingSeats}个位置</div>
                </div>
                <div className={styles.deadline_container}>
                    <div>截止日期</div>
                    <div>{item.priceDeadline ? dayjs(item.priceDeadline).format('YYYY-MM-DD') : '暂无'}</div>
                </div>
                <div className={styles.pricing_container}>
                    <div className={styles.price}>
                        <span className={styles.currency}>{item.currency}</span>{' '}
                        <span className={styles.value}>{item.adultPriceSale}</span>
                    </div>
                    {item.marketPrice && (
                        <div className={`${styles.price} ${styles.market_price}`}>
                            <span className={styles.currency}>{item.currency}</span>{' '}
                            <span className={styles.value}>{item.marketPrice}</span>
                        </div>
                    )}
                </div>

                <div className={styles.actions_container}>
                    <Actions item={item} />
                </div>
            </div>

            <p className={styles.extra}>
                <span className={styles.seats_info}>
                    共{item.totalSeats}个位置, 剩余{item.remainingSeats}个位置;{' '}
                </span>
                <span className={styles.deadline_info}>
                    {item.priceDeadline && <span>截止日期 {dayjs(item.priceDeadline).format('YYYY-MM-DD')}</span>}
                </span>
            </p>
        </div>
    );
}

Row.propTypes = {
    item: PropTypes.object.isRequired,
};

export default Row;
