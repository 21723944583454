import React, { useEffect, useState } from 'react';
import TransferForm, { DEFAULT_DATA, k_transfer_form_data } from '@components/transfer/TransferForm';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { Selector } from '@utils/constants';
import ActivitiesGrid from '@components/activity/ActivitiesGrid';
import { Button } from 'antd';
import useTransferEnquiryPost from '@hooks/transfer-enquiry/useTransferEnquiryPost';
import { Result } from 'antd';
import { set } from 'date-fns';

function TransferEnquiryHomePage() {
    const jsonString = sessionStorage.getItem(k_transfer_form_data);
    const inintData = JSON.parse(jsonString) || DEFAULT_DATA;
    const [data, setData] = useState(inintData);
    const activities = useSelector((state) => state.activities.data);
    const fetching = useSelector((state) => state.activities.fetching);
    const [transferActivties, setTransferActivties] = useState([]);
    const { adding, addEnquiry } = useTransferEnquiryPost();

    const navigate = useNavigate();

    const [result, setResult] = useState();

    useEffect(() => {
        const jsonString = sessionStorage.getItem(k_transfer_form_data);
        const inintData = JSON.parse(jsonString) || DEFAULT_DATA;
        setData(inintData);
    }, []);

    useEffect(() => {
        filterActivities();
    }, [fetching]);

    useEffect(() => {
        // console.log('data changed', data);
        sessionStorage.setItem(k_transfer_form_data, JSON.stringify(data));
        return () => {};
    }, [data]);

    const filterActivities = () => {
        const fixedSelector = Selector.Transfers;
        let newData = [...activities];
        // Fixed selector
        newData = activities.filter((item) => {
            if (item.selectors?.includes(fixedSelector)) {
                return true;
            }
        });
        // Selector sorting
        newData?.sort((a, b) => {
            const sortA = a.selectorItems.find((item) => item.name == fixedSelector).sort;
            const sortB = b.selectorItems.find((item) => item.name == fixedSelector).sort;
            return sortA - sortB;
        });
        setTransferActivties(newData);
    };

    const handleSubmit = () => {
        console.log('Submitted data:', data);
        if (confirm('提交?') != true) return;
        addEnquiry(data)
            .then((res) => {
                console.log('Enquiry added:', res);
                setResult(res);
                setData(DEFAULT_DATA);
            })
            .catch((error) => {
                console.error('Error adding enquiry:', error);
                alert('Error adding enquiry:', error);
            });
    };

    const Success = () => {
        return (
            <Result
                status="success"
                title="提交成功"
                subTitle={<div>请留意您的邮箱(包括垃圾邮件文件夹)或在”用车询价“页面查看进展。</div>}
                extra={[
                    <Button type="primary" key="console" onClick={() => navigate('enquiries')}>
                        查看
                    </Button>,
                    <Button key="back" onClick={() => setResult()}>
                        返回
                    </Button>,
                ]}
            />
        );
    };

    if (result) {
        return <Success />;
    }

    return (
        <div>
            <div style={{ fontSize: 18, fontWeight: 500, padding: '16px 0' }}>用车(机场接送/按时包车)</div>
            <div>
                <TransferForm data={data} setData={setData} />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        type="primary"
                        size="large"
                        style={{ width: '160px' }}
                        onClick={handleSubmit}
                        loading={adding}
                    >
                        提交
                    </Button>
                </div>
            </div>
            {fetching && <div>Loading...</div>}
            <h3>合约商家(节省 50% 车费)</h3>
            <div style={{ padding: '16px 0' }}>
                <ActivitiesGrid activities={transferActivties} isFromPrice={true} />
            </div>
        </div>
    );
}

export default TransferEnquiryHomePage;
